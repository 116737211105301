import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GeneralTab } from './GeneralTab';
import { NotificationTab } from "./NotificationTab";

const tabs = [
  { name: 'GENERAL', component: GeneralTab, current: true },
  { name: 'NOTIFICATIONS', component: NotificationTab, current: false },
  // { name: 'PAYMENTS', component: null, current: false },
  // { name: 'COACHING', component: null, current: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function SettingsTab() {
  const [CurrentTab, setCurrentTab] = useState(() => tabs[0].component);

  useEffect(() => {
    tabs.forEach(tab => {
      if (tab.current) {
        setCurrentTab(() => tab.component);
      }
    });
  }, []);

  return <>
    <div>
      <div className="tw-font-bold tw-text-red-600 tw-text-2xl tw-mt-10 tw-mb-4">Settings</div>
      <div className="tw-flex tw-flex-col">
        <div className="">
          <nav className="tw--mb-2 tw-flex tw-space-x-7 md:tw-w-full" style={{ borderBottom: "2px solid rgba(245, 245, 245, 0.12)" }} aria-label="Tabs">
            {tabs.map((tab, index) => (
              <a key={index} onClick={() => setCurrentTab(() => tab.component)}
                onTouchEnd={() => setCurrentTab(() => tab.component)}
                className={classNames(CurrentTab === tab.component
                  ? 'tw-text-red-600'
                  : 'tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-cursor-pointer',
                  'tw-whitespace-nowrap tw-py-4 tw-px-1 tw-text-base tw-font-bold tw-no-underline'
                )}
                style={CurrentTab === tab.component
                  ? { borderBottom: "2px solid red" }
                  : { borderBottom: "2px solid transparent" }
                }
                aria-current={CurrentTab === tab.component ? 'page' : undefined} > {tab.name} </a>))}
          </nav>
        </div>
        <AnimatePresence mode='wait'>
          {CurrentTab && <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 50 }}
            key={CurrentTab.name}
          >
            <CurrentTab />
          </motion.div>}
        </AnimatePresence>
      </div>
    </div>
  </>
}
