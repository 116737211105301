import {Button, Stack} from "react-bootstrap";
import {AlertRenderProps} from "../../definitions/alert-spec";
import { AppConfig } from '../../config/app-config';
import {Duration} from 'luxon';

export const OnlineModePopup = (props: AlertRenderProps<string>) => (
    <div>
        <Stack style={{textAlign: "start"}} className="px-3">
            <p>You are now online and available for instant bookings! You will automatically go offline if:</p>
            <ul style={{textAlign: "start"}}>
                <li>You are idle for {Duration.fromObject({ seconds: AppConfig.onlineModeExpirySeconds }).as('hours')} hours</li>
                <li>You log out</li>
                <li>Enter a session</li>
            </ul>
            <Button variant="outline-light" onClick={props.cancel}>Got it!</Button>
        </Stack>
    </div>
);
