import "./AboutUsPage.scss"
import { Col, Container, Row } from "react-bootstrap";
import { Community } from "../../components/Community";
import { Helmet } from "react-helmet";
import { EnvUtils } from "../../utils/env-utils";

export function AboutUsPage() {

  return (
    <div className={"page about-us "}>
      <Helmet>
        <title>FPS Lounge | About Us</title>
        <meta name="description" content="FPS Lounge is a social gaming and educational technology company where anyone can connect and become a world class gamer." />
        <script type="text/javascript">
          {`!function(m,a,i,t,r,e){if(m.RH)return;r=m.RH={},r.uuid=t,r.loaded=0,r.base_url=i,r.queue=[],m.rht=function(){r.queue.push(arguments)};e=a.getElementsByTagName('script')[0],c=a.createElement('script');c.async=!0,c.src=i+'/widget/'+t+'.js',e.parentNode.insertBefore(c,e),console.log('ReferralHero script loaded successfully');}(window,document,'https://app.referralhero.com', '${EnvUtils.readString("REACT_APP_REFERRALHERO_ID")}');`}
        </script>
      </Helmet>
      <div className="py-5">
        <Container>
          <Row className={"pb-5"}>
            <Col lg={7}>
              <div className="about-us-content">
                <h1>WE ARE <span
                  className="primary-color">FPS LOUNGE</span>
                </h1>
                <div className="pb-4 about-us-description">
                  Built By Gamers, For Gamers
                </div>
              </div>
            </Col>
            <Col lg={5}>
              <img className={"about-us-logo img-fluid"} src="/assets/Logo/LogoDark BG.png"
                alt="" />
            </Col>
          </Row>

          <div className="co-founder-msg-outline mt-5">
            <div className="p-5 co-founder-img-section">
              <div className="co-founder-img">
                <img src="/assets/about-us/co-founder.png" className={'img-fluid'} alt="" />
              </div>
              <div className="co-founder-content mx-lg-5">
                <div className="co-founder-title">
                  Founder & CEO
                </div>
                <div className="co-founder-name">
                  DEREK TAING
                </div>
                <div className="co-founder-game">
                  FAVORITE GAME OF ALL-TIME: <span className="secondary-color">APEX LEGENDS</span>
                </div>
              </div>
            </div>

            <div className="co-founder-message px-5 pb-5">
              The gaming industry, boasting a global revenue of over $135 billion, now outstrips the combined income of the music, entertainment, and major US sports leagues.
              <br /><br />
              Yet, by 2022, the lion's share of these profits were claimed by game developers and platforms. This left passionate influencers, streamers, and pro Esports athletes—those truly driving the industry's growth—with mere crumbs.
              <br /><br />
              Derek knows this imbalance all too well. A competitive gamer and content creator for half a decade, he navigated the challenges of games like Rainbow Six Siege, PUBG, and Apex Legends. His dedication and competitive spirit enabled him to monetize a substantial audience, turning his gaming love into a livelihood. But he saw many peers, grinding 16+ hour days, still barely scraping by.
              <br /><br />

              Recognizing the industry's glaring issue, Derek introduced FPS Lounge. More than just a platform, it's a mission to make gaming as a profession sustainable and fair. Designed by and for gamers, FPS Lounge aims to redirect the industry's wealth, ensuring that top-tier players are adequately rewarded. FPS Lounge stands as a beacon of change in the gaming world.
              <br /><br />

            </div>
          </div>

          <div className="about-us-welcome">
            <img src="/assets/about-us/welcome-text.png" className={"img-fluid py-5"} alt="" />
          </div>
        </Container>
      </div>

      {/* <Community /> */}
    </div>
  )
}
