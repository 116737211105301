import React, { useEffect, RefObject, useState } from 'react';
import Hls, { HlsConfig, Level } from 'hls.js';

export interface HlsPlayerProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {
  hlsConfig?: HlsConfig;
  playerRef?: RefObject<HTMLVideoElement>;
  src: string;
  showQualitySelector?: boolean;
}

export function ReactHlsPlayer({
  hlsConfig,
  playerRef = React.createRef<HTMLVideoElement>(),
  src,
  autoPlay,
  showQualitySelector = true,
  ...props
}: HlsPlayerProps) {
  const [hls, setHls] = useState<Hls | null>(null);
  const [levels, setLevels] = useState<Level[]>([]);
  useEffect(() => {
    let hls: Hls;
    function _initPlayer() {
      if (hls != null) hls.destroy();
      const newHls = new Hls({ enableWorker: false, ...hlsConfig });
      if (playerRef.current != null) newHls.attachMedia(playerRef.current);

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(src);

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          setLevels(newHls.levels);

          if (autoPlay) {
            playerRef.current
              ?.play()
              .catch(() => console.log('Unable to autoplay prior to user interaction with the dom.'));
          }
        });
        setHls(newHls);
      });

      newHls.on(Hls.Events.ERROR, function(event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError();
              break;
            default:
              _initPlayer();
              break;
          }
        }
      });

      hls = newHls;
    }

    // Check for Media Source support
    if (Hls.isSupported()) {
      _initPlayer();
    }

    return () => {
      if (hls != null) {
        hls.destroy();
      }
    };
  }, [autoPlay, hlsConfig, playerRef, src]);
  const changeQuality = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (hls) {
      hls.currentLevel = value === 'auto' ? -1 : parseInt(value, 10);
    }
  };
  // If Media Source is supported, use HLS.js to play video
  if (Hls.isSupported()) return (
    <>
      <video className="tw-rounded tw-w-full" ref={playerRef} {...props} />
      {showQualitySelector && (
        <div>
          Change Quality &nbsp;
          <select onChange={changeQuality} className="tw-rounded">
            <option value="auto">Auto</option>
            {levels.map((level, index) => (
              <option value={index} key={index}>
                {level.height}p
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
  // Fallback to using a regular video player if HLS is supported by default in the user's browser
  return <video className="tw-rounded tw-w-full" ref={playerRef} src={src} autoPlay={autoPlay} {...props} />;
}
