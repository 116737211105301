import { Button, Card, Col, Row } from "react-bootstrap";
import  { FC } from "react";
import { BookingSchema } from "../schemas/booking-schema";
import { BookingMode, BookingModeExt } from "../definitions/booking-mode";
import { BookingStatus } from "../definitions/booking-status";
import { CheckCircleFill, TrashFill } from "react-bootstrap-icons";
import { useStoreActions } from "../models";
import { SessionParticipant } from "../definitions/session-participant";
import "./CoachBookingListItem.scss"
import { BookingCoachDisplayPopup } from "./alerts/BookingCoachDisplayPopup";
import { useBusy } from "../hooks/use-busy";
import { TimeUtils } from "../utils/time-utils";

export const CoachBookingListItem: FC<{ bookingData: BookingSchema; hasActions: boolean; }> = function(props) {
  const bookingStoreAction = useStoreActions((actions) => actions.booking);
  const uiStoreActions = useStoreActions((actions) => actions.ui);
  const { isBusy, setIsBusy } = useBusy();

  function buildCompletionActionButtons() {
    if (props.bookingData.bookingStatus !== BookingStatus.Accepted) return <></>;
    return <>
      <div className={"booking-request-info-row"}>
        {props.bookingData.coachCompletion ||
          <Button className={"me-1 mt-3"} variant={"outline-success"} disabled={isBusy} onClick={async () => { setIsBusy(true);
                  const should = await uiStoreActions.addConfirmationAlert(
              { message: "Are you sure you want to mark the session as complete?" });
                  if (!should) {
                      setIsBusy(false);
                      return;
                  }
                  await bookingStoreAction.completeSession({
                      bookingId: props.bookingData.id,
                      sessionParticipant: SessionParticipant.Coach,
                      isComplete: true,
                  }); setIsBusy(false); }} >
              <CheckCircleFill size={20} />
              {isBusy ? "Processing..." : "Mark As Complete"}
          </Button> 
        }
      </div>
    </>;
  }

  function buildProceedActionButtons() {
    if (props.bookingData.bookingStatus !== BookingStatus.Pending) return <></>;
    return <>
      <div className="booking-request-info-row">
        <div className="booking-request-title">Proceed</div>
        <div>
          <Button className={'me-4'} variant={"outline-success"} onClick={async () => {
            const should = await uiStoreActions.addConfirmationAlert({ message: 'Are you sure you want to accept the session?' })
            if (!should) return;
            setIsBusy(true);
            await bookingStoreAction.proceedBooking({
              bookingId: props.bookingData.id,
              accept: true
            });
            setIsBusy(false);
          }}>
            <CheckCircleFill
              size={20} /></Button>
          <Button variant={"outline-danger"} onClick={async () => {
            const rejectionReason = await uiStoreActions.addSingleInputAlert({
              title: 'Reject the session',
              message: 'Please mention the reason for the rejection before continuing',
              required: true,
            });
            if (!rejectionReason) return;
            setIsBusy(true);
            await bookingStoreAction.proceedBooking({
              bookingId: props.bookingData.id,
              accept: false,
              proceedComment: rejectionReason,
            });
            setIsBusy(false);
          }}><TrashFill
              size={20} /></Button>
        </div>
      </div>
    </>;
  }

  // todo upgrade and connect this
  async function onBookingOpen(): Promise<void> {
    await uiStoreActions.addCustomAlert({
      title: `${props.bookingData.studentPointer.name}`,
      closeOnClickOutside: true,
      builder: (alertProps) => {
        return <BookingCoachDisplayPopup {...alertProps} bookingData={props.bookingData} />;
      }
    });
  }

  return (
    <div className={"coach-booking-list-item clickable"} key={props.bookingData.id}>
      <Card className={'mb-4'}>
        <Card.Body>
          <div className="p-4">
            <Row>
              <Col md={6}>
                <div className="student-info-row">
                  <div className={"dashboard-student-img"}><img
                    src="/assets/profile/dummy-profile-1.jpg"
                    width={100} alt="" /></div>
                  <div className={"student-info"}>
                    <div className="student-name">{props.bookingData.studentPointer.name}</div>
                    <div
                      className="booking-mode primary-color pt-2">{BookingMode[props.bookingData.bookingMode]} - {BookingStatus[props.bookingData.bookingStatus]}</div>
                  </div>
                </div>
              </Col>
              <Col md={6} className={"booking-request-col pt-4 pt-md-0"}>
                <div className="booking-request-row py-1">
                  <div className="booking-request-bold">Placed at:</div>
                  {TimeUtils.timestampToLocaleString(props.bookingData.created)}
                </div>
                <div className="booking-request-row py-1">
                  <div className="booking-request-bold">Mode:</div>
                  {BookingModeExt.toDisplayLabel(props.bookingData.bookingMode)}
                </div>
                {props.bookingData.hours && <div className="booking-request-row py-1">
                  <div className="booking-request-bold">Duration:</div>
                  {TimeUtils.hoursToHumanizedString(props.bookingData.hours)}
                </div>}
                {props.bookingData.booking &&
                  <div className={"booking-request-time mb-4"}>
                    <div className="booking-request-row">
                      <div className="booking-request-bold">Start:</div>
                      {TimeUtils.dateToLocaleString(props.bookingData.booking?.start)}
                    </div>
                    <div className="booking-request-row py-1">
                      <div className="booking-request-bold">End:</div>
                      {TimeUtils.dateToLocaleString(props.bookingData.booking?.end)}</div>
                    {props.bookingData.proceedComment &&
                      <div className="booking-request-row py-1">
                        <div className="booking-request-bold">Reason:</div>
                        {props.bookingData.proceedComment}</div>}
                  </div>}
                <div className={"mb-4"}>
                  {buildCompletionActionButtons()}
                </div>
                <div>
                  {buildProceedActionButtons()}
                </div>

              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
