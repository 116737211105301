import { useGameOnce } from "../hooks/use-game";
import { useNavigate } from "react-router-dom";
import { UserSchema, UserSchemaExt } from "../schemas/user-schema";
import { useIsCoachBusy } from "../hooks/use-is-coach-busy";
import { SocialLink } from "../pages/coach/CoachProfilePage";
import { ChatDotsFill, Controller, Discord, Fingerprint, Keyboard, SlashCircle, StarFill, Twitch, Twitter, Youtube } from "react-bootstrap-icons";
import { ListFlags } from "./Flag";
import { useCurrentUser } from "../hooks/use-current-user";
import { routes } from "../App";
import { useCoachPricing } from "../hooks/use-coach-pricing";
import _ from "lodash";
import { BsFillRecordCircleFill } from "react-icons/bs";
import { EnvUtils } from "../utils/env-utils";

interface CoachProfileCardProps {
  coachData: UserSchema | undefined;
  alternateStyle?: boolean;
  altStyle2?: boolean;
  altStyle3?: boolean;
}

export const CoachProfileCard = ({ coachData, alternateStyle, altStyle2, altStyle3 }: CoachProfileCardProps) => {

  const navigate = useNavigate();
  const { game } = useGameOnce(coachData?.coachingGame);
  const { isCoachBusy } = useIsCoachBusy(coachData?.uid);
  const { user } = useCurrentUser();
  const { pricingPack } = useCoachPricing(coachData, game);
  const rawDisableCoaches = EnvUtils.readString("REACT_APP_DISABLE_COACHES");
  const disableCoaches = rawDisableCoaches.replace(/[\[\]']/g, "").split(',').map(item => item.trim());
  const isCoachDisabled = disableCoaches.includes(coachData?.uid || "");

  function onChatWithCoach(): void {
    if (!coachData) return;
    navigate(routes.chatWith.replace(':userId', coachData.uid))
  }

  function getRanks() {
    try {
      return game?.filters
        .map(filter => filter.filterOptions.find(option => coachData?.filters?.[filter.id!] === option.id)?.optionName)
        .join(', ');
    } catch (ex) {
      return '-'
    }
  }

  return (

    <div className="lg:tw-col-span-2 md:tw-col-span-3 tw-col-span-6 tw-bg-dp-01 tw-rounded-md" style={altStyle2 ? { boxShadow: '0px 0px 12px 0px #2CB17F' } : {}} >
      {coachData && UserSchemaExt.isCoach(coachData) &&
        <div className="tw-relative">
          <div className="tw-absolute md:tw--top-24 tw--top-12 tw-w-full tw-flex">
            <img
              className="tw-object-cover md:tw-w-52 md:tw-h-52 tw-w-28 tw-h-28 tw-rounded-full tw-flex tw-mx-auto"
              src={coachData?.profileImage ?? game?.imageUrl ?? '/assets/placeholders/game-placeholder.jpg'}
              alt=""
            />
          </div>
          <div className="tw-flex tw-relative tw-p-5">
            <div className="tw-flex tw-flex-col tw-w-full md:tw-mt-28 tw-mt-14 tw-mb-10 tw-text-center tw-space-y-3">
              <h3 className="lg:tw-text-2xl tw-text-xl tw-text-white tw-font-bold tw-filter-none tw-normal-case tw-mb-0 tw-pb-0">{coachData?.displayName}</h3>
              <div className="tw-flex lg:tw-flex-row tw-flex-col4 tw-mx-auto">
                {UserSchemaExt.isOnline(coachData) && !isCoachBusy &&
                  <div className="tw-bg-green/[0.14] tw-px-2 tw-py-1.5 tw-text-green tw-rounded-full tw-flex">
                    <div className="tw-flex tw-h-2 tw-w-2 tw-my-auto">
                      <span
                        className="tw-animate-ping tw-absolute tw-inline-flex tw-h-2 tw-w-2 tw-rounded-full tw-bg-green tw-opacity-50">
                      </span>
                      <span className="tw-relative tw-inline-flex tw-rounded-full tw-h-2 tw-w-2 tw-bg-green"></span>
                    </div>
                    <span className="tw-text-green tw-flex tw-m-auto tw-text-xs leading-4 tw-p-0 tw-uppercase tw-font-bold tw-ml-2">Available</span>
                  </div>
                }

                {isCoachBusy &&
                  <div className="tw-bg-orange/[0.14] tw-px-2 tw-py-1.5 tw-text-orange tw-rounded-full tw-flex">
                    <div className="tw-flex tw-h-2 tw-w-2 tw-my-auto">
                      <span
                        className="tw-animate-ping tw-absolute tw-inline-flex tw-h-2 tw-w-2 tw-rounded-full tw-bg-orange tw-opacity-50">
                      </span>
                      <span className="tw-relative tw-inline-flex tw-rounded-full tw-h-2 tw-w-2 tw-bg-orange"></span>
                    </div>
                    <span className="tw-text-orange tw-flex tw-m-auto tw-text-xs leading-4 tw-p-0 tw-uppercase tw-font-bold tw-ml-2">Busy</span>
                  </div>
                }

                {!UserSchemaExt.isOnline(coachData) && !isCoachBusy &&
                  <div className="tw-bg-dp-24/[0.14] tw-px-2 tw-py-1.5 tw-text-gray-200 tw-rounded-full tw-flex">
                    <div className="tw-flex tw-h-2 tw-w-2 tw-my-auto">
                      <span
                        className="tw-animate-ping tw-absolute tw-inline-flex tw-h-2 tw-w-2 tw-rounded-full tw-bg-gray-100 tw-opacity-50">
                      </span>
                      <span className="tw-relative tw-inline-flex tw-rounded-full tw-h-2 tw-w-2 tw-bg-gray-100"></span>
                    </div>
                    <span className="tw-text-gray-100 tw-flex tw-m-auto tw-text-xs leading-4 tw-p-0 tw-uppercase tw-font-bold tw-ml-2">Offline</span>
                  </div>
                }

                {UserSchemaExt.isOnline(coachData) && !isCoachBusy &&
                  <h4 className="tw-text-green tw-text-lg tw-font-medium tw-my-auto tw-ml-2">${coachData && (pricingPack?.hourlyRate || 0)}/hr</h4>
                }

                {isCoachBusy &&
                  <h4 className="tw-text-orange tw-text-lg tw-font-medium tw-my-auto tw-ml-2">${coachData && (pricingPack?.hourlyRate || 0)}/hr</h4>
                }

                {!UserSchemaExt.isOnline(coachData) && !isCoachBusy &&
                  <h4 className="tw-text-gray-100 tw-text-lg tw-font-medium tw-my-auto tw-ml-2">${coachData && (pricingPack?.hourlyRate || 0)}/hr</h4>
                }
              </div>

              <div className="tw-flex tw-space-x-2 tw-mx-auto">
                <StarFill className="tw-text-gold" size="24" />
                <StarFill className="tw-text-gold" size="24" />
                <StarFill className="tw-text-gold" size="24" />
                <StarFill className="tw-text-gold" size="24" />
                <StarFill className="tw-text-gold" size="24" />
              </div>

              <div className="tw-flex tw-mx-auto">
                <div className="tw-flex tw-space-x-6 tw-my-3">
                  <SocialLink to={coachData.twitchUsername} type="twitch">
                    <Twitch size="24" />
                  </SocialLink>
                  <SocialLink to={coachData.youtube} type="youtube">
                    <Youtube size="24" />
                  </SocialLink>
                  <SocialLink to={coachData.twitterUsername} type="twitter">
                    <Twitter size="24" />
                  </SocialLink>
                  <SocialLink to="https://discord.gg/fpslounge" type="discord">
                    <Discord size="24" />
                  </SocialLink>
                </div>
              </div>

              <div className="tw-flex tw-flex-col tw-mx-auto tw-text-center">
                <span className="tw-text-white tw-text-xs tw-font-bold leading-4 tw-uppercase mb-2">Languages</span>

                {_.isEmpty(coachData?.languages) && <p className="tw-text-gray-100">Not Available</p>}
                <div className="tw-flex tw-space-x-4 tw-mx-auto tw-mb-4">
                  <ListFlags user={user} />
                </div>
              </div>

              <hr className="tw-bg-gray-100 tw-h-0.5 tw-w-full" />

              <div className="tw-flex lg:tw-flex-row tw-flex-col tw-justify-between tw-gap-1 tw-items-center">
                <div className="tw-flex-1 tw-my-2.5">
                  {game && <img src={game.logo} alt={game.name} />}
                </div>
                <div className="tw-flex tw-flex-col tw-flex-1 tw-my-2.5">
                  <span className="tw-text-white tw-text-xs tw-font-bold tw-uppercase">Rank</span>
                  <span className="tw-text-red-100 tw-text-md">{getRanks()}</span>
                </div>
                <div className="tw-flex-1 tw-my-2.5">
                  {coachData.inputMethods?.length
                    ? (coachData.inputMethods.map(input => {
                      if (input === "keyboard") return <Keyboard className="tw-text-gray-100" size="24" />;
                      if (input === "controller") return <Controller className="tw-gray-red-100" size="24" />;
                      if (input === "touch") return <Fingerprint className="tw-gray-red-100" size="24" />;
                      return input;
                    }))
                    : <SlashCircle className="tw-text-gray-100" size="24" />}
                </div>
              </div>
              <div className={(!altStyle3 && !alternateStyle) ? "tw-block" : "tw-hidden"}>
                {coachData?.displayName !== user?.displayName &&
                  <button
                    className={`tw-w-full tw-text-white tw-font-bold tw-uppercase tw-border-none tw-outline-none tw-px-4 tw-py-2.5 tw-rounded-md ${isCoachDisabled ? 'tw-bg-gray-500 tw-cursor-not-allowed' : 'tw-bg-red-100'}`}
                    onClick={() => onChatWithCoach()}
                    title={isCoachDisabled ? 'This coach is not available for booking' : ''}
                    disabled={isCoachDisabled}
                  >
                    <ChatDotsFill className="tw-text-white tw-my-auto tw-mr-1" width={24} />
                    Chat with Coach
                  </button>
                }

                {coachData?.displayName === user?.displayName &&
                  <button
                    className="tw-w-full tw-border tw-border-gray-100 tw-bg-transparent tw-text-gray-100 tw-px-4 tw-py-2.5 tw-rounded-md"
                    onClick={() => navigate(routes.editCoachProfile)}
                  >
                    Edit Profile
                  </button>
                }
              </div>
              <div className={altStyle3 ? "tw-block" : "tw-hidden"}>
                {coachData?.displayName !== user?.displayName &&
                  <button
                    className="tw-w-full tw-bg-red-100 tw-text-white tw-font-bold tw-uppercase tw-border-none tw-outline-none tw-px-4 tw-py-2.5 tw-rounded-md"
                    onClick={(e) => {
                      e.stopPropagation();
                      return navigate(routes.bookSession.replace(':username', coachData.uid ?? ''));
                    }}
                  >
                    Book Now
                  </button>
                }
              </div>
              <div className={alternateStyle ? "tw-block" : "tw-hidden"}>
                <div className="tw-text-center tw-font-bold tw-text-lg tw-text-white">{coachData?.bio}</div>
                <div className="tw-text-center">
                  {coachData.mainPoints && (<ul>
                    {coachData.mainPoints?.map(mainPoint =>
                      <div
                        key={mainPoint.id}
                        className="tw-mt-4 tw-text-white"
                      >
                        <BsFillRecordCircleFill className="tw-text-red-600" /> {mainPoint.label}
                      </div>
                    )}
                  </ul>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      }

    </div>
  )
}
