import Carousel from "react-multi-carousel";
import { CoachProfileCard } from "../../components/CoachProfileCard";
import { useFeaturedCoaches } from "../../hooks/use-coaches";
import { routes } from "../../App";

import { useNavigate } from "react-router-dom";
import { useGames } from "../../hooks/use-games";
import { GameUtils } from "../../utils/game-utils";
import { CoachCard } from "../../components/CoachCard";
export function HomePageCoachCard() {
  const navigate = useNavigate();
  const { allGames } = useGames();
  const { allFeaturedCoaches } = useFeaturedCoaches();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1200, min: 992 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 992, min: 0 },
      items: 1
    }
  }
  return (
    <div>
      <h1 className={"text-center"}><span className="primary-color">Train With the Very Best</span><br />in 3 Easy Steps
      </h1>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-6 tw-justify-center tw-py-8 tw-items-center">
        <div className="tw-flex tw-bg-dp-01 tw-rounded-full tw-items-center tw-w-72 tw-h-28">
          <img src={require('../../assets/icons/target2.png')} alt="Select a coach" width="100px" height="100px" />
          <div className="tw-flex tw-flex-col tw-text-center md:tw-text-left tw-gap-y-1">
            <div className="tw-text-sm tw-text-gray-400 tw-font-bold">STEP 01</div>
            <div className="tw-font-extrabold">Select Coach</div>
          </div>
        </div>
        <div className="tw-flex tw-bg-dp-01 tw-rounded-full tw-items-center tw-w-72 tw-h-28 tw-text-center md:tw-text-left">
          <img src={require('../../assets/icons/sign-up.png')} alt="Select a coach" width="100px" height="100px" />
          <div className="tw-flex tw-flex-col tw-gap-y-1">
            <div className="tw-text-sm tw-text-gray-400 tw-font-extra">STEP 02</div>
            <div className="tw-font-extrabold">Book A Session</div>
          </div>
        </div>
        <div className="tw-flex tw-bg-dp-01 tw-rounded-full tw-items-center tw-w-72 tw-h-28 tw-text-center md:tw-text-left">
          <img src={require('../../assets/icons/badge.png')} alt="Select a coach" width="100px" height="100px" />
          <div className="tw-flex tw-flex-col tw-gap-y-1">
            <div className="tw-text-sm tw-text-gray-400 tw-font-bold">STEP 03</div>
            <div className="tw-font-extrabold">Become A Pro</div>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-justify-center md:tw-py-8"><a className="tw-no-underline"
        onClick={() => navigate(routes.coaches)}>
        <div className="tw-flex tw-w-52 tw-h-16 tw-items-center tw-justify-center tw-text-white tw-bg-red-700 tw-rounded-md tw-font-bold ">Explore Coaches</div>
      </a></div>
      <div className="tw-py-5 md:tw-mx-48 md:tw-w-[80rem]">
        {allFeaturedCoaches && !!allFeaturedCoaches.length &&
          <Carousel className="md:tw-justify-center"
            responsive={responsive}
            swipeable={false}
            draggable={false}
            showDots={true}
            infinite={true}>
            {allFeaturedCoaches?.map(coach => {
              // const gameData = GameUtils.getGameDataById(allGames, coach.coachingGame);
              return (
                <>
                  <div className="tw-mx-6 tw-mt-24 tw-mb-6">
                    <CoachProfileCard key={coach.uid} coachData={coach} altStyle3 />
                  </div>
                </>
              )
            })}
          </Carousel>}
      </div>
    </div>
  );
}
