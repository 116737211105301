import {discountConverter, DiscountSchema} from "../schemas/discount-schema";
import {collection, doc, DocumentReference} from "firebase/firestore";
import {db} from "../config/firebase-config";

export abstract class DiscountUtils {
    public static getDiscountRefById(id: string): DocumentReference<DiscountSchema> {
        return doc(collection(db, 'discounts'), id).withConverter(discountConverter);
    }

    public static getDiscountDataById(allDiscounts?: DiscountSchema[], discountId?: string): DiscountSchema | null {
        if (!discountId) return null;
        return allDiscounts?.find(discount => discount.id === discountId) ?? null;
    }
}
