import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, orderBy, query, where} from "firebase/firestore";
import {userConverter, UserSchema} from "../schemas/user-schema";

export function useCoaches(take?: number): { allCoaches: UserSchema[] | undefined; coachesError: FirestoreError | undefined; coachesLoading: boolean } {
    const coachCollectionRef = collection(db, 'users').withConverter(userConverter);
    const coachesQuery = query<UserSchema>(coachCollectionRef, where("role", "==", 'coach'),  orderBy('name', 'asc'));
    const [allCoaches, coachesLoading, coachesError] = useCollectionData<UserSchema>(coachesQuery);
    return {
        allCoaches,
        coachesLoading,
        coachesError,
    };
}

export function useFeaturedCoaches(take?: number): { allFeaturedCoaches: UserSchema[] | undefined; coachesError: FirestoreError | undefined; coachesLoading: boolean } {
    const coachCollectionRef = collection(db, 'users').withConverter(userConverter);
    const coachesQuery = query<UserSchema>(coachCollectionRef, where("role", "==", 'coach'), where("isFeatured", "==", true));
    const [allCoaches, coachesLoading, coachesError] = useCollectionData<UserSchema>(coachesQuery);
    return {
        allFeaturedCoaches: allCoaches,
        coachesLoading,
        coachesError,
    };
}
