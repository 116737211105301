import {db} from "../config/firebase-config";
import {useDocumentData, useDocumentDataOnce} from "react-firebase-hooks/firestore";
import {collection, doc, FirestoreError} from "firebase/firestore";
import {discountConverter, DiscountSchema} from "../schemas/discount-schema";

export function useDiscount(discountId: string | undefined): { discount?: DiscountSchema; discountError?: FirestoreError | undefined; discountLoading: boolean } {
    const discountCollectionRef = collection(db, 'discounts').withConverter(discountConverter);
    const [discount, discountLoading, discountError] = useDocumentData<DiscountSchema>(discountId ? doc(discountCollectionRef, discountId) : null);
    return {
        discount,
        discountError,
        discountLoading,
    };
}

export function useDiscountOnce(discountId: string | undefined): { discount?: DiscountSchema; discountError?: FirestoreError | undefined; discountLoading: boolean } {
    const discountCollectionRef = collection(db, 'discounts').withConverter(discountConverter);
    const [discount, discountLoading, discountError] = useDocumentDataOnce<DiscountSchema>(discountId ? doc(discountCollectionRef, discountId) : null);
    return {
        discount,
        discountError,
        discountLoading,
    };
}
