import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './styles/DateTimeRangePicker.scss';
import {BrowserRouter as Router} from "react-router-dom";
import {StoreProvider} from "easy-peasy";
import {store} from "./models";
import {App} from "./App";
import {FirebaseMessagingWrapper} from "./components/firebase/FirebaseMessagingWrapper";
import {ScrollToTop} from "./components/ScrollToTop";
import { Sentry } from './config/sentry-config';

type Props = StoreProvider["props"] & { children: React.ReactNode };
const StoreProviderCasted = StoreProvider as unknown as React.ComponentType<Props>;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Sentry.ErrorBoundary>
        <StoreProviderCasted store={store}>
            <Router>
                <FirebaseMessagingWrapper>
                    <ScrollToTop>
                        <App/>
                    </ScrollToTop>
                </FirebaseMessagingWrapper>
            </Router>
        </StoreProviderCasted>
    </Sentry.ErrorBoundary>
);
