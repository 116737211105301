import { useEffect, useState } from "react";
import { UserSchema } from "../schemas/user-schema";
import { useCurrentUser } from "../hooks/use-current-user";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { EnvUtils } from "../utils/env-utils";

interface UserData {
  name: string | undefined;
  email: string | undefined;
  extra_field: string | undefined;
  extra_field_2: string | undefined;
  terms: boolean;
}

declare global {
  interface Window {
    RH: any;
  }
}


export const ReferralHeroData = () => {

  const [finalUser, setFinalUser] = useState<UserSchema | null>(null);
  const { user } = useCurrentUser();

  const filterUserData = () => {
    const targetDate = DateTime.fromISO('2023-04-24');
    const userCreatedDate = user?.created ? DateTime.fromSeconds(user.created.seconds) : null;

    if (user?.role === 'coach') {
      return true;
    }

    if (user?.role === 'student' && userCreatedDate && userCreatedDate > targetDate) {
      return true;
    }

    return false;
  };

  const checkScriptLoaded = (callback: () => void) => {
    if (window.RH && window.RH.form && typeof window.RH.form.submit === "function") {
      callback();
    } else {
      setTimeout(() => checkScriptLoaded(callback), 100);
    }
  };

  useEffect(() => {
    if (user && !finalUser) {
      const sendData = filterUserData();
      // console.log('Should send data:', sendData);

      if (window.RH && sendData) {
        setFinalUser(user);
        checkScriptLoaded(() => {
          const data: UserData = {
            name: user?.gamerTag ? user.gamerTag : user?.name,
            email: user?.email,
            extra_field: user?.role,
            extra_field_2: user?.uid,
            terms: true,
          };
          window.RH.form.submit(data);
        });
      }
    }
  }, [user]);
  return (

    <Helmet>
      <title>FPS Lounge | Student Dashboard</title>
      <script type="text/javascript">
        {`!function(m,a,i,t,r,e){if(m.RH)return;r=m.RH={},r.uuid=t,r.loaded=0,r.base_url=i,r.queue=[],m.rht=function(){r.queue.push(arguments)};e=a.getElementsByTagName('script')[0],c=a.createElement('script');c.async=!0,c.src=i+'/widget/'+t+'.js',e.parentNode.insertBefore(c,e),console.log('ReferralHero script loaded successfully');}(window,document,'https://app.referralhero.com', '${EnvUtils.readString("REACT_APP_REFERRALHERO_ID")}');`}
      </script>
    </Helmet>
  )

}
