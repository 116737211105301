import { useMemo } from "react";
import { CartItemPayload } from "../../models/cart-model";
import { useCoursesByUser } from "../../hooks/use-courses";
import { useStoreState } from "../../models";
import { EconomyConfig } from "../../config/economy-config";
import currency from "currency.js";
import _ from "lodash";

export const PaymentOrderSummaryInfo = () => {
  const cartStoreOptions = useStoreState(state => state.cart);
  const courseProductIds = useMemo(() => {
    return cartStoreOptions.data
      .filter((item: CartItemPayload) => item.type === "courses")
      .map((item: CartItemPayload) => item.productId);
  }, [cartStoreOptions.data]);
  const {allStudentCourses: courses} = useCoursesByUser({ courses: courseProductIds });
  
  const subTotal = useMemo(() => currency(courses?.reduce((sub, course) => sub + course.price, 0) ?? 0), [courses]);
  const cut = subTotal.multiply(EconomyConfig.houseTxFeePercentNormalized);

  return (
    <>
      <h3 className="tw-text-white tw-text-xl">Order Summary</h3>
      <hr className="tw-bg-gray-100" />
      {
        courses?.map(course => {
          return (
            <div key={course.id} className="tw-flex tw-w-full tw-justify-between tw-my-3">
              <div className="tw-flex tw-flex-col">
                <h6 className="tw-text-md tw-font-bold tw-text-white">Course: {course.name}</h6>
                <span className="tw-text-sm tw-text-gray-100">by {course.coachPointer.name}</span>
              </div>
              <span className="tw-text-lg tw-text-white tw-font-bold tw-my-auto">${course.price}</span>
            </div>
          )
        })
      }

      <div className="tw-flex tw-w-full tw-justify-between tw-my-3">
        <div className="tw-flex tw-flex-col">
          <h6 className="tw-text-md tw-font-bold tw-text-white">Service Fee</h6>
          <span className="tw-text-sm tw-text-gray-100">{_.round(EconomyConfig.houseTxFeePercentNormalized * 100, 2)}%</span>
        </div>
        <span className="tw-text-lg tw-text-white tw-font-bold tw-my-auto">{cut.format()}</span>
      </div>

      <hr className="tw-bg-gray-100" />

      <div className="tw-flex tw-w-full tw-justify-between">
        <h4 className="tw-text-white tw-text-lg tw-font-bold">Total</h4>
        <h4 className="tw-text-white tw-text-lg tw-font-bold">{subTotal.add(cut).format()}</h4>
      </div>
    </>
  )
}
