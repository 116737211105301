import "./DiscountBanner.scss"
import {Button, CloseButton, Container} from "react-bootstrap";
import {useAddFunds} from "../hooks/use-add-funds";
import {useMemo} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../config/firebase-config";
import useCookie from "react-use-cookie";
import {useActiveDiscounts} from "../hooks/use-discounts";
import _ from "lodash";
import {TimeUtils} from "../utils/time-utils";

export function DiscountBanner() {
    const [user, loading] = useAuthState(auth);
    const {addFunds} = useAddFunds();
    const [closed, setClosed] = useCookie('bannerClosed',);
    const {allDiscounts, discountsLoading} = useActiveDiscounts();
    const discount = useMemo(() => _.first(allDiscounts), [allDiscounts]);
    const showBanner = useMemo(() => {
        return !closed && (!loading && user);
    }, [closed, loading, user]);
    return (
        <Container>
            {showBanner && discount && <div className="discount-banner">
                <div className="p-3">
                    <div onClick={() => setClosed('yes')} className="close-btn"><CloseButton/></div>
                    <div className="discount-title mb-2">{discount.name} Bonus!</div>

                    <div className={"mb-2 discount-text"}><span
                        className={"primary-color bold-text"}>{discount.percentage}%</span> Bonus on all purchases
                        until <span
                            className="bold-text">{TimeUtils.timestampToLocaleString(discount.endDate)}</span></div>
                    <div className={"discount-text"}>Add funds now to enjoy instant rewards!</div>
                    <Button onClick={() => addFunds()} className={"FPS-primary-button mt-3"}>Add Funds</Button>
                </div>
            </div>}
        </Container>

    )
}
