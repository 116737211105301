import {Card} from "react-bootstrap";
import React, {FC} from "react";
import {BookingSchema} from "../schemas/booking-schema";
import {BookingModeExt} from "../definitions/booking-mode";
import {TimeUtils} from "../utils/time-utils";

export const CoachBookingMiniListItem: FC<{ bookingData: BookingSchema; hasActions: boolean; }> = function (props) {
    return (<div className={"coach-booking-list-item clickable"} key={props.bookingData.id}>
            <Card className={'mb-2'}>
                <Card.Body>
                    <div className="p-2">
                        <div className="booking-request-row py-1" style={{fontWeight: "bold"}}>
                            {BookingModeExt.toDisplayLabel(props.bookingData.bookingMode)}
                        </div>
                        <div className="booking-request-row py-1">
                            Placed at: {TimeUtils.timestampToLocaleString(props.bookingData.created)}
                        </div>
                        {props.bookingData.hours && <div className="booking-request-row py-1">
                            {TimeUtils.hoursToHumanizedString(props.bookingData.hours)}
                        </div>}
                        {props.bookingData.booking &&
                            <div className={"booking-request-time"}>
                                <div className="booking-request-row">
                                    Start: {TimeUtils.dateToLocaleString(props.bookingData.booking?.start)}
                                </div>
                                <div className="booking-request-row py-1">
                                    End: {TimeUtils.dateToLocaleString(props.bookingData.booking?.end)}</div>
                                {props.bookingData.proceedComment &&
                                    <div className="booking-request-row py-1">
                                        <div className="booking-request-bold">Reason:</div>
                                        {props.bookingData.proceedComment}</div>}
                            </div>}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};
