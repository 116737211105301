import { NavLink } from "react-router-dom";
import { routes } from "../../App";
import { ReactNode } from "react";

export type PaymentOrderConfirmationProps = {
    productType: "courses" | "funds";
    productId?: string | null;
    amount?: number;
    paymentIntent?: string | null;
};

const PaymentConfirmationPanel = ({ to, link, title }: { to: keyof typeof routes | string, link: ReactNode, title: ReactNode }) => {
    return (
        <>
            <p className="tw-text-md tw-text-gray-100">{title}</p>
            <NavLink
                className="tw-w-full tw-bg-red-100 tw-no-underline tw-text-white tw-font-bold tw-border-none tw-outline-none tw-px-4 tw-py-2.5 tw-rounded-md tw-uppercase"
                to={to}
            >
                {link}
            </NavLink>
        </>
    )
}

const ProductTypePanel = ({ productType, amount }: Pick<PaymentOrderConfirmationProps, 'productType' | 'amount'>) => {
    if (productType === 'courses') {
        return <PaymentConfirmationPanel to="/student-dashboard" link="Start Learning" title={<>Purchase successful!<br />The course may take a few minutes to show up in your account.<br /><br />Click below to start learning!</>} />;
    }
    return <PaymentConfirmationPanel to="/coaches" link="Find a Coach" title={`$${amount} has been added to your balance.`} />;
}

export const PaymentOrderConfirmation = ({productType, amount}: PaymentOrderConfirmationProps) => {
    return (
        <div className="tw-col-span-2 tw-flex tw-h-full tw-bg-gray-300 tw-py-6">
            <div className="tw-mx-auto tw-text-center">
                <h5 className="tw-text-white tw-font-bold tw-text-lg tw-mb-6">Thank you for your purchase!</h5>
                <ProductTypePanel productType={productType} amount={amount} />
            </div>
        </div>
    );
}
