import { useCurrentUser } from "../../hooks/use-current-user";
import { useCoursesByUser } from "../../hooks/use-courses";
import { CourseList, CourseListItem } from "../../components/CourseList";
import { useState } from "react";
import { EnvUtils } from "../../utils/env-utils";
import { CoursePage } from "../common/CoursePage";

export function CoursesTab() {
  const { user } = useCurrentUser();
  const { allStudentCourses: courses } = useCoursesByUser(user);
  const [currentCourseId, setCurrentCourseId] = useState<string | null>(null);

  if (currentCourseId) {
    return (
      <div className="tw-mt-36 tw-ml-36 tw-mr-16">
        <CoursePage courseId={currentCourseId} setCurrentCourseId={setCurrentCourseId} />
      </div>
    );
  }

  return (
    <section>
      <div className="tw-mt-36 tw-mx-16">
        <h3 className="lg:tw-text-2xl tw-text-xl tw-font-bold tw-filter-none tw-normal-case tw-mb-0 tw-pb-0 tw-text-red-100">Courses</h3>
        <div className="">
          {courses?.length ? courses.map(course => (
            <CourseListItem
              key={course.id}
              image={course.image}
              name={course.name}
              description={course.description}
              id={course.id}
              price={course.price}
              author={course.coachPointer}
              actionPanel={
                () => (
                  <button className="tw-no-underline tw-max-w-xs tw-inline-flex tw-justify-center tw-self-center md:tw-self-auto tw-items-center tw-rounded-md tw-border tw-border-transparent tw-bg-red-100 tw-px-6 tw-py-3.5 tw-font-bold tw-uppercase tw-leading-4 tw-text-white tw-shadow-sm hover:tw-bg-red-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-red-100 focus:tw-ring-offset-2" onClick={() => setCurrentCourseId(course.id)}>Start Learning</button>
                )
              }
            />
          )) : <div>
            <h3 className="lg:tw-text-2xl tw-text-xl tw-font-bold tw-filter-none tw-normal-case tw-mb-0 tw-pb-0 tw-text-red-100">Available Courses</h3>
            <p className="tw-mb-4">Learn from our talented coaches</p>
            <CourseList coachId={`${EnvUtils.readString("REACT_APP_COURSE_ID")}` ?? null} />
          </div>}
        </div>
      </div>
    </section>
  )
}
