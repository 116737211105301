export enum BookingStatus {
    Pending = 0,
    Accepted = 1,
    Complete = 2,
    Cancelled = 3,
    Rejected = 4,
}

export abstract class BookingStatusExt {
    public static toDisplayLabel(bookingMode: BookingStatus | null | undefined): string {
        switch (bookingMode) {
            case BookingStatus.Pending:
                return 'Pending Confirmation';
            case BookingStatus.Accepted:
                return 'Session Accepted';
            case BookingStatus.Complete:
                return 'Session Complete';
            case BookingStatus.Cancelled:
                return 'Session Cancelled';
            case BookingStatus.Rejected:
                return 'Session Rejected';
            default:
                return 'Unknown';
        }
    }
}
