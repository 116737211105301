import { useStoreActions } from "../models";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useCurrentUser } from "./use-current-user";

export function useChangePhoneNumber(): {
  changePhoneNumber: () => void,
  isBusyChangePhoneNumber: boolean,
} {
  const usersStoreActions = useStoreActions(state => state.users);
  const uiStoreActions = useStoreActions((actions) => actions.ui);
  const { user, userLoading, userError } = useCurrentUser();

  useEffect(() => {
    if (userError) {
      uiStoreActions.addMessageAlert({
        title: 'Phone number change failed!',
        subtitle: `Please retry later or contact an administrator. Error: ${userError}`,
      });
    }
  }, [uiStoreActions, userError])

  async function changePhoneNumber(): Promise<void> {
    const newPhoneNumber = await uiStoreActions.addSingleInputAlert({
      title: "Update Phone Number",
      message: "Enter a new Phone Number for your account. Phone Number must have Country Code",
      inputType: 'string',
      required: true,
    });
    if (!newPhoneNumber) return;
    const confirmPhoneNumber = await uiStoreActions.addSingleInputAlert({
      title: "Confirm Phone Number",
      message: "Please re-enter your phone number for confirmation.",
      inputType: 'string',
      required: true,
    });
    if (!confirmPhoneNumber) return;
    if (newPhoneNumber !== confirmPhoneNumber) {
      await uiStoreActions.addMessageAlert({
        title: 'Phone Number do not match',
        subtitle: 'Please recheck the phone number you entered and retry.',
      });
      return;
    }
    if (!user?.uid) return;
    const success = await usersStoreActions.editStudentProfile({
      phoneNumber: newPhoneNumber,
      updateId: user.uid
    });
    if (!success) return;
    toast('Phone Number updated successfully!');
  }

  return {
    changePhoneNumber,
    isBusyChangePhoneNumber: userLoading,
  }
}
