import clsx from "clsx";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStoreActions } from "../../models";

export enum CheckoutProcessStep {
  PAYMENT_METHOD,
  REVIEW_STRIPE,
  REVIEW_PAYPAL,
  ORDER_CONFIRMATION
}

const NavHeader = ({ children, hidden }: PropsWithChildren<{ hidden: boolean }>) => {
  return (
    <h4 className={clsx('tw-text-lg tw-my-auto', hidden ? "tw-text-gray-100 md:tw-flex tw-hidden" : "tw-text-white tw-flex")}>
      {children}
    </h4>
  );
}

const CheckoutNavbar: React.FC = () => {

  const navigate = useNavigate();

  const [getCheckoutProcessStep, setCheckoutProcessStep] = useState<CheckoutProcessStep>(CheckoutProcessStep.PAYMENT_METHOD);
  const [queryParams] = useSearchParams();

  const paymentIntent = queryParams.get("payment_intent");
  const redirectStatus = queryParams.get("redirect_status");

  const cartStoreActions = useStoreActions(state => state.cart);

  useEffect(() => {
    if (paymentIntent && redirectStatus === "succeeded") {
      setCheckoutProcessStep(CheckoutProcessStep.ORDER_CONFIRMATION);
    }
  }, [paymentIntent, redirectStatus]);


  const cancel = () => {
    cartStoreActions.clear();
    navigate(-1);
  }

  return (
    <div className="tw-flex tw-flex-col tw-w-screen">
      <div className="tw-bg-dp-04 tw-p-5 tw-shadow-md">
        <div className="tw-flex tw-w-full tw-justify-between">
          <div>
            {getCheckoutProcessStep !== CheckoutProcessStep.ORDER_CONFIRMATION && (
              <button
                className="tw-text-gray-100 tw-bg-transparent tw-border-none tw-outline-none tw-uppercase tw-bold tw-text-md tw-flex tw-my-auto tw-no-underline tw-cursor-pointer"
                onClick={cancel}
              >
                Cancel
              </button>
            )}
          </div>
          <div className="tw-flex md:tw-mx-auto tw-ml-auto tw-space-x-6">
            <NavHeader hidden={getCheckoutProcessStep !== CheckoutProcessStep.PAYMENT_METHOD}>
              Payment Method
            </NavHeader>
            <span className="tw-text-lg tw-text-gray-100 tw-my-auto md:tw-flex tw-hidden">&gt;</span>
            <NavHeader hidden={getCheckoutProcessStep !== CheckoutProcessStep.REVIEW_STRIPE}>
              Review Purchase
            </NavHeader>
            <span className="tw-text-lg tw-text-gray-100 tw-my-auto md:tw-flex tw-hidden">&gt;</span>
            <NavHeader hidden={getCheckoutProcessStep !== CheckoutProcessStep.ORDER_CONFIRMATION}>
              Confirmation
            </NavHeader>
          </div>
        </div>
      </div>
    </div>
  )
};

export default CheckoutNavbar;
