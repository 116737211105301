import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useCoursesByCoach } from "../hooks/use-courses";
import { ModelPointer } from "../interfaces/model-pointer";
import { useCurrentUser } from "../hooks/use-current-user";
import { routes } from "../App";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { functions } from "../config/firebase-config";
import { CallableResponse } from "../definitions/callable-response";
import { useCallback } from "react";
import currency from "currency.js";
import { useStoreActions } from "../models";

export type CourseListItemProps = {
  image: string;
  name: string;
  description: string;
  author: ModelPointer;
  price: number;
  id: string;
  actionPanel: React.ElementType;
};

const Ribbon = ({ children, backgroundColor, color, fontFamily }: any) => {
  return (
    <div className="tw-absolute tw-top-0 -tw-left-[15px]">
      <svg height="120" width="180">
        <g transform="scale(-1.35,1.35) translate(-90, 0)">
          <polygon points="0 15, 15 30, 0 45, 90 45, 90 15" fill={backgroundColor} strokeWidth="0" />
          <polygon points="80 60, 80 40, 90 45" fill={`${backgroundColor}77`} strokeWidth="0" />
        </g>
      </svg>
      <span style={{ color, fontFamily }} className="tw-absolute tw-top-[29px] tw-left-[13px] tw-text-md tw-font-bold">{children}</span>
    </div>
  );
};

export function CourseListItem({
  image,
  name,
  author,
  description,
  actionPanel,
}: CourseListItemProps) {
  const ActionPanel = actionPanel;

  return (
    <div className="tw-w-full tw-rounded lg:tw-max-w-full lg:tw-flex tw-bg-dp-01 tw-p-4 tw-relative">
      <Ribbon backgroundColor="#ee2337" color="#ffffff">
        Featured
      </Ribbon>
      <div
        className="tw-h-48 md:tw-h-64 md:tw-w-96 tw-flex-none tw-bg-contain tw-bg-no-repeat tw-rounded tw-bg-center md:tw-bg-left tw-text-center tw-overflow-hidden"
        style={{ backgroundImage: `url(${image})` }}
        title=""
      />
      <div className="tw-w-full tw-border-r tw-border-b tw-border-l tw-rounded-b lg:tw-rounded-b-none tw-pt-8 md:tw-px-8 md:tw-pt-0 tw-flex tw-flex-col tw-justify-between tw-leading-normal">
        <div className="tw-mb-8">
          <div className="tw-text-white tw-font-bold tw-text-xl tw-mb-2">
            {name}
          </div>
          <p className="tw-text-gray-100 tw-text-base">{description}</p>
          <div className="tw-font-bold">
            <p className="tw-text-white tw-leading-none">By {author.name}</p>
          </div>
        </div>
        <ActionPanel />
      </div>
    </div>
  );
}

const useActionPanel = () => {
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const cartStoreActions = useStoreActions(state => state.cart);
  const [executeCallable] = useHttpsCallable<{ courseId: string }, CallableResponse>(functions, 'purchaseCourse');

  const getActionPanel = useCallback((courseId: string, price: number) => {
    const needsFunds = (user?.balance ?? 0) < price;
    function PurchaseCourseActionPanel() {
      const purchaseCourse = useCallback(async () => {
        if (!user) {
          return navigate({
            pathname: routes.login,
            search: `?redirect=${location.pathname}`,
          });
        }
        if (needsFunds) {
          cartStoreActions.add({ productId: courseId, type: 'courses', amount: 1 })
          return navigate(routes.checkout);
        }
        await executeCallable({ courseId });
        return navigate(routes.studentDashboard);
      }, []);

      return (
        <section className="tw-flex tw-justify-center md:tw-justify-between tw-rounded-lg tw-w-full">
          <span className="tw-hidden md:tw-inline-block tw-text-2xl tw-font-bold tw-text-red-100">
            {currency(price).format()}
          </span>
          <button
            className="tw-inline-flex tw-items-center tw-rounded-md tw-border tw-border-transparent tw-bg-red-100 tw-px-6 tw-py-3.5 tw-font-bold tw-uppercase tw-leading-4 tw-text-white tw-shadow-sm hover:tw-bg-red-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-red-100 focus:tw-ring-offset-2"
            onClick={purchaseCourse}
          >
            Purchase Now
          </button>
        </section>
      );
    }
    const hasCourse = user?.courses?.includes(courseId);
    if (hasCourse) {
      return StudentCoursesActionPanel;
    }
    return PurchaseCourseActionPanel;
    // addFunds isn't memoized in the useFunds function, adding it in the dependency array would rerun each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },
    [user, executeCallable]
  );

  return getActionPanel;
};

function StudentCoursesActionPanel() {
  return <NavLink className="tw-no-underline tw-max-w-xs tw-inline-flex tw-justify-center tw-self-center md:tw-self-auto tw-items-center tw-rounded-md tw-border tw-border-transparent tw-bg-red-100 tw-px-6 tw-py-3.5 tw-font-bold tw-uppercase tw-leading-4 tw-text-white tw-shadow-sm hover:tw-bg-red-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-red-100 focus:tw-ring-offset-2" to={routes.studentDashboard}>My Courses</NavLink>
}

export function CourseList({ coachId }: { coachId: null | string }) {
  const { allCourses: courses } = useCoursesByCoach(coachId ?? "");
  const getActionPanel = useActionPanel();

  if (!coachId || !courses || !courses.length) {
    return null;
  }

  return (
    <section className="tw-flex tw-flex-col tw-col-span-2 tw-mb-4">
      <div>
        {courses.map((course) => {
          const actionPanel = getActionPanel(course.id, course.price);
          return (
            <CourseListItem
              key={course.id}
              image={course.image}
              name={course.name}
              description={course.description}
              id={course.id}
              price={course.price}
              author={course.coachPointer}
              actionPanel={actionPanel}
            />
          );
        })}
      </div>
    </section>
  );
}
