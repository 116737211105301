import React, {useEffect} from "react";
import {Button, Container, Form, Row, Stack} from "react-bootstrap";
import './SubmitVodPage.scss';
import {useNavigate, useParams} from "react-router-dom";
import {useUser} from "../../hooks/use-user";
import {SubmitHandler, useForm} from "react-hook-form";
import {useStoreActions} from "../../models";
import {useCurrentUser} from "../../hooks/use-current-user";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useBusy} from "../../hooks/use-busy";
import {SubmitVodPayload} from "../../interfaces/submit-vod-payload";
import {FieldError} from "../../components/form/FieldError";
import _ from "lodash";
import {VodTypeExt} from "../../definitions/vod-type";
import humanizeDuration from "humanize-duration";
import Slider from "rc-slider";
import {useCoachPricing} from "../../hooks/use-coach-pricing";
import {useGameOnce} from "../../hooks/use-game";
import { Helmet } from "react-helmet";

export const SubmitVodPage = () => {
    const {isBusy, setIsBusy} = useBusy();
    let {username} = useParams();
    const navigate = useNavigate();
    const {userData: coachData, userLoading, userError} = useUser(username!);
    const {game} = useGameOnce(coachData?.coachingGame);
    const {user} = useCurrentUser();
    const bookingStoreAction = useStoreActions((actions) => actions.booking);
    const {pricingPack} = useCoachPricing(coachData, game);

    useEffect(() => {
        console.log(pricingPack);
    }, [pricingPack])

    const {
        register,
        handleSubmit,
        formState: {errors},
        watch,
        setValue,
    } = useForm<SubmitVodPayload>({
        shouldUnregister: true
    });

    const {hours} = watch();
    useEffect(() => {
        setValue('hours', 0.5)
    }, [])

    const onSubmit: SubmitHandler<SubmitVodPayload> = async (payload) => {
        console.log(payload)
        if (!coachData?.uid || !coachData.coachingGame || !user?.uid) return;
        setIsBusy(true);
        const success = await bookingStoreAction.submitVod({
            ...payload,
            gameId: coachData.coachingGame,
            coachPointer: {
                id: coachData.uid,
                name: coachData.displayName
            },
            studentPointer: {
                id: user?.uid,
                name: user.displayName,
            },
        });
        setIsBusy(false);
        if (!success) return;
        navigate(-1);
    };

    async function onDiscard(): Promise<void> {
        navigate(-1);
    }

    function getMaxSliderValue(): number {
        return 60 * 12;
    }

    function getCurrentSliderValue(): number {
        return 60 * (hours ?? 0);
    }

    if (!userLoading && userError) return <p>Loading error occurred: {userError.message}</p>

    function calculateFee(): number {
        return (pricingPack?.vodRate || 0) * (hours || 0);
    }

    return (<BusyOverlay isBusy={isBusy || userLoading}>
        <Helmet>
            <title>FPS Lounge | Submit VOD</title>
        </Helmet>
        <Container className={'page submit-vod-page py-5'}>
            <div className="box-outline">
                <div className="p-5">
                    {coachData && <Row>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <h1 className={"text-center primary-color"}>Submit VOD</h1>
                                <h2 className={'text-center pb-5'}>{coachData.displayName}</h2>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        VOD Title
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder="VOD Title"
                                        {...register("name", {
                                            required: "Field is required",
                                        })}
                                    />
                                    <FieldError message={errors.vodUrl?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        VOD Description
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder="VOD Description"
                                        {...register("description", {
                                            required: "Field is required",
                                        })}
                                    />
                                    <FieldError message={errors.vodUrl?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Describe what you want the coach to focus on
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder="Explain what to focus on"
                                        {...register("focus", {})}
                                    />
                                    <FieldError message={errors.focus?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Select {...register('vodType', {
                                        required: "Field is required",
                                    })}>
                                        <option value={''}>Select VOD Type</option>
                                        {_(VodTypeExt.allVodTypes).map(vodType => <option key={vodType}
                                                                                          value={vodType}>{VodTypeExt.toDisplayLabel(vodType)}</option>).value()}
                                    </Form.Select>
                                    <FieldError
                                        message={errors.vodType?.message}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label style={{textAlign: "left", width: "100%"}}>
                                        Paste your VOD URL
                                    </Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder="Paste a link to your VOD"
                                        {...register("vodUrl", {
                                            shouldUnregister: true,
                                            required: "Field is required",
                                        })}
                                    />
                                    <FieldError message={errors.vodUrl?.message}/>
                                </Form.Group>
                                <div className={"p-4"}>
                                    <div className="vod-text-main">
                                        <h4>
                                            <div className="primary-color vod-text">Coaching Time:</div>
                                        </h4>
                                        <h4>{humanizeDuration(Math.round((hours ?? 0) * 3600 * 1000))}</h4>
                                    </div>

                                    <Stack direction={"vertical"}>
                                        <div className={'d-flex'}
                                             style={{justifyContent: "space-between"}}>
                                            <div className="vod-text-main">
                                                <h4>
                                                    <div className="primary-color vod-text">VOD Length:</div>
                                                </h4>
                                                <h4>{humanizeDuration(Math.round(((hours ?? 0) / 3) * 3600 * 1000))}</h4>
                                            </div>
                                            <div className="vod-text-main">{coachData &&
                                                <h4>Session Fee: $ {calculateFee()}</h4>}</div>
                                        </div>
                                        <div className="book-coach-slider pt-4"><Slider
                                            handleStyle={{width: 27, height: 27, marginTop: -11}}
                                            value={getCurrentSliderValue()} min={30}
                                            max={getMaxSliderValue()} range={false}
                                            step={30}
                                            defaultValue={0}
                                            onChange={(value) => setValue('hours', value as number / 60)}/>
                                        </div>
                                    </Stack>
                                </div>
                                <Stack direction={"horizontal"} gap={2}
                                       className={'mt-5 submit-vod-btn-main'}>
                                    <Button type="submit" className={"dashboard-primary-btn w-100"}>
                                        Save
                                    </Button>
                                    <Button className={"w-100 dashboard-primary-outline-btn"}
                                            onClick={() => onDiscard()}>
                                        Cancel
                                    </Button>
                                </Stack>
                            </div>
                        </Form>
                    </Row>}
                </div>
            </div>

        </Container></BusyOverlay>);
}
