import { action, thunk, Thunk, Action } from "easy-peasy";

export type CartItemPayload = {
  productId: string;
  type: "courses" | "funds";
  amount: number;
};

export interface CartModel {
  data: CartItemPayload[];
  add: Action<CartModel, CartItemPayload>, 
  clear: Action<CartModel, void>;
} 

export const cart: CartModel = {
  data: [],

  add: action((state, payload) => {
    state.data.unshift(payload);
  }),

  clear: action((state) => {
    state.data = [];
  })
} 
