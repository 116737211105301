import "../../index.scss";
import AsyncContent from "./AsyncContent";
import { SpinnerPadded } from "../../components/SpinnerPadded";
import CheckoutNavbar from "./CheckoutNavbar";
import { FC, Suspense } from "react";
import { BusyOverlay } from "../../components/BusyOverlay";
import { useBusy } from "../../hooks/use-busy";

export enum CheckoutProcessStep {
  PAYMENT_METHOD,
  REVIEW_STRIPE,
  REVIEW_PAYPAL,
  ORDER_CONFIRMATION
}

export const CheckoutProcessPage: FC = () => {
  const { isBusy, setIsBusy } = useBusy();
  return (
    <>
      <CheckoutNavbar />
      <Suspense fallback={<SpinnerPadded />}>
        <BusyOverlay isBusy={isBusy}>
          <AsyncContent setIsBusy={setIsBusy} />
        </BusyOverlay>
      </Suspense>
    </>
  )
}
