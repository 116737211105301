import {Button, Table} from "react-bootstrap";
import React from "react";
import {useNavigate} from "react-router-dom";
import {routes} from "../../App";
import {GameSchema} from "../../schemas/game-schema";
import "./AdminGameTab.scss"
import {useGames} from "../../hooks/use-games";

export function AdminGamesTab() {
    const navigate = useNavigate();
    const {allGames, gamesLoading: loading} = useGames();

    function buildGamesTableRow(gameData: GameSchema) {
        return <tr className={"table-row-bg"} key={gameData.id}>
            <td className={"name-td"}>{gameData.name}</td>
            <td>{gameData.type}</td>
            <td>{gameData.description}</td>
            <td className={"buttons-td"}><Button className={"dashboard-secondary-btn"}
                                                 onClick={() => navigate(routes.editGame.replace(':gameId', gameData.id))}>Edit</Button>
            </td>
        </tr>;
    }

    return <>
        <div className="admin-game-tab">
            <h3 className={"primary-color"}>Games</h3>
            <p>This section will allow you to manage the games available in the system for coaches and students</p>
            <div className="dashboard-box-outline">
                <div className="p-4">
                    <div className="py-3">
                        <Button className={"dashboard-secondary-btn"} variant={"primary"}
                                onClick={() => navigate(routes.addGame)}>Add
                            New Game</Button>
                    </div>
                    {loading || <Table className={"add-new-games-table"} striped={false}>
                        <thead>
                        <tr>
                            <td>Game Name</td>
                            <td>Game Type</td>
                            <td>Game Description</td>
                        </tr>
                        </thead>
                        <tbody>
                        {loading || allGames?.map(buildGamesTableRow)}
                        </tbody>
                    </Table>}
                </div>
            </div>

        </div>


    </>;
}
