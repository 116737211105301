import {
  DocumentData,
  DocumentSnapshot,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue
} from "firebase/firestore";
import { PricingPack } from "../interfaces/pricing-pack";
import { CalendarEvent } from "../definitions/calendar-event";
import { v4 as uuid } from "uuid";
import { TimeUtils } from "../utils/time-utils";
import { AppConfig } from "../config/app-config";
import _ from "lodash";
import { UserLanguage } from "../interfaces/user-language";
import { DataPointer } from "../interfaces/data-pointer";
import { DataConfig } from "../config/data-config";
import { GenericDict } from "../interfaces/generic-dict";
import { GameSchema } from "./game-schema";
import { GameUtils } from "../utils/game-utils";
import { Zone } from "luxon";

export const ADMIN_ROLE: string = 'admin';
export const STUDENT_ROLE: string = 'student';
export const COACH_ROLE: string = 'coach';

interface UserSchemaBase {
  uid: string,
  created: Timestamp,
  email: string,
  paypalId?: string,
  name: string,
  fullName?: string,
  role: string,
  languages?: string[],
  platforms?: string[],
  inputMethods?: string[],
  gamerTag?: string,
  discord?: string,
  youtube?: string,
  phoneNumber?: string,
  twitterUsername?: string;
  twitchUsername?: string;
  permalink?: string,
  vodBio?: string,
  bio?: string,
  courses?: string[],
  coachingGame?: string,
  coachRequested?: boolean,
  coachRequestResult: boolean | null,
  coachApprovalRemark?: string,
  balance: number,
  displayName: string;
  // use with caution, null if user haven't customized pricing
  pricingPack: PricingPack | null;
  chargingMode: false | string[];
  availabilityRanges: [number, number][];
  kdRatio?: string;
  winRate?: string;
  totalKills?: string;
  totalDeaths?: string;
  mainPoints?: DataPointer[];
  isFeatured?: boolean;
  isHidden?: boolean;
  profileImage?: string;
  filters?: GenericDict<string>;
  timezone?: string;
}

export interface UserSchema extends UserSchemaBase {
  isOnline?: Timestamp,
  snapshot: DocumentSnapshot,
  courseProgress: Record<string, Record<number, number>>;
}

export interface AlgoliaUserSchema extends UserSchemaBase {
  isOnline?: number,
}

export interface ResetPasswordPayload {
  email: string;
}

export interface LoginPayload {
  email: string;
  password: string;
}

export interface RegisterPayload {
  email: string;
  name: string;
  fullName: string;
  discord: string;
  password: string;
  confirmPassword: string;
}

export interface AlterAdminRolePayload {
  email: string;
  enable: boolean;
}

export const userConverter: FirestoreDataConverter<UserSchema> = {
  toFirestore(post: WithFieldValue<UserSchema>): DocumentData {
    return post;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): UserSchema {
    const availabilityRanges = snapshot.get('availabilityRanges');
    return {
      ...snapshot.data(options),
      uid: snapshot.id,
      displayName: snapshot.get('gamerTag') ?? snapshot.get('name') ?? 'Unnamed',
      balance: snapshot.get('balance') ?? 0,
      availabilityRanges: availabilityRanges ? JSON.parse(availabilityRanges) : [],
      coachRequestResult: snapshot.get('coachRequestResult') ?? null,
      snapshot: snapshot,
    } as UserSchema;
  },
};

export abstract class UserSchemaExt {
  static canWithdraw(user: UserSchema | null): boolean {
    return !!user && user.balance >= 50;
  }

  static isCoach(coachData: UserSchema) {
    return coachData.role === COACH_ROLE;
  }

  static isStudent(coachData: UserSchema) {
    return coachData.role === STUDENT_ROLE;
  }

  static isAdmin(coachData: UserSchema) {
    return coachData.role === ADMIN_ROLE;
  }

  static hourlyRateString(user: UserSchema | null | undefined): string {
    if (!user?.pricingPack?.hourlyRate) return '-';
    return `$${user.pricingPack.hourlyRate.toFixed(2)}/hr`;
  }

  static hourlyRateStringAlgolia(user: AlgoliaUserSchema | null | undefined): string {
    if (!user?.pricingPack?.hourlyRate) return '-';
    return `$${user.pricingPack.hourlyRate.toFixed(2)}/hr`;
  }

  static availabilityDateRanges(user: UserSchema | null | undefined): CalendarEvent[] {
    if (!user) return [];
    const coachTimezone = UserSchemaExt.getTimezone(user);
    const rangeList = user.availabilityRanges;
    return rangeList.map(event => {
      return {
        id: uuid(),
        start: TimeUtils.weeklyHalfHourIdToDate(event[0], TimeUtils.nowTz(coachTimezone)).toJSDate(),
        end: TimeUtils.weeklyHalfHourIdToDate(event[1], TimeUtils.nowTz(coachTimezone), true).toJSDate(),
      };
    });
  }

  static isOnline(user: UserSchema | undefined | null): boolean {
    if (!user?.isOnline) return false;
    const userTimezone = UserSchemaExt.getTimezone(user);
    const lastOnlineDate = TimeUtils.dateOfTz(user.isOnline.toMillis(), userTimezone);
    return !!(user?.isOnline && !(lastOnlineDate.plus({ second: AppConfig.onlineModeExpirySeconds }) <= TimeUtils.nowTz(userTimezone)))
  }

  static getTimezone(user: UserSchemaBase): Zone {
    return TimeUtils.findTimezoneByZoneName(user.timezone) ?? TimeUtils.defaultTimezone!;
  }

  static getTimezoneName(user: UserSchemaBase): string {
    return this.getTimezone(user).name;
  }

  static languageInfo(user: Pick<UserSchemaBase, 'languages'> | undefined | null): UserLanguage[] | null {
    if (!user) return null;
    return _.filter(DataConfig.supportedLanguages, lang => !!user.languages?.includes(lang.id));
  }

  static platformInfo(user: UserSchema | undefined | null): DataPointer[] | null {
    if (!user) return null;
    return _.filter(DataConfig.supportedPlatforms, platform => !!user.platforms?.includes(platform.id));
  }

  static inputMethodInfo(user: UserSchema | undefined | null): DataPointer[] | null {
    if (!user) return null;
    return _.filter(DataConfig.supportedInputMethods, inputMethod => !!user.inputMethods?.includes(inputMethod.id));
  }

  static isOnlineAlgolia(user: AlgoliaUserSchema | undefined | null): boolean {
    if (!user?.isOnline) return false;
    const userTimezone = UserSchemaExt.getTimezone(user);
    const lastOnlineDate = TimeUtils.dateOfTz(user.isOnline, userTimezone);
    return !!(user?.isOnline && !(lastOnlineDate.plus({ second: AppConfig.onlineModeExpirySeconds }) < TimeUtils.nowTz(userTimezone)))
  }

  public static async getCoachingGameData(user: UserSchema): Promise<GameSchema | null> {
    return GameUtils.getGameDataByIdFetched(user.coachingGame);
  }

  public static async getPricingPack(user: UserSchema | undefined | null): Promise<PricingPack | null> {
    if (!user) return null;
    if (user?.pricingPack) return user.pricingPack;
    const coachingGame = await this.getCoachingGameData(user);
    return coachingGame?.pricingPack ?? null;
  }

  public static async getChargingMode(user: UserSchema | undefined | null): Promise<string[] | false | null> {
    if (!user) return null;
    if (user.chargingMode !== null && user.chargingMode !== undefined) return user.chargingMode;
    const coachingGame = await this.getCoachingGameData(user);
    return coachingGame?.chargingMode ?? null;
  }

  public static getPricingPackWithGame(user: UserSchema | undefined | null, game: GameSchema | undefined | null): PricingPack | null {
    if (!user) return null;
    if (user?.pricingPack) return user.pricingPack;
    return game?.pricingPack ?? null;
  }

  public static getChargingModeWithGame(user: UserSchema | undefined | null, game: GameSchema | undefined | null): string[] | false | null {
    if (!user) return null;
    if (user.chargingMode !== null && user.chargingMode !== undefined) return user.chargingMode;
    return game?.chargingMode ?? null;
  }
}
