import {userConverter, UserSchema} from "../schemas/user-schema";
import {collection, doc, DocumentReference, getDoc} from "firebase/firestore";
import {db} from "../config/firebase-config";

export abstract class UserUtils {
    public static getUserRefByUid(uid: string): DocumentReference<UserSchema> {
        return doc(collection(db, 'users'), uid).withConverter(userConverter);
    }

    public static async getUserDataByUid(uid: string): Promise<UserSchema | null> {
        if (!uid) return null;
        const documentSnapshot = await getDoc(this.getUserRefByUid(uid));
        return documentSnapshot.data() ?? null;
    }
}
