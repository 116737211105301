import { Button, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";

export function EmailConfirmed() {
  const navigate = useNavigate();
  const gotoDashboard = () => {
    navigate({ pathname: routes.root })
  }
  return (
    <div>
      <Helmet>
        <title>FPS Lounde | Email Confirmation</title>
      </Helmet>

      <Container className={"pb-5"}>
        <div style={{ marginBottom: '10rem' }}>
          <h1>.</h1>
        </div>
        <div className="tw-col-span-2 tw-flex tw-h-full tw-bg-gray-300 tw-py-6">
          <div className="tw-mx-auto tw-text-center">
            <h3 className="tw-text-white tw-font-bold tw-text-2xl tw-mb-6">Your email has been confirmed!</h3>
            <h6 className="tw-text-gray-400 tw-font-medium tw-text-lg tw-mb-6">Thank you for joining FPS Lounge!</h6>

            <Button style={{ marginTop: '10px', padding: '14px 24px' }} variant="primary" className="text-uppercase btn m-0" type="submit" onClick={gotoDashboard} >
              Home
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}
