import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue
} from "firebase/firestore";
import { ModelPointer } from "../interfaces/model-pointer";

export interface Videos {
  name: string,
  src: string,
  thumbnail: string,
  description: string,
}

export interface Sections {
  name: string,
  videos: Videos[],
}

export interface CourseSchema {
  id: string,
  created: Timestamp,
  modified: Timestamp,
  coachPointer: ModelPointer,
  image: string,
  image2: string,
  difficulty?: string,
  Length?: string,
  Lang?: string,
  name: string,
  bgVideo?: string | undefined,
  bgThumbnail?: string,
  description: string,
  stripeId: string | null,
  price: number,
  sections: Sections[],
}

export const courseConverter: FirestoreDataConverter<CourseSchema> = {
  toFirestore(course: WithFieldValue<CourseSchema>): DocumentData {
    return course;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): CourseSchema {
    return {
      id: snapshot.id,
      ...snapshot.data(options),
    } as CourseSchema;
  }
}
