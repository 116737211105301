import {thunk, Thunk} from "easy-peasy";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {addDoc, collection, updateDoc} from "firebase/firestore";
import {db, storage} from "../config/firebase-config";
import omit from "lodash/omit";
import {GamePayload} from "../schemas/game-schema";
import {doc} from "firebase/firestore";
import {CommonUtils} from "../utils/common-utils";
import {toast} from "react-toastify";

export interface GamesModel {
    addGame: Thunk<GamesModel, GamePayload, any, {}, Promise<boolean>>;
    uploadLogo: Thunk<GamesModel, File>;
}

export const games: GamesModel = {
    addGame: thunk(async (actions, game) => {
        try {
            if (game.updateId) { 
                if (game.logo) {
                    // TODO: Add support for uploading images.
                    toast("Updating logo after initially set is not supported.");
                }
                await updateDoc(doc(collection(db, 'games'), game.updateId), {
                    ...omit(CommonUtils.sanitizeData(game), "logo"),
                    orderIndex: 0,
                });
            } else {
                const logo = await actions.uploadLogo(game.logo[0]);
                const formattedGame = CommonUtils.sanitizeData({ ...game, logo });
                await addDoc(collection(db, 'games'), formattedGame);
            }
            return true;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }),

    uploadLogo: thunk(async (_actions, logo) => {
        const storageRef = ref(storage, "images/" + logo.name);
        const result = await uploadBytes(storageRef, logo);
        return getDownloadURL(result.ref);
    }),
};
