import { useEffect } from "react";
import { useCurrentUser } from "../../hooks/use-current-user";
import { useAllGains } from "../../hooks/use-gains";
import { AdminGainListItem } from "../../components/AdminGainListItem";
import { SpinnerPadded } from "../../components/SpinnerPadded";
import { getDocs } from "firebase/firestore";

export function AdminGainsTab() {
  const { userLoading } = useCurrentUser();
  const { allGains, gainsLoading, gainsQuery } = useAllGains();
  useEffect(() => {
    if (gainsQuery) getDocs(gainsQuery).then();
  }, [gainsQuery]);

  return (
    <div>
      <h3 className={"primary-color"}>All Transactions</h3>
      <p>Review your gains history below.</p>
      <div>
        {(gainsLoading || userLoading) && <SpinnerPadded />}
        {userLoading ||
          allGains?.map((gain) => (
            <div className="">
              <AdminGainListItem
                key={gain.id}
                hasActions={true}
                gainData={gain}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
