import {gameConverter, GameSchema} from "../schemas/game-schema";
import {collection, doc, DocumentReference, getDoc} from "firebase/firestore";
import {db} from "../config/firebase-config";

export abstract class GameUtils {
    public static getGameRefByUid(uid: string): DocumentReference<GameSchema> {
        return doc(collection(db, 'games'), uid).withConverter(gameConverter);
    }

    public static async getGameDataByIdFetched(gameId?: string | null): Promise<GameSchema | null> {
        if (!gameId) return null;
        const documentSnapshot = await getDoc(this.getGameRefByUid(gameId));
        return documentSnapshot.data() ?? null;
    }

    public static getGameDataById(allGames?: GameSchema[], gameId?: string): GameSchema | null {
        if (!gameId) return null;
        return allGames?.find(game => game.id === gameId) ?? null;
    }

    public static getGameDataByName(allGames?: GameSchema[], gameName?: string): GameSchema | null {
        if (!gameName) return null;
        return allGames?.find(game => game.name === gameName) ?? null;
    }
}
