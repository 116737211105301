import { updateDoc } from "firebase/firestore";
import { useChangePassword } from "../../hooks/use-change-password";
import { useChangePhoneNumber } from "../../hooks/use-change-phonenumber";
import { useCurrentUser } from "../../hooks/use-current-user";
import { TimeUtils } from "../../utils/time-utils";
import { toast } from "react-toastify";
import { useChangeEmail } from "../../hooks/use-update-email";

export function GeneralTab() {
  const { user } = useCurrentUser();
  const { changePassword } = useChangePassword();
  const { changePhoneNumber } = useChangePhoneNumber();
  const { changeEmail } = useChangeEmail();

  return (
    <div>
      <div className="tw-text-red-600 tw-text-xl tw-font-bold tw-mt-10">General Preferences</div>
      <div className="tw-text-gray-500 tw-text-base">Update your account information and timezone</div>
      <div className=" tw-py-2">&nbsp;</div>
      <div style={{ borderBottom: "2px solid rgba(245, 245, 245, 0.12)" }} className="tw-pb-6 sm:tw-flex sm:tw-justify-between">
        <div className="sm:tw-flex-none">
          <dt>Email</dt>
          <div className="tw-text-gray-500 tw-text-sm">Your email notifications will be sent to this email</div>
        </div>
        <div className="tw-flex tw-justify-between tw-gap-x-6 tw-mt-3 sm:tw-mt-0 ">
          <div>{user?.email}</div>
          {/* <div className="tw-text-red-600 hover:tw-text-red-800 tw-font-bold tw-cursor-pointer" onClick={changeEmail}>UPDATE</div> */}
        </div>
      </div>
      <div style={{ borderBottom: "2px solid rgba(245, 245, 245, 0.12)" }} className="tw-py-6 sm:tw-flex sm:tw-justify-between">
        <div className="sm:tw-flex-none">
          <dt>Phone Number</dt>
          <div className="tw-text-gray-500 tw-text-sm">Your SMS notifications will be sent to this phone number</div>
        </div>
        <div className="tw-flex tw-justify-between tw-gap-x-6 tw-mt-3 sm:tw-mt-0 sm:tw-pt-2 ">
          <div>{user?.phoneNumber}</div>
          <div className="tw-text-red-600 hover:tw-text-red-800 tw-font-bold tw-cursor-pointer" onClick={changePhoneNumber}>{user?.phoneNumber ? "UPDATE" : "ADD PHONE NUMBER"} </div>
        </div>
      </div>
      <div style={{ borderBottom: "2px solid rgba(245, 245, 245, 0.12)" }} className="tw-py-6 sm:tw-flex sm:tw-justify-between">
        <div className="sm:tw-flex-none">
          <dt>User ID</dt>
          <div className="tw-text-gray-500 tw-text-sm">Your ID helps us find you when you need help </div>
        </div>
        <div className="tw-mt-3">{user?.uid}</div>
      </div>
      <div style={{ borderBottom: "2px solid rgba(245, 245, 245, 0.12)" }} className="tw-py-6 sm:tw-flex sm:tw-justify-between">
        <div className="sm:tw-flex-none">
          <dt>Password</dt>
          <div className="tw-text-gray-500 tw-text-sm">Change the password to your account</div>
        </div>
        <div className="tw-text-red-600 hover:tw-text-red-800 tw-font-bold tw-cursor-pointer tw-mt-3 sm:tw-mt-0 sm:tw-pt-2 tw-text-end" onClick={changePassword}>CHANGE PASSWORD</div>
      </div>
      <div className="tw-py-6 sm:tw-flex sm:tw-justify-between">
        <div className="sm:tw-flex-none">
          <dt>Current Timezone</dt>
          <div className="tw-text-gray-500 tw-text-sm">Update your current timezone</div>
        </div>
        <div className="tw-flex tw-justify-between tw-gap-x-6 tw-mt-3 sm:tw-mt-0 ">
          <div>{user?.timezone}</div>
          <div onClick={async () => {
            if (!user) return;
            await updateDoc(user.snapshot.ref, {
              timezone: TimeUtils.guessTimezone().name,
            });
            toast('Timezone reset complete!');
          }} className="tw-text-red-600 hover:tw-text-red-800 tw-font-bold tw-cursor-pointer">UPDATE</div>
        </div>
      </div>
    </div>

  )
}
