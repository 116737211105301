import { useCurrentUser } from "../../hooks/use-current-user";
import { useCoursesByUser } from "../../hooks/use-courses";
import { CourseList, CourseListItem } from "../../components/CourseList";
import { useState } from "react";
import { EnvUtils } from "../../utils/env-utils";
import { CoursePage } from "../common/CoursePage";

export function StudentCoursesTab() {
  const { user, userLoading } = useCurrentUser();
  const { allStudentCourses: courses } = useCoursesByUser(user);
  const [currentCourseId, setCurrentCourseId] = useState<string | null>(null);

  if (currentCourseId) {
    return <CoursePage courseId={currentCourseId} setCurrentCourseId={setCurrentCourseId} />
  }

  return (
    <section>
      <h3 className="lg:tw-text-2xl tw-text-xl tw-font-bold tw-filter-none tw-normal-case tw-mb-0 tw-pb-0 tw-text-red-100">My Courses</h3>
      <p className="tw-mb-4">Your purchased courses will appear here for you to get started learning!</p>
      <div className="">
        {courses?.length ? courses.map(course => (
          <CourseListItem
            key={course.id}
            image={course.image}
            name={course.name}
            description={course.description}
            id={course.id}
            price={course.price}
            author={course.coachPointer}
            actionPanel={
              () => (
                <button className="tw-no-underline tw-max-w-xs tw-inline-flex tw-justify-center tw-self-center md:tw-self-auto tw-items-center tw-rounded-md tw-border tw-border-transparent tw-bg-red-100 tw-px-6 tw-py-3.5 tw-font-bold tw-uppercase tw-leading-4 tw-text-white tw-shadow-sm hover:tw-bg-red-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-red-100 focus:tw-ring-offset-2" onClick={() => setCurrentCourseId(course.id)}>Start Learning</button>
              )
            }
          />
        )) : <div>
          <div style={{ marginBottom: '3.75rem' }} className="tw-flex tw-justify-center tw-rounded tw-bg-dp-01">
            <div style={{ padding: '2.75rem' }} >You don't have any courses! Purchase a course now and start learning!
            </div> </div>
          <h3 className="lg:tw-text-2xl tw-text-xl tw-font-bold tw-filter-none tw-normal-case tw-mb-0 tw-pb-0 tw-text-red-100">Available Courses</h3>
          <p className="tw-mb-4">Learn from our talented coaches</p>
          <CourseList coachId={`${EnvUtils.readString("REACT_APP_COURSE_ID")}` ?? null} />
        </div>}
      </div>
    </section>
  )
}
