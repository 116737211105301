import * as React from 'react';
import {Facet} from "../../definitions/facet";
import {useRefinementList} from "react-instantsearch-hooks-web";
import {Dropdown, DropdownButton, Form, Stack} from "react-bootstrap";
import './FacetRefinementList.scss';

type Props = {
    facet: Facet
};

export function FacetRefinementList(props: Props) {
    const refinementList = useRefinementList({attribute: props.facet.id});
    if (!refinementList.items.length) return <></>;
    return (
        <div className={'facet-refinement-list mx-2'}>
            <DropdownButton className={'filter-dropdown-button'} title={props.facet.label}>
                {refinementList.items.map(
                    item => <Dropdown.Item key={item.value}>
                        <Stack className={'facet-option'} direction={"horizontal"}>
                            <Form.Check checked={item.isRefined} readOnly={true}/>
                            <span className={'ms-2'}
                                  onClick={() => {
                                      console.log(props.facet)
                                      console.log(item.value)
                                      refinementList.refine(item.value);
                                  }}>{item.label}</span>
                        </Stack>
                    </Dropdown.Item>)}

            </DropdownButton>
        </div>
    );
};
