import {action, Action, Computed, computed, thunk, Thunk} from "easy-peasy";
import {AlertRenderProps, AlertSpec} from "../definitions/alert-spec";
import _ from "lodash";
import {ReactElement} from "react";

export interface UiModel {
    alerts: AlertSpec[];

    /* actions */
    getTopAlert: Computed<UiModel, AlertSpec | null>;
    addRawAlert: Action<UiModel, AlertSpec>;
    removeTopAlert: Action<UiModel>;
    addMessageAlert: Thunk<UiModel, { title: string, subtitle?: string, }, any, {}, Promise<void>>;
    addComingSoonAlert: Thunk<UiModel, void, any, {}, Promise<void>>;
    addConfirmationAlert: Thunk<UiModel, {
        message: string,
        title?: string,
        cancelBtnText?: string,
        confirmBtnText?: string,
    }, any, {}, Promise<boolean>>;
    addSingleInputAlert: Thunk<UiModel, {
        message?: string,
        title: string,
        cancelBtnText?: string,
        confirmBtnText?: string,
        inputType?: string,
        required?: boolean,
        validationRegex?: RegExp,
        defaultValue?: string,
        backdrop?: boolean,
        allowOutsideClick?: boolean,
    }, any, {}, Promise<string | null>>;

    addCustomAlert: Thunk<UiModel, {
        title: string,
        closeOnClickOutside?: boolean,
        customClass?: string,
        builder: (props: AlertRenderProps<any>) => ReactElement
    }, any, {}, Promise<any | null>>;
}

export const ui: UiModel = {
    /* states */
    alerts: [],

    getTopAlert: computed((state) => {
        return _.first(state.alerts as AlertSpec[]) ?? null;
    }),
    /* actions */
    removeTopAlert: action((state) => {
        const visibleAlert = state.getTopAlert;
        if (!visibleAlert) return;
        state.alerts = _.remove(state.alerts, alert => alert === visibleAlert);
    }),
    addRawAlert: action((state, alert: AlertSpec) => {
        if (!alert.id) alert.id = `ALERT_${Date.now()}`;
        state.alerts = [
            ...state.alerts,
            alert,
        ];
    }),
    addMessageAlert: thunk(async (actions, alertPayload) => {
        return await new Promise<void>(resolve => {
            actions.addRawAlert({
                title: alertPayload.title,
                content: (<>{alertPayload.subtitle}</>),
                onConfirm: () => {
                    resolve();
                    actions.removeTopAlert();
                },
                onCancel: () => {
                    resolve();
                    actions.removeTopAlert();
                },
            });
        })
    }),
    addComingSoonAlert: thunk(async (actions) => {
        return actions.addMessageAlert({
            title: "Coming soon!",
            subtitle: "This feature will be available in an upcoming version.",
        });
    }),
    addConfirmationAlert: thunk(async (actions, alertPayload) => {
        return new Promise<boolean>(((resolve) => {
            actions.addRawAlert({
                title: alertPayload.title ?? 'Confirmation',
                content: (<>{alertPayload.message}</>),
                showConfirm: true,
                showCancel: true,
                cancelBtnText: alertPayload.cancelBtnText ?? 'No',
                confirmBtnText: alertPayload.confirmBtnText ?? 'Yes',
                onConfirm: () => {
                    resolve(true);
                    actions.removeTopAlert();
                },
                onCancel: () => {
                    resolve(false);
                    actions.removeTopAlert();
                },
            });
        }))
    }),
    addSingleInputAlert: thunk(async (actions, payload) => {
        return new Promise<string | null>(((resolve) => {
            actions.addRawAlert({
                title: payload.title,
                content: (<>{payload.message}<br/><br/></>),
                input: true,
                required: payload.required,
                inputType: payload.inputType,
                validationRegex: payload.validationRegex,
                defaultValue: payload.defaultValue,
                showConfirm: true,
                showCancel: true,
                cancelBtnText: payload.cancelBtnText ?? 'Cancel',
                confirmBtnText: payload.confirmBtnText ?? 'Confirm',
                onConfirm: (data) => {
                    resolve(data);
                    actions.removeTopAlert();
                },
                onCancel: () => {
                    resolve(null);
                    actions.removeTopAlert();
                },
            });
        }))
    }),

    addCustomAlert: thunk(async (actions, payload) => {
        return new Promise<any | null>(((resolve) => {
            actions.addRawAlert({
                title: payload.title,
                customClass: payload.customClass,
                type: "controlled",
                custom: true,
                showCancel: false,
                showConfirm: false,
                closeOnClickOutside: payload.closeOnClickOutside,
                content: (props) => payload.builder({
                    cancel: () => {
                        props.cancel();
                    },
                    confirm: (result: any) => {
                        props.confirm();
                        resolve(result);
                    },
                    onEnterKeyDownConfirm: props.onEnterKeyDownConfirm,
                    setAutoFocusInputRef: props.setAutoFocusInputRef,
                }),
                onConfirm: () => {
                    actions.removeTopAlert();
                },
                onCancel: () => {
                    actions.removeTopAlert();
                    resolve(null);
                },
            });
        }))
    }),
};

