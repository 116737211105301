import { useEffect, useRef, useState } from "react";
import { StudentOverviewTab } from "./StudentOverviewTab";
import { StudentBookingsTab } from "./StudentBookingsTab";
import { StudentWalletTab } from "./StudentWalletTab";
import { StudentCalendarTab } from "./StudentCalendarTab";
import { StudentCoursesTab } from "./StudentCoursesTab";
import { StudentGainsTab } from "../coach/StudentGainsTab";
import { StudentVodTab } from "./StudentVodTab";
import useCookie from "react-use-cookie";
import { useCurrentUser } from "../../hooks/use-current-user";
import { BusyOverlay } from "../../components/BusyOverlay";
import { useAddFunds } from "../../hooks/use-add-funds";
import { Helmet } from "react-helmet";
import { MdOutlineHome, MdPlayCircleOutline, MdOutlineCalendarToday, MdPersonSearch, MdOutlineFreeCancellation, MdOutlineReceiptLong, MdAccountBalanceWallet, MdMenuBook } from "react-icons/md";
import { AlgoliaCoachesPage } from "../common/AlgoliaCoachesPage";
import { CoachRegisterPage } from "../auth/CoachRegisterPage";
import { ChatPage } from "../common/ChatPage";
import { NotificationsPage } from "../common/NotificationsPage";
import { SettingsTab } from "../common/SettingsTab";
import { ReferralHeroData } from "../../components/ReferralHeroData";
import { TopNavbar } from "../../components/TopNavbar";

export const StudentDashboardPage = () => {
  const { user } = useCurrentUser();
  const { addFunds, isBusyAddFunds } = useAddFunds();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [activeTab, setActiveTab] = useCookie("activeTab", "first");
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = (ref: any, callback: any) => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  useEffect(() => {
    handleOutsideClick(sidebarRef, setSidebarVisible);
  }, [sidebarRef]);

  const getTabClassName = (tabName: string) => {
    return `tw-rounded-md tw-text-white tw-text-base tw-p-2 tw-m-2.5 tw-cursor-pointer md:hover:tw-bg-red-600/25 ${activeTab === tabName ? "tw-bg-red-600/50 tw-font-semibold" : ""}`;
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <BusyOverlay isBusy={isBusyAddFunds}>
      <Helmet>
        <title>FPS Lounge | Student Dashboard</title>
        <ReferralHeroData />
      </Helmet>
      <TopNavbar setActiveTab={setActiveTab} sidebarVisible={sidebarVisible} toggleSidebar={toggleSidebar} />

      <div className="tw-pt-16">
        {/* Sidebar */}
        <div className="tw-flex">
          <div ref={sidebarRef} className={`tw-fixed tw-flex tw-flex-col tw-justify-between md:tw-w-1/5 tw-w-4/5 tw-h-fill tw-bg-[#282F35] tw-transform tw-transition-transform tw-duration-500 tw-ease-in-out ${sidebarVisible ? 'tw-translate-x-0' : '-tw-translate-x-full'}`} style={{ zIndex: 501 }} >
            {/* Above Sidebar */}
            <div className="tw-flex tw-flex-col">
              <div className={getTabClassName("first")} onClick={() => { setActiveTab("first"); toggleSidebar(); }}>
                <MdOutlineHome className="tw-cursor-pointer" size={29} /> &nbsp;&nbsp; Dashboard
              </div>
              <div className={getTabClassName("second")} onClick={() => { setActiveTab("second"); toggleSidebar(); }}>
                <MdOutlineCalendarToday className="" size={29} /> &nbsp;&nbsp; My Calendar
              </div>
              <div className={getTabClassName("third")} onClick={() => { setActiveTab("third"); toggleSidebar(); }}>
                <MdOutlineFreeCancellation className="" size={29} /> &nbsp;&nbsp; Booking Requests
              </div>
              <div className={getTabClassName("fourth")} onClick={() => { setActiveTab("fourth"); toggleSidebar(); }}>
                <MdPlayCircleOutline className="" size={29} /> &nbsp;&nbsp; VOD Requests
              </div>
              <div className={getTabClassName("fifth")} onClick={() => { setActiveTab("fifth"); toggleSidebar(); }}>
                <MdOutlineReceiptLong className="" size={29} /> &nbsp;&nbsp; My Purchases
              </div>
              <div className={getTabClassName("sixth")} onClick={() => { setActiveTab("sixth"); toggleSidebar(); }}>
                <MdAccountBalanceWallet className="" size={29} /> &nbsp;&nbsp; My Wallet
              </div>
              <div className={getTabClassName("seventh")} onClick={() => { setActiveTab("seventh"); toggleSidebar(); }}>
                <MdMenuBook className="" size={29} /> &nbsp;&nbsp; My Courses
              </div>
              <div className="tw-text-sm tw-font-bold tw-ml-2.5">
                EXPLORE
              </div>
              <div className={getTabClassName("eight")} onClick={() => { setActiveTab("eight"); toggleSidebar(); }}>
                <MdPersonSearch className="" size={29} /> &nbsp;&nbsp; Coaches
              </div>
            </div>
            {/* Below Sidebar */}
            <div className="tw-flex tw-flex-col">
              <div className="tw-bg-gray-100/75 tw-h-[0.25px] tw-mb-3.5 tw-mx-3 "></div>
              <div className="tw-text-sm tw-font-bold tw-ml-2.5">&nbsp;&nbsp;AVAILABLE BALANCE</div>
              <div className="tw-text-lg tw-font-bold tw-text-white tw-ml-2.5">&nbsp;&nbsp;${user?.balance ?? "-"}</div>
              <button onClick={() => addFunds()} className="tw-rounded-md tw-text-white tw-text-base tw-font-semibold tw-p-3.5 tw-m-2.5 tw-bg-red-600/75">ADD FUNDS</button>
            </div>
          </div>
          {/* Main Content */}
          <div className={`tw-flex tw-flex-row tw-w-full md:tw-mx-36 tw-m-4 tw-transition-all tw-duration-500 tw-ease-in-out ${sidebarVisible ? 'md:tw-ml-96' : ''}`}>
            {activeTab === "first" && (
              <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full">
                <div className="tw-m-3">
                  <StudentOverviewTab />
                </div>
              </div>
            )}
            {activeTab === "second" && (
              <div className="tw-flex tw-flex-col tw-w-full">
                <StudentCalendarTab />
              </div>
            )}
            {activeTab === "third" && <StudentBookingsTab />}
            {activeTab === "fourth" && <StudentVodTab />}
            {activeTab === "fifth" && <StudentGainsTab />}
            {activeTab === "sixth" && <StudentWalletTab />}
            {activeTab === "seventh" && (
              <div className="">
                <StudentCoursesTab />
              </div>)}
            {activeTab === "eight" && (
              <div style={{ marginTop: "-6rem" }}>
                <AlgoliaCoachesPage />
              </div>
            )}
            {activeTab === "registerCoach" && <CoachRegisterPage />}
            {activeTab === "notifications" && <NotificationsPage />}
            {activeTab === "messages" && <ChatPage />}
            {activeTab === "settings" && (
              <div className="tw-flex tw-flex-col tw-w-full">
                <SettingsTab />
              </div>
            )}
          </div>
        </div>
      </div>
    </BusyOverlay>
  );
}
