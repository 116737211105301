import React, { useEffect } from "react";
import "./App.scss";
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes, useLocation } from "react-router-dom";
import { HomePage } from "./pages/common/HomePage";
import { Footer } from "./components/Footer";
import { AdminDashboardPage } from "./pages/admin/AdminDashboardPage";
import LoginPage from "./pages/auth/LoginPage";
import { RegisterPage } from "./pages/auth/RegisterPage";
import { StudentDashboardPage } from "./pages/student/StudentDashboardPage";
import { CoachRegisterPage } from "./pages/auth/CoachRegisterPage";
import { Protected } from "./components/Protected";
import SweetAlert from "react-bootstrap-sweetalert";
import { useStoreState } from "./models";
import { CoachProfilePage } from "./pages/coach/CoachProfilePage";
import AdminAddGamePage from './pages/admin/AdminAddGamePage';
import { SystemPage } from "./pages/admin/SystemPage";
import { CoachDashboardPage } from "./pages/coach/CoachDashboardPage";
import { BookCoachPage } from "./pages/coach/BookCoachPage";
import { AlgoliaCoachesPage } from "./pages/common/AlgoliaCoachesPage";
import { AboutUsPage } from "./pages/common/AboutUsPage";
import { EmailConfirmed } from "./pages/common/EmailConfirmed";
import { MainNavbar } from "./components/MainNavbar";
import { PricingPage } from "./pages/common/PricingPage";
import { EditCoachPackagesPage } from "./pages/coach/EditCoachPackagesPage";
import { SubmitVodPage } from "./pages/student/SubmitVodPage";
import { CoachRespondToVodPage } from "./pages/coach/CoachRespondToVodPage";
import { CoachEditAvailabilityPage } from "./pages/coach/CoachEditAvailabilityPage";
import { ResetPasswordPage } from "./pages/auth/ResetPasswordPage";
import { ChatPage } from "./pages/common/ChatPage";
import { NotificationsPage } from "./pages/common/NotificationsPage";
import { CoachApprovalPage } from "./pages/coach/CoachApprovalPage";
import { EditCoachProfilePage } from "./pages/coach/EditCoachProfilePage";
import { EditStudentProfilePage } from "./pages/coach/EditStudentProfilePage";
import { TermsConditionPage } from "./pages/common/TermsConditionPage";
import { PrivacyPolicyPage } from "./pages/common/PrivacyPolicyPage";
import { ToastContainer } from "react-toastify";
import { RoleFilters } from "./definitions/role-filters";
import { EditCoachMiniProfilePage } from "./pages/coach/EditCoachMiniProfilePage";
import { useCurrentUser } from "./hooks/use-current-user";
import { updateDoc } from "firebase/firestore";
import { TimeUtils } from "./utils/time-utils";
import { EnvUtils } from "./utils/env-utils";
import AdminAddDiscountPage from "./pages/admin/AdminAddDiscountPage";
import TagManager from "react-gtm-module";
import { DiscountBanner } from "./components/DiscountBanner";
import { StudentBookingViewPage } from "./pages/student/StudentBookingViewPage";
import { CheckoutProcessPage } from "./pages/billing/CheckoutProcessPage";
import { CoursesTab } from "./pages/student/CourseTab";

export const routes = {
  root: "/",
  login: "/login",
  register: "/register",
  resetPassword: "/reset-password",
  coachRegister: "/coach-register",
  admin: "/admin",
  studentDashboard: "/student-dashboard",
  courseWithId: "/student-dashboard/:courseId",
  courses: "/courses",
  coachDashboard: "/coach-dashboard",
  editCoachPackages: "/edit-coach-packages",
  coaches: "/coaches",
  coachesWithFilter: "/coaches/:gameFilter",
  coachProfile: "/coach/:username",
  bookSession: "/book-coach/:username",
  coachApproval: "/coach-approval/:username",
  addGame: "/add-game",
  addDiscount: "/add-discount",
  editGame: "/edit-game/:gameId",
  editDiscount: "/edit-discount/:discountId",
  respondToVod: "/respond-to-vod/:vodId",
  system: "/system",
  lab: "/lab",
  submitVod: "/submit-vod/:username",
  studentBooking: "/student-booking/:bookingId",
  aboutUs: "/about-us",
  pricing: "/pricing",
  chat: "/chat",
  chatWith: "/chat/:userId",
  editCoachAvailability: "/edit-availability",
  notifications: "/notifications",
  editCoachProfile: "/edit-coach-profile",
  editCoachMiniProfile: "/edit-coach-mini-profile",
  editStudentProfile: "/edit-student-profile",
  termsCondition: "/terms-and-condition",
  privacyPolicy: "/privacy-policy",
  checkout: "/checkout",
  emailConfirmed: "/email-confirmed"
} as const;

const tagManagerArgs = {
  gtmId: 'GTM-MMHRX49'
}
TagManager.initialize(tagManagerArgs);

export const App: React.FC = () => {
  const { authUserLoading, user } = useCurrentUser();
  const uiStoreState = useStoreState(state => state.ui);
  const routeLocation = useLocation();

  useEffect(() => {
    if (user && !user.timezone) {
      updateDoc(user.snapshot.ref, {
        timezone: TimeUtils.guessTimezone().name,
      }).then();
    }
  }, [user])

  function buildAlerts() {
    const currentAlert = uiStoreState.getTopAlert;
    if (!currentAlert) return null;
    return <SweetAlert {...currentAlert}>{currentAlert.content}</SweetAlert>;
  }

  const shouldShowNavbar = routeLocation.pathname !== routes.coachDashboard &&
    routeLocation.pathname !== routes.studentDashboard &&
    routeLocation.pathname !== routes.admin;

  return (<>{authUserLoading || <div className={"App"}>
    {routeLocation.pathname !== routes.checkout &&
      <>
        {shouldShowNavbar && <MainNavbar />}
        <DiscountBanner />
      </>
    }
    <Routes>
      <Route path={routes.root} element={<HomePage />} />
      <Route path={routes.login} element={<LoginPage />} />
      <Route path={routes.register} element={<RegisterPage />} />
      <Route path={routes.resetPassword} element={<ResetPasswordPage />} />
      {/* TODO: coaches and coachesWithFilter should be refactored into subroutes for rendering reasons https://github.com/remix-run/react-router/issues/7285 */}
      <Route path={routes.coaches} element={<AlgoliaCoachesPage />} />
      <Route path={routes.coachesWithFilter} element={<AlgoliaCoachesPage />} />
      <Route path={routes.coachProfile} element={<CoachProfilePage />} />
      <Route path={routes.aboutUs} element={<AboutUsPage />} />
      <Route path={routes.emailConfirmed} element={<EmailConfirmed />} />
      <Route path={routes.pricing} element={<PricingPage />} />
      <Route path={routes.courses} element={<CoursesTab />} />
      <Route path={routes.termsCondition} element={<TermsConditionPage />} />
      <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
      <Route path={routes.admin}
        element={<Protected roleFilter={RoleFilters.adminOnly}><AdminDashboardPage /></Protected>} />
      <Route path={routes.chat}
        element={<Protected roleFilter={RoleFilters.anyone}><ChatPage /></Protected>} />
      <Route path={routes.notifications}
        element={<Protected roleFilter={RoleFilters.anyone}><NotificationsPage /></Protected>} />
      <Route path={routes.chatWith}
        element={<Protected roleFilter={RoleFilters.anyone}><ChatPage /></Protected>} />
      <Route path={routes.coachRegister}
        element={<Protected roleFilter={RoleFilters.studentOnly}><CoachRegisterPage /></Protected>} />
      <Route path={`${routes.studentDashboard}/*`}
        element={<Protected roleFilter={RoleFilters.studentOnly}><StudentDashboardPage /></Protected>} />
      <Route path={routes.coachDashboard}
        element={<Protected roleFilter={RoleFilters.coachOnly}><CoachDashboardPage /></Protected>} />
      <Route path={routes.editCoachPackages}
        element={<Protected roleFilter={RoleFilters.coachOnly}><EditCoachPackagesPage /></Protected>} />
      <Route path={routes.bookSession}
        element={<Protected roleFilter={RoleFilters.studentOnly}><BookCoachPage /></Protected>} />
      <Route path={routes.addGame}
        element={<Protected roleFilter={RoleFilters.adminOnly}><AdminAddGamePage /></Protected>} />
      <Route path={routes.addDiscount}
        element={<Protected roleFilter={RoleFilters.adminOnly}><AdminAddDiscountPage /></Protected>} />
      <Route path={routes.editGame}
        element={<Protected roleFilter={RoleFilters.adminOnly}><AdminAddGamePage /></Protected>} />
      <Route path={routes.editDiscount}
        element={<Protected roleFilter={RoleFilters.adminOnly}><AdminAddDiscountPage /></Protected>} />
      {EnvUtils.readBool('REACT_APP_MAINTENANCE_MODE') &&
        <Route path={routes.system}
          element={<Protected roleFilter={RoleFilters.anyone}><SystemPage /></Protected>} />}
      <Route path={routes.submitVod}
        element={<Protected roleFilter={RoleFilters.studentOnly}><SubmitVodPage /></Protected>} />
      <Route path={routes.respondToVod}
        element={<Protected roleFilter={RoleFilters.coachOnly}><CoachRespondToVodPage /></Protected>} />
      <Route path={routes.editCoachAvailability}
        element={<Protected
          roleFilter={RoleFilters.coachOnly}><CoachEditAvailabilityPage /></Protected>} />
      <Route path={routes.coachApproval}
        element={<Protected roleFilter={RoleFilters.adminOnly}><CoachApprovalPage /></Protected>} />
      <Route path={routes.editCoachProfile}
        element={<Protected roleFilter={RoleFilters.coachOnly}><EditCoachProfilePage /></Protected>} />
      <Route path={routes.editCoachMiniProfile}
        element={<Protected roleFilter={RoleFilters.coachOnly}><EditCoachMiniProfilePage /></Protected>} />
      <Route path={routes.editStudentProfile}
        element={<Protected roleFilter={RoleFilters.studentOnly}><EditStudentProfilePage /></Protected>} />
      <Route path={routes.studentBooking}
        element={<Protected roleFilter={RoleFilters.studentOnly}><StudentBookingViewPage /></Protected>} />
      <Route path={routes.checkout}
        element={<Protected roleFilter={RoleFilters.studentOnly}><CheckoutProcessPage /></Protected>} />
    </Routes>
    {routeLocation.pathname !== routes.checkout && routeLocation.pathname !== routes.coachDashboard && routeLocation.pathname !== routes.studentDashboard && routeLocation.pathname !== routes.admin &&
      <Footer />
    }
    {buildAlerts()}
    <ToastContainer position={"bottom-center"} theme={"dark"} />
  </div>}</>
  );
}
