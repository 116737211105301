import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, ProgressBar, Stack } from "react-bootstrap";
import { useCurrentUser } from "../../hooks/use-current-user";
import { EditStudentProfilePayload } from "../../schemas/game-schema";
import { useNavigate } from "react-router-dom";
import { FieldError } from "../../components/form/FieldError";
import { SubmitHandler, useForm } from "react-hook-form";
import { useStoreActions } from "../../models";
import './EditStudentProfilePage.scss';
import { useUploadProfilePic } from "../../hooks/use-upload-profile-pic";
import Select from "react-select";
import { DataConfig } from "../../config/data-config";
import { DataPointer } from "../../interfaces/data-pointer";
import { UserLanguage } from "../../interfaces/user-language";
import _ from "lodash";
import { toast } from "react-toastify";

export function EditStudentProfilePage() {
  const usersStoreActions = useStoreActions(state => state.users);
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const [isFormInit, setIsFormInit] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState<DataPointer[] | null>(null);
  const [selectedInputMethods, setSelectedInputMethods] = useState<DataPointer[] | null>(null);
  const [selectedLanguages, setSelectedLanguages] = useState<UserLanguage[] | null>(null);

  const {
    upload,
    isUploading,
    uploadingError,
    publicUrl,
    progressPercent,
  } = useUploadProfilePic();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<EditStudentProfilePayload>();

  useEffect(() => {
    if (!user || isFormInit) return;
    setValue('gamerTag', user.gamerTag ?? '');
    setValue('discord', user.discord ?? '');
    setValue('phoneNumber', user.phoneNumber ?? '');
    setSelectedLanguages(DataConfig.supportedLanguages.filter(language => user.languages?.includes(language.id)))
    setSelectedInputMethods(DataConfig.supportedInputMethods.filter(inputMethod => user.inputMethods?.includes(inputMethod.id)))
    setSelectedPlatforms(DataConfig.supportedPlatforms.filter(platform => user.platforms?.includes(platform.id)))
    setIsFormInit(true);
  }, [user])

  useEffect(() => {
    setValue('profileImage', publicUrl);
  }, [publicUrl])

  const onSubmit: SubmitHandler<EditStudentProfilePayload> = async (payload) => {
    console.log(payload)
    if (!user?.uid) return;
    if (_.isEmpty(selectedLanguages)) {
      toast('Please select at least one language');
      return;
    }
    if (_.isEmpty(selectedPlatforms)) {
      toast('Please select at least one platform');
      return;
    }
    if (_.isEmpty(selectedInputMethods)) {
      toast('Please select at least one input method');
      return;
    }
    if (isUploading) {
      toast('Please wait until the profile image is uploaded.');
      return;
    }
    const success = await usersStoreActions.editStudentProfile({
      ...payload,
      updateId: user.uid,
      languages: selectedLanguages?.map(language => language.id) ?? [],
      platforms: selectedPlatforms?.map(platform => platform.id) ?? [],
      inputMethods: selectedInputMethods?.map(inputMethod => inputMethod.id) ?? [],
    });
    if (!success) return;
    navigate(-1);
  };

  function onDiscard() {
    navigate(-1);
  }

  return (<Container className={'page edit-student-profile-page'}>
    <div className={"dashboard-box-outline"}>
      <div className="p-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Col>
            <h1 className={"primary-color text-center"}>Edit My Profile</h1>
            <p className={"text-center pb-5"}>This section will let you edit your profile.</p>
            <Col>
              <Form.Group className="mb-4">
                <Form.Label style={{ textAlign: "left", width: "100%" }}>Profile Image</Form.Label>
                {uploadingError && <strong>Error: {uploadingError.message}</strong>}
                {isUploading && <ProgressBar className={'my-5'} now={progressPercent ?? 0} />}
                <input
                  type="file"
                  onChange={async (e) => {
                    const file = e.target.files ? e.target.files[0] : undefined;
                    if (file) await upload(file);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label style={{ textAlign: "left", width: "100%" }}>
                  Select Languages
                </Form.Label>
                <Select
                  className={'react-select-dropdown'}
                  classNamePrefix={"react-select-dropdown-options"}
                  options={DataConfig.supportedLanguages}
                  value={selectedLanguages}
                  getOptionValue={option => option.id}
                  onChange={(items) => setSelectedLanguages([...items])}
                  isMulti={true}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label style={{ textAlign: "left", width: "100%" }}>
                  Select Platforms
                </Form.Label>
                <Select
                  className={'react-select-dropdown'}
                  classNamePrefix={"react-select-dropdown-options"}
                  options={DataConfig.supportedPlatforms}
                  value={selectedPlatforms}
                  getOptionValue={option => option.id}
                  onChange={(items) => setSelectedPlatforms([...items])}
                  isMulti={true}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label style={{ textAlign: "left", width: "100%" }}>
                  Select Input Methods
                </Form.Label>
                <Select
                  className={'react-select-dropdown'}
                  classNamePrefix={"react-select-dropdown-options"}
                  options={DataConfig.supportedInputMethods}
                  value={selectedInputMethods}
                  getOptionValue={option => option.id}
                  onChange={(items) => setSelectedInputMethods([...items])}
                  isMulti={true}
                />
              </Form.Group>
              <div className="discord-tag">
                <Form.Group className="mb-4">
                  <Form.Label style={{ textAlign: "left", width: "100%" }}>
                    Discord Username
                  </Form.Label>
                  <Form.Control
                    placeholder="Discord Username"
                    {...register('discord', {
                      required: "Field is required",
                    })}
                  />
                  <FieldError message={errors.discord?.message} />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label style={{ textAlign: "left", width: "100%" }}>
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    placeholder="Phone Number"
                    {...register('phoneNumber', {
                      required: "Field is required",
                    })}
                  />
                  <FieldError message={errors.phoneNumber?.message} />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label style={{ textAlign: "left", width: "100%" }}>
                    Game Tag
                  </Form.Label>
                  <Form.Control
                    placeholder="Gamer Tag / Display Name"
                    {...register('gamerTag', {
                      required: "Field is required",
                    })}
                  />
                  <FieldError message={errors.gamerTag?.message} />
                </Form.Group>
              </div>
            </Col>
            <Stack direction={"horizontal"} gap={2} className={'dashboard-main-btn-row mt-5'}>
              <Button variant="primary" type="submit" className={"dashboard-primary-btn w-100"}>
                Save
              </Button>
              <Button variant="outline-primary" className={"dashboard-primary-outline-btn w-100"}
                onClick={() => onDiscard()}>
                Cancel
              </Button>
            </Stack>
          </Col>
        </Form>
      </div>
    </div>
  </Container>

  );
}
function setSelectedLanguages(arg0: import("../../interfaces/user-language").UserLanguage[]) {
  throw new Error("Function not implemented.");
}

