import { Col, Container, Row } from "react-bootstrap";
import "./Footer.scss";
import { routes } from "../App";
import { useCurrentUser } from "../hooks/use-current-user";
import { useGames } from "../hooks/use-games";
import { useNavigate } from "react-router-dom";
import { Community } from "./Community";

export const Footer = () => {
  const { allGames } = useGames();
  const navigate = useNavigate();
  const { isAdmin, isStudent, isCoach } = useCurrentUser();

  return (
    <div className="pt-5 pb-md-5 pb-0 bg-main-color footer">
      <Container className={"pt-5"}>
        <Row className={"pb-3"}>
          <Col md={8}>
            <div className="footer-logo"><img src="/assets/Logo/LogoDark%20BG.png" width={250} alt="" />
            </div>
            <div className="footer-description tw-text-gray-400">
              FPS Lounge is a creator marketplace that allows any gamer to play alongside and learn from the biggest streamers, creators, and pro players. We are a community Built By Gamers, For Gamers.
            </div>
          </Col>
          <Col md={4}>
            <div className="footer-links-main tw-pt-5">
              <div>
                <div className="footer-title pb-3">Quick Links</div>
                <div className="footer-links py-1"><a className="tw-text-gray-400"
                  href={routes.coaches}>Coaches</a></div>
                <div className="footer-links py-1"><a className="tw-text-gray-400"
                  href={routes.pricing}>Pricing</a></div>
                {(isCoach || isStudent) &&
                  <div className="footer-links py-1"><a className="tw-text-gray-400" href={routes.chat}>Chat</a></div>}
                {(isCoach || isAdmin) ||
                  <div className="footer-links py-1"><a className="tw-text-gray-400" href={routes.coachRegister}>Become a
                    Coach</a></div>}
                <div className="footer-links py-1"><a className="tw-text-gray-400" href={routes.aboutUs}>About
                  Us</a></div>
                <div className="footer-links py-1"><a className="tw-text-gray-400" target={'_blank'}
                  href={"mailto:admin@fpslounge.com"}>Contact
                  Us</a></div>
              </div>
              <div>
                <div className="footer-title pb-3">Games</div>
                {allGames?.map(game => <div key={game.id} className="footer-links clickable py-1 tw-text-gray-400">
                  <div
                    onClick={() => navigate(routes.coachesWithFilter.replace(':gameFilter', game.name))}>{game.name}</div>
                </div>)}
              </div>
            </div>
          </Col>
        </Row>
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-y-3 md:tw-flex-row md:tw-flex-wrap md:tw-justify-between md:tw-mt-12">
          <div className="footer-links float-end pt-2"><a className="tw-text-gray-400" href={routes.privacyPolicy}>Privacy Policy</a></div>
          <div className=""> <Community /> </div>
          <div className="footer-links float-md-end px-md-5 pt-2"><a className="tw-text-gray-400" href={routes.termsCondition}>Terms & Conditions</a></div>
        </div>
      </Container>
    </div>
  );
}
