export type PaymentReviewPayPalProps = {
  name: string,
  email: string,
}

export const PaymentReviewPayPal = (props: PaymentReviewPayPalProps) => {
  return (
    <>
      <h5 className="tw-text-white tw-font-bold tw-text-lg tw-mb-6">Contact Information</h5>
      
      <div className="tw-flex tw-flex-col tw-mb-6">
        <h6 className="tw-text-lg tw-text-white tw-font-bold">{props.name}</h6>
        <p className="tw-text-md tw-text-gray-100">{props.email}</p> 
      </div>

      <button
        className="tw-w-full tw-text-white tw-font-bold tw-border-none tw-outline-none tw-px-4 tw-py-2.5 tw-rounded-md tw-uppercase"
      >
        Edit Information  
      </button>

      <button
        className="tw-w-full tw-bg-red-100 tw-text-white tw-font-bold tw-border-none tw-outline-none tw-px-4 tw-py-2.5 tw-rounded-md tw-uppercase"
      >
        Pay Now 
      </button>

    </>
  )
}


