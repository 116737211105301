import React from "react";
import {useNavigate} from "react-router-dom";
import {DiscountSchema} from "../../schemas/discount-schema";
import {Button, Table} from "react-bootstrap";
import {routes} from "../../App";
import {useDiscounts} from "../../hooks/use-discounts";
import {TimeUtils} from "../../utils/time-utils";
import {useStoreActions} from "../../models";
import {deleteDoc} from "firebase/firestore";
import {DiscountUtils} from "../../utils/discount-utils";

export function AdminPromotionsTab() {
    const navigate = useNavigate();
    const {allDiscounts, discountsLoading} = useDiscounts();
    const uiStoreActions = useStoreActions((actions) => actions.ui);

    async function onDeleteDiscount(discountData: DiscountSchema) {
        const should = await uiStoreActions.addConfirmationAlert(
            {message: `Are you sure you want to delete the discount '${discountData.name}'?`}
        )
        if (!should) return;
        await deleteDoc(DiscountUtils.getDiscountRefById(discountData.id));
    }

    function buildDiscountsTableRow(discountData: DiscountSchema) {
        return <tr className={"table-row-bg"} key={discountData.id}>
            <td className={"name-td"}>{discountData.name}</td>
            <td>{discountData.percentage}%</td>
            <td>{TimeUtils.timestampToLocaleString(discountData.startDate)}</td>
            <td>{TimeUtils.timestampToLocaleString(discountData.endDate)}</td>
            <td className={"buttons-td"}>
                <Button className={"dashboard-secondary-btn mx-1"}
                        onClick={() => navigate(routes.editDiscount.replace(':discountId', discountData.id))}>Edit</Button>
                <Button className={"dashboard-secondary-btn mx-1"}
                        onClick={() => onDeleteDiscount(discountData)}>Delete</Button>
            </td>
        </tr>;
    }

    return <>
        <div className="admin-discount-tab">
            <h3 className={"primary-color"}>Promotions</h3>
            <p>Create discounts & promotions that is limited to a time period</p>
            <div className="dashboard-box-outline">
                <div className="p-4">
                    <div className="py-3">
                        <Button className={"dashboard-secondary-btn"} variant={"primary"}
                                onClick={() => navigate(routes.addDiscount)}>Add New Discount</Button>
                    </div>
                    {discountsLoading || <Table className={"add-new-discounts-table"} striped={false}>
                        <thead>
                        <tr>
                            <td>Discount Name</td>
                            <td>Amount</td>
                            <td>Start</td>
                            <td>End</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {discountsLoading || allDiscounts?.map(buildDiscountsTableRow)}
                        </tbody>
                    </Table>}
                </div>
            </div>

        </div>
    </>;
}
