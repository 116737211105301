import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, query, where} from "firebase/firestore";
import {userConverter, UserSchema} from "../schemas/user-schema";

export function useAdmins(): { allAdmins: UserSchema[] | undefined; adminsError: FirestoreError | undefined; adminsLoading: boolean } {
    const adminCollectionRef = collection(db, 'users').withConverter(userConverter);
    const adminsQuery = query<UserSchema>(adminCollectionRef, where("role", "==", 'admin'));
    const [allAdmins, adminsLoading, adminsError] = useCollectionData<UserSchema>(adminsQuery);
    return {
        allAdmins,
        adminsLoading,
        adminsError,
    };
}
