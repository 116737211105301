import {thunk, Thunk} from "easy-peasy";
import {db, functions} from "../config/firebase-config";
import {httpsCallable} from "firebase/functions";
import {CallableResponse} from "../definitions/callable-response";
import {PaypalSessionUtils} from "../utils/paypal-session-utils";
import {doc, setDoc} from "firebase/firestore";
import {FirebaseUtils} from "../utils/firebase-utils";
import {TypedCallableResponse} from "../definitions/typed-callable-response";
import {CreateOrderActions, CreateOrderData} from "@paypal/paypal-js";
import {AppConfig} from "../config/app-config";
import _ from "lodash";
import {DiscountPayload} from "../schemas/discount-schema";
import {addDoc, collection, updateDoc} from "firebase/firestore";
import {CommonUtils} from "../utils/common-utils";

export interface RequestCreditFromFaucetPayload {
    uid: string;
    amount: number;
}

export interface RequestWithdrawalPayload {
    uid: string;
    amount: number;
}

export interface TipCoachPayload {
    coachId: string;
    amount: number;
    tipMessage: string;
}

export interface CreatePaypalOrder {
    uid: string;
    quantity: number;
    price: string;
    data: CreateOrderData;
    actions: CreateOrderActions;
}

export interface EconomyModel {
    requestCreditFromFaucet: Thunk<EconomyModel, RequestCreditFromFaucetPayload, any, {}, Promise<boolean>>;
    requestWithdrawal: Thunk<EconomyModel, RequestWithdrawalPayload, any, {}, Promise<TypedCallableResponse<string>>>;
    tipCoach: Thunk<EconomyModel, TipCoachPayload, any, {}, Promise<CallableResponse>>;
    createPaypalFundingOrder: Thunk<EconomyModel, CreatePaypalOrder, any, {}, Promise<TypedCallableResponse<string>>>;
    addDiscount: Thunk<EconomyModel, DiscountPayload, any, {}, Promise<boolean>>;
}

export const economy: EconomyModel = {
    /* thunks */
    addDiscount: thunk(async (actions, discount) => {
        try {
            if (discount.updateId) {
                await updateDoc(doc(collection(db, 'discounts'), discount.updateId), {
                    ...CommonUtils.sanitizeData(discount),
                    ...FirebaseUtils.getModifiedTimestamp()
                });
            } else {
                await addDoc(collection(db, 'discounts'), {
                    ...CommonUtils.sanitizeData(discount),
                    ...FirebaseUtils.getCreatedTimestamp()
                });
            }
            return true;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }),
    requestCreditFromFaucet: thunk(async (actions, bookingData) => {
        try {
            const createBookingRequestCallable = httpsCallable<RequestCreditFromFaucetPayload, CallableResponse>(functions, 'requestCreditFromFaucet');
            const response = await createBookingRequestCallable(bookingData);
            return response.data.success;
        } catch (e) {
            console.error("Error adding document: ", e);
            return false;
        }
    }),
    tipCoach: thunk(async (actions, tipData) => {
        try {
            const tipCoachCallable = httpsCallable<TipCoachPayload, CallableResponse>(functions, 'tipCoach');
            const response = await tipCoachCallable(tipData);
            return response.data;
        } catch (ex) {
            return {
                success: false,
                message: `Error requesting withdrawal: ${ex}`,
            };
        }
    }),
    requestWithdrawal: thunk(async (actions, bookingData) => {
        try {
            const createBookingRequestCallable = httpsCallable<RequestCreditFromFaucetPayload, TypedCallableResponse<string>>(functions, 'requestWithdrawal');
            const response = await createBookingRequestCallable(bookingData);
            return response.data;
        } catch (ex: any) {
            return {
                success: false,
                message: `Error requesting withdrawal: ${ex}`,
            };
        }
    }),
    createPaypalFundingOrder: thunk(async (actions, orderPayload) => {
        try {
            const newOrderRef = PaypalSessionUtils.getNewPaypalSessionRef(orderPayload.uid);
            await setDoc(newOrderRef, {
                ..._.omit(orderPayload, 'actions'),
                ...FirebaseUtils.getCreatedTimestamp(),
            });
            const orderId = await orderPayload.actions.order
                .create({
                    purchase_units: [
                        {
                            amount: {
                                currency_code: AppConfig.currency,
                                value: orderPayload.quantity.toString(),
                            },
                            custom_id: newOrderRef.path,
                        },
                    ],
                });
            if (!orderId) return {
                success: false,
                message: `Failed to create a paypal order!`,
            };
            return {
                success: true,
                message: 'Paypal transaction created successfully',
                data: orderId,
            }
        } catch (ex) {
            return {
                success: false,
                message: `Paypal transaction failed with error: ${ex}`,
            };
        }
    }),
};
