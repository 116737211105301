import {Card} from "react-bootstrap";
import React, {FC} from "react";
import {BookingSchema} from "../schemas/booking-schema";
import {BookingModeExt} from "../definitions/booking-mode";
import {BookingStatus} from "../definitions/booking-status";
import {TimeUtils} from "../utils/time-utils";
import {useNavigate} from "react-router-dom";
import {routes} from "../App";

export const StudentBookingMiniListItem: FC<{ bookingData: BookingSchema }> = function (props) {
    const navigate = useNavigate();

    function getStyleClass(): string {
        switch (props.bookingData.bookingStatus) {
            case BookingStatus.Pending:
                return "student-booking-list-item-pending";
            case BookingStatus.Accepted:
                return "student-booking-list-item-accept";
            case BookingStatus.Complete:
                return "student-booking-list-item-accept";
            case BookingStatus.Cancelled:
                return "student-booking-list-item-reject";
            case BookingStatus.Rejected:
                return "student-booking-list-item-reject";
        }
    }

    async function onItemClick() {
        navigate(routes.studentBooking.replace(':bookingId', props.bookingData.id))
    }

    return (<div className={`${getStyleClass()} clickable`} onClick={() => onItemClick()} key={props.bookingData.id}>
        <Card className={'mb-2'} style={{fontSize: 13}}>
            <Card.Body>
                <div className="p-2">
                    <div className="my-bookings-row" style={{fontWeight: "bold"}}>
                        {BookingModeExt.toDisplayLabel(props.bookingData.bookingMode)}
                    </div>
                    <div className="my-bookings-row">
                        Placed: {TimeUtils.timestampToLocaleString(props.bookingData.created)}
                    </div>
                    {props.bookingData.hours && <div className="my-bookings-row">
                        {TimeUtils.hoursToHumanizedString(props.bookingData.hours)}
                    </div>}

                    {props.bookingData.booking &&
                        <div className={"my-bookings-time mb-2"}>
                            <div className="my-bookings-row">
                                Start: {TimeUtils.dateToLocaleString(props.bookingData.booking.start)}
                            </div>
                            <div className="my-bookings-row">
                                End: {TimeUtils.dateToLocaleString(props.bookingData.booking.end)}
                            </div>
                            {props.bookingData.proceedComment &&
                                <div className="my-bookings-row">
                                    <div className="my-bookings-bold">Reason:</div>
                                    {props.bookingData.proceedComment}</div>}
                        </div>}
                </div>
            </Card.Body>
        </Card>
    </div>);
};
