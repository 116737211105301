export type PaymentReviewStripeProps = {
  name: string,
  email: string,
  address: string,
  state: string,
  postal: string,
  country: string
}

export const PaymentReviewStripe = (props: PaymentReviewStripeProps) => {
  return (
    <>
      <h5 className="tw-text-white tw-font-bold tw-text-lg tw-mb-6">Contact Information</h5>
      
      <div className="tw-flex tw-flex-col tw-mb-6">
        <h6 className="tw-text-lg tw-text-white tw-font-bold">{props.name}</h6>
        <p className="tw-text-md tw-text-gray-100">{props.email}</p> 
      </div>

      <div className="tw-flex tw-flex-col tw-mb-6">
        <h6 className="tw-text-lg tw-text-white tw-font-bold">Billing Info</h6>
        <p className="tw-text-md tw-text-gray-100 tw-mb-1">{props.name}</p> 
        <p className="tw-text-md tw-text-gray-100 tw-mb-1">{props.address}</p>
        <p className="tw-text-md tw-text-gray-100 tw-mb-1">{props.postal}, {props.state}, {props.country}</p>
      </div>
    </>
  )
}

