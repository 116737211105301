import { useCurrentUser } from "../../hooks/use-current-user";
import { PlayFill } from "react-bootstrap-icons";
import { useCourse } from "../../hooks/use-course";
import { ReactHlsPlayer } from "../../components/HlsPlayer";
import { useState, useRef, useCallback, ButtonHTMLAttributes, ReactNode, useEffect, } from "react";
import { StudentCoursesTab } from "./StudentCoursesTab";
import { CoursePage } from "../common/CoursePage";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase-config";
import { MdAccountCircle } from "react-icons/md";

type BaseProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode | undefined;
  index: number;
};
interface StudentCoursesVideoTabProps {
  courseId: string;
  setCurrentCourseId: (id: string | null) => void;
  currentLessonId: number | null;
}

function StudentBaseTab({ children, leadingIcon, index, ...props }: BaseProps & { leadingIcon?: ReactNode | undefined }) {
  return (
    <button {...props} className="tw-w-full tw-h-full tw-min-w-[150px] tw-min-h-8 tw-bg-gray-250 disabled:tw-bg-red-600/75 tw-rounded-lg tw-py-4 tw-px-4 tw-inline-flex tw-items-center tw-border tw-border-transparent tw-text-base tw-text-white tw-shadow-sm hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 tw-ring-red-600/75 focus:tw-ring-offset-2" >
      {leadingIcon && leadingIcon}
      <span className="tw-line-clamp-2 tw-text-ellipsis tw-text-left">
        <span className="tw-font-bold">{index + 1}</span>:&nbsp;
        {children}
      </span>
    </button>
  );
}

function StudentVideoTab({ children, ...props }: BaseProps & { index: number }) {
  const icon = <span className="tw-mr-2 tw-ml-[-0.25rem]"><PlayFill size={25} /></span>;
  return (
    <li className="tw-list-none">
      <StudentBaseTab {...props} leadingIcon={icon}>
        {children}
      </StudentBaseTab>
    </li>
  );
}

// function StudentSectionTab({ children, ...props }: BaseProps) {
//   return (
//     <li className="tw-list-none" key={`section-default`}>
//       <StudentBaseTab {...props}>{children}</StudentBaseTab>
//     </li>
//   );
// }
export const GradientFade = () => (
  <div
    style={{
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(180deg, rgba(19, 26, 33, 0.00) 51.53%, #131A21 100%)',
      pointerEvents: 'none',
    }}
  />
);

export function StudentCoursesVideoTab({ courseId, setCurrentCourseId, currentLessonId }: StudentCoursesVideoTabProps) {
  const { user, userLoading } = useCurrentUser();
  const { course, courseLoading } = useCourse(courseId);
  const [currentTab, setCurrentTab] = useState('CourseVideoPage');
  const [{ section, video }, setCurrentSelection] = useState({
    section: 0,
    video: 0,
  });
  const [currentVideo, setCurrentVideo] = useState<null | { thumbnail: string; name: string; src: string; description: string; }>(null);
  const playerRef = useRef<HTMLVideoElement>(null);
  const isLoading = userLoading || courseLoading;

  const updateUserDoc = async (data: any) => {
    if (!user) return;
    const userDoc = doc(collection(db, 'users'), user.uid);
    await updateDoc(userDoc, data);
  }

  const setCurrent = useCallback(
    async (key: "section" | "video", value: number) => {
      setCurrentSelection((v) => {
        if (key === "section") return { video: -1, section: value };
        setCurrentVideo(course?.sections[v.section].videos[value] ?? null);
        return { ...v, [key]: value };
      });
      if (key === "video") {
        const videoTimeUpdates = {
          [`courseProgress.${courseId}.${value}`]: 0
        };
        await updateUserDoc(videoTimeUpdates);
      }
    },
    [course?.sections]
  );

  const handleTimeUpdate = async (e: any) => {
    const currentTimeUpdate = {
      [`courseProgress.${courseId}.${video}`]: e.target.currentTime
    };
    await updateUserDoc(currentTimeUpdate);
  }

  useEffect(() => {
    if (course && course.sections.length > 0 && course.sections[0].videos.length > 0) {
      let initialVideoIndex = 0;
      if (currentLessonId !== null && currentLessonId >= 0 && currentLessonId < course.sections[0].videos.length) {
        initialVideoIndex = currentLessonId;
      }
      setCurrent("video", initialVideoIndex);
    }
  }, [course, setCurrent, currentLessonId]);

  if (currentTab === 'HOME') {
    return <StudentCoursesTab />
  }
  if (currentTab === 'CoursePage' && course) {
    return <CoursePage courseId={(course.id)} setCurrentCourseId={setCurrentCourseId} />
  }

  const hasCourse = courseId
    ? user?.courses?.includes(courseId) ?? false
    : false;
  if (!isLoading && (!hasCourse || !course)) {
    return (
      <div>
        <h2>Course not found or access denied</h2>
        <p>Please check the course ID or your access permissions.</p>
      </div>
    );
  }

  // TODO loading spinner
  if (!course) return null;

  // TODO implement Cloudflare video tokens
  // TODO add watched to UserModel

  return (
    <>
      <div className="tw-text-sm tw-opacity-50 tw-font-bold tw-mt-3">
        <span className="tw-cursor-pointer" onClick={() => setCurrentTab('HOME')}>HOME &nbsp;</span>
        &gt;
        <span className="tw-cursor-pointer" onClick={() => setCurrentTab('CoursePage')}>&nbsp; COURSES &nbsp;</span>
        &gt;
        <span>&nbsp;APEX MASTERY...</span>
      </div>
      <section className="tw-flex tw-flex-col md:tw-flex-row" >
        <div className="tw-w-full md:tw-w-4/5">
          {currentVideo ? (
            <div className="">
              <h3 className="lg:tw-text-2xl tw-text-xl tw-font-bold tw-filter-none tw-normal-case tw-mb-8 tw-pb-0 tw-text-red-600/80">
                {course.name}
              </h3>
              <ReactHlsPlayer
                onTimeUpdate={handleTimeUpdate}
                controls
                className="tw-rounded-xl tw-w-full"
                src={currentVideo.src}
                playerRef={playerRef}
                {...(currentVideo.thumbnail ? { poster: currentVideo.thumbnail } : undefined)}
              />
              <h4 className="tw-mt-8 tw-mb-4 tw-text-white">{currentVideo.name}</h4>
              <p className="tw-text-gray-400">{currentVideo.description}</p>
            </div>
          ) : null}
        </div>

        <div className="tw-w-full md:tw-w-1/5 md:tw-mt-28 md:tw-ml-8">
          <div>
            <h4 className="tw-font-bold">Your Instructor</h4>
            <div className="tw-flex tw-gap-x-4">
              {user?.profileImage ? (
                <img src={user.profileImage} alt="Profile" className="tw-h-9 tw-w-9 tw-rounded-full" />
              ) : (
                <MdAccountCircle size={40} />
              )}
              <h4 className="tw-font-semibold tw-text-gray-400">{course.coachPointer.name}</h4>
            </div>
            <p className="tw-text-gray-400">{course.description}</p>
          </div>
          <div>
            <h4 className="tw-text-lg tw-font-bold">Lessons</h4>
            <div >
              <div className="tw-relative tw-h-80 tw-overflow-y-auto tw-flex tw-flex-col tw-gap-4 tw-mb-4">
                <div className="tw-relative tw-flex tw-flex-col tw-gap-4 tw-mb-4">
                  {course.sections[section].videos.map((v, i) => (
                    <StudentVideoTab key={`video-${v.name}`} index={i} disabled={video === i} onClick={() => setCurrent("video", i)}>
                      {v.name}
                    </StudentVideoTab>
                  ))}
                  <GradientFade />
                </div>
                <GradientFade />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
