import {bookingConverter, BookingSchema} from "../schemas/booking-schema";
import {collection, doc, FirestoreError} from "firebase/firestore";
import {db} from "../config/firebase-config";
import {useDocumentData, useDocumentDataOnce} from "react-firebase-hooks/firestore";

export function useBooking(bookingId: string | undefined): { booking?: BookingSchema; bookingError?: FirestoreError | undefined; bookingLoading: boolean } {
    const bookingCollectionRef = collection(db, 'bookings').withConverter(bookingConverter);
    const [booking, bookingLoading, bookingError] = useDocumentData<BookingSchema>(bookingId ? doc(bookingCollectionRef, bookingId) : null);
    return {
        booking,
        bookingError,
        bookingLoading,
    };
}

export function useBookingOnce(bookingId: string | undefined): { booking?: BookingSchema; bookingError?: FirestoreError | undefined; bookingLoading: boolean } {
    const bookingCollectionRef = collection(db, 'bookings').withConverter(bookingConverter);
    const [booking, bookingLoading, bookingError] = useDocumentDataOnce<BookingSchema>(bookingId ? doc(bookingCollectionRef, bookingId) : null);
    return {
        booking,
        bookingError,
        bookingLoading,
    };
}
