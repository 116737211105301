import {EnvUtils} from "../utils/env-utils";
import * as Sentry from "@sentry/react";

// settings
export class SentryConfig {
    public static readonly dsn = EnvUtils.readString('REACT_APP_SENTRY_DSN');
}

Sentry.init({ dsn: SentryConfig.dsn });

export { Sentry };