import { useEffect, useMemo, useState } from "react";
import { LoginButtons } from "./LoginButtons";

export function HomePageHeroComponent() {
  type ImageUrlsType = {
    [key: string]: string;
    valorant: string;
    apex: string;
    fortnite: string;
    warzone: string;
  };
  const imageUrls = useMemo<ImageUrlsType>(() => ({
    valorant: require('../assets/images/Homepage/valorant.jpeg'),
    apex: require('../assets/images/Homepage/apex.jpeg'),
    fortnite: require('../assets/images/Homepage/fornite.jpeg'),
    warzone: require('../assets/images/Homepage/codmw.jpg'),
    tarkov: require('../assets/images/Homepage/tarkov.jpg')
  }), []);

  const [selectedImage, setSelectedImage] = useState(imageUrls.apex);

  useEffect(() => {
    const imageKeys = Object.keys(imageUrls);
    const interval = setInterval(() => {
      const currentKey = Object.keys(imageUrls).find(key => imageUrls[key] === selectedImage) || '';
      const currentIndex = imageKeys.indexOf(currentKey);
      const nextIndex = (currentIndex + 1) % imageKeys.length;
      setSelectedImage(imageUrls[imageKeys[nextIndex]]);
    }, 20000);
    return () => clearInterval(interval);
  }, [selectedImage, imageUrls]);

  return (
    <section>
      <div>
        <img src={selectedImage}
          alt="apex" className="tw-transition-opacity tw-duration-2000 tw-ease-in-out tw-absolute tw-top-0 tw-w-screen md:tw-w-auto lg:tw-w-[109%] xl:tw-w-screen tw-h-[30rem] md:tw-h-[43rem] lg:tw-h-[45rem] xl:tw-h-[50rem] 2xl:tw-h-[63rem] tw-object-cover tw-object-top tw-bg-cover" />
        <div className=" tw-relative tw-top-20 md:tw-top-28 2xl:tw-top-40 text-center md:tw-text-left md:tw-mx-48 md:tw-w-[27rem] md:tw-mt-0">
          <div className="tw-uppercase tw-text-3xl md:tw-text-3xl lg:tw-text-4xl xl:tw-text-4xl 2xl:tw-text-4xl tw-font-extrabold primary-color tw-leading-tight">Unlock Your Potential
            <span className="tw-uppercase tw-text-3xl md:tw-text-3xl lg:tw-text-4xl xl:tw-text-4xl 2xl:tw-text-4xl tw-font-extrabold text-white tw-leading-tight"> With Expert Coaching</span>
          </div>
          <LoginButtons />

        </div>
        <div className="tw-relative tw-top-28 md:tw-top-40 lg:tw-top-40 xl:tw-top-44 2xl:tw-top-96 tw-flex tw-justify-start md:tw-justify-center tw-gap-x-4 tw-overflow-x-auto md:tw-overflow-x-hidden">
          <div className="tw-flex tw-items-center tw-justify-center tw-w-40 tw-h-20 tw-rounded-xl tw-cursor-pointer"
            style={{ backgroundImage: `url(${require("../assets/images/Homepage/valorant.jpeg")})`, backgroundSize: 'cover' }}
            onClick={() => setSelectedImage(imageUrls.valorant)}>
            <div className="tw-w-36 md:tw-w-20 tw-h-12 tw-bg-cover tw-bg-center" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/fps-lounge.appspot.com/o/images%2Fvalorant.png?alt=media&token=71f9019c-7567-427e-af50-d606988427fd')` }}>
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-justify-center tw-w-40 tw-h-20 tw-rounded-xl tw-cursor-pointer"
            style={{ backgroundImage: `url(${require("../assets/images/Homepage/apex.jpeg")})`, backgroundSize: 'cover' }}
            onClick={() => setSelectedImage(imageUrls.apex)}>
            <div className="tw-w-36 md:tw-w-20 tw-h-12 tw-bg-cover tw-bg-center" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/fps-lounge.appspot.com/o/images%2Fapex-legends.png?alt=media&token=97c808d4-cec6-474d-8b3f-872612a1dc3c')` }}>
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-justify-center tw-w-40 tw-h-20 tw-rounded-xl tw-cursor-pointer"
            style={{ backgroundImage: `url(${require("../assets/images/Homepage/fornite.jpeg")})`, backgroundSize: 'cover' }}
            onClick={() => setSelectedImage(imageUrls.fortnite)}>
            <div className="tw-w-36 tw-h-12 tw-bg-cover tw-bg-center" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/fps-lounge.appspot.com/o/images%2Ffortnite.png?alt=media&token=85c2c380-e2e9-4f7d-9047-f461ed6d8f59')` }}>
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-justify-center tw-w-40 tw-h-20 tw-rounded-xl tw-cursor-pointer"
            style={{ backgroundImage: `url(${require("../assets/images/Homepage/codmw.jpg")})`, backgroundSize: 'cover' }}
            onClick={() => setSelectedImage(imageUrls.warzone)}>
            <div className="tw-w-36 tw-h-12 tw-bg-cover tw-bg-center" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/fps-lounge.appspot.com/o/images%2Fwarzone.png?alt=media&token=f42a2e7c-5483-45a4-885f-878cc84bf5ea')` }}>
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-justify-center tw-w-40 tw-h-20 tw-rounded-xl tw-cursor-pointer"
            style={{ backgroundImage: `url(${require("../assets/images/Homepage/tarkov.jpg")})`, backgroundSize: 'cover' }}
            onClick={() => setSelectedImage(imageUrls.tarkov)}>
            <div className="tw-w-36 tw-h-12 tw-bg-cover tw-bg-center" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/fps-lounge.appspot.com/o/images%2Ftarkov1.png?alt=media&token=70954335-866e-490f-a303-0f5aac5b6526'` }}>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
