import React, {useEffect} from "react";
import {useCurrentUser} from "../../hooks/use-current-user";
import {useGainsOfStudent} from "../../hooks/use-gains";
import {StudentGainListItem} from "../../components/StudentGainListItem";
import {SpinnerPadded} from "../../components/SpinnerPadded";
import {getDocs} from "firebase/firestore";

export function StudentGainsTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const {allGains, gainsLoading, gainsQuery} = useGainsOfStudent(user?.uid);
    useEffect(() => {
        if (gainsQuery) getDocs(gainsQuery).then();
    }, [gainsQuery]);

    return (<div>
        <h3 className={"primary-color"}>My Purchases</h3>
        <p>Review your gains history below.</p>
        {(gainsLoading || userLoading) && <SpinnerPadded/>}
        {userLoading || allGains?.map(gain =>
            <StudentGainListItem key={gain.id} hasActions={true} gainData={gain}/>)}
    </div>);
}
