import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, orderBy, Query, query} from "firebase/firestore";
import {discountConverter, DiscountSchema} from "../schemas/discount-schema";
import {useMemo} from "react";
import {Timestamp} from "firebase/firestore";

export function useDiscounts(): { allDiscounts: DiscountSchema[] | undefined; discountsError: FirestoreError | undefined; discountsLoading: boolean; discountsQuery: Query } {
    const discountCollectionRef = collection(db, 'discounts').withConverter(discountConverter);
    const discountsQuery = query<DiscountSchema>(discountCollectionRef, orderBy('created', "desc"));
    const [allDiscounts, discountsLoading, discountsError] = useCollectionData<DiscountSchema>(discountsQuery);
    return {
        allDiscounts,
        discountsLoading,
        discountsError,
        discountsQuery,
    };
}

export function useActiveDiscounts(): { allDiscounts: DiscountSchema[] | undefined; discountsError: FirestoreError | undefined; discountsLoading: boolean; discountsQuery: Query } {
    const {
        allDiscounts,
        discountsLoading,
        discountsError,
        discountsQuery
    } = useDiscounts();
    const activeDiscounts = useMemo(() => allDiscounts?.filter((discount) => {
        const now = Timestamp.now().toMillis();
        return discount.startDate.toMillis() <= now && discount.endDate.toMillis() >= now;
    }), [allDiscounts]);
    return {
        allDiscounts: activeDiscounts,
        discountsLoading,
        discountsError,
        discountsQuery,
    };
}
