import React, { useMemo } from "react";
import { Alert, Button, Col, Container, ProgressBar, Row, Stack } from "react-bootstrap";
import './StudentBookingViewPage.scss';
import { useNavigate, useParams } from "react-router-dom";
import { useStoreActions } from "../../models";
import { BusyOverlay } from "../../components/BusyOverlay";
import { useBooking } from "../../hooks/use-booking";
import { SpinnerPadded } from "../../components/SpinnerPadded";
import { BookingMode, BookingModeExt } from "../../definitions/booking-mode";
import { BookingStatus, BookingStatusExt } from "../../definitions/booking-status";
import { TimeUtils } from "../../utils/time-utils";
import { CommonUtils } from "../../utils/common-utils";
import { CoachCardDisplay } from "../../components/CoachCardDisplay";
import { useUser } from "../../hooks/use-user";
import { useGameOnce } from "../../hooks/use-game";
import { BookingSchema } from "../../schemas/booking-schema";
import { routes } from "../../App";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

export const StudentBookingViewPage = () => {
  let { bookingId } = useParams();
  const navigate = useNavigate();
  const bookingStoreAction = useStoreActions((actions) => actions.booking);
  const { booking, bookingLoading } = useBooking(bookingId);
  const { game, gameLoading } = useGameOnce(booking?.gameId);
  const { userData: coachData, userLoading, userError } = useUser(booking?.coachPointer.id);
  const isPending = useMemo(() => booking?.bookingStatus === BookingStatus.Pending, [booking?.bookingStatus]);

  function getStatusVariant(): string | undefined {
    switch (booking?.bookingStatus) {
      case BookingStatus.Pending:
        return "warning";
      case BookingStatus.Accepted:
        return "success";
      case BookingStatus.Complete:
        return "success";
      case BookingStatus.Cancelled:
        return "secondary";
      case BookingStatus.Rejected:
        return "danger";
    }
    return undefined;
  }

  function buildBookingStatusSection(booking: BookingSchema) {
    return <Alert className={'mt-2'} variant={getStatusVariant()}>
      <div className={'p-3'}>
        <div className={'status-label pb-2'}>{BookingStatusExt.toDisplayLabel(booking?.bookingStatus)}</div>
        {isPending && <ProgressBar variant={getStatusVariant()} now={100} striped={true} animated={true} />}
        {booking.proceedComment &&
          <div className="my-bookings-row py-1">
            Reason: {booking.proceedComment}</div>}
        {isPending && booking.bookingMode === BookingMode.Instant &&
          <div className="my-bookings-row py-1">
            <br />
            Your booking request has been sent to your coach! If your coach doesn't respond to your request, your session will automatically be cancelled 30 minutes after your scheduled time slot begins.
          </div>}
        {isPending && booking.bookingMode === BookingMode.Scheduled &&
          <div className="my-bookings-row py-1">
            <br />
            Your booking request has been sent to your coach! If your coach doesn't respond to your request, your session will automatically be cancelled 30 minutes after your scheduled time slot begins.
          </div>}
      </div>
    </Alert>;
  }

  function buildBookingDetailsSection(booking: BookingSchema) {
    return <div className={'my-4'}>
      <div className="student-name">Coach - <span
        className={'primary-color fw-semibold'}>{booking.coachPointer.name}</span></div>
      <div className="booking-mode">Booking Type - <span
        className={'primary-color fw-semibold'}>{BookingModeExt.toDisplayLabel(booking.bookingMode)}</span>
      </div>
      {booking.hours && <div className="booking-status">Booking Duration - <span
        className={'primary-color fw-semibold'}>{TimeUtils.hoursToHumanizedString(booking.hours)}</span>
      </div>}
      {booking.booking &&
        <div className={"my-bookings-time"}>
          <div>Start - <span
            className={'primary-color fw-semibold'}>{TimeUtils.dateToLocaleString(booking.booking.start)}</span>
          </div>
          <div>End - <span
            className={'primary-color fw-semibold'}>{TimeUtils.dateToLocaleString(booking.booking.end)}</span>
          </div>
        </div>}
    </div>;
  }

  function onChatWithCoach(): void {
    if (!coachData) return;
    navigate(routes.chatWith.replace(':userId', coachData.uid))
  }

  async function cancelBooking() {
    await bookingStoreAction.proceedBooking({
      bookingId: booking!.id,
      accept: false,
      cancelled: true,
      proceedComment: 'Cancelled by student',
    });
    toast('Booking cancelled successfully');
  }

  return (<BusyOverlay isBusy={bookingLoading || gameLoading || userLoading}>
    <Container className={'page student-booking-page py-5'}>
      <Helmet>
        <title>FPS Lounge | Student Booking</title>
      </Helmet>
      <Row>
        <Col lg={4}>
          {coachData && <CoachCardDisplay userData={coachData} gameData={game ?? null} />}
        </Col>
        <Col lg={8}>
          {booking && <Stack style={{ textAlign: "start" }} className={'px-3'}>
            <h2>{BookingModeExt.toDisplayLabel(booking.bookingMode)} Booking</h2>
            <div className="mt-4">
              <p>Once your coach accepts your booking request, you can join your coach in discord at your scheduled time slot for your session. You will not be charged if a coach rejects or does not respond to your request, or if you cancel your booking. </p>
              <p></p>
            </div>
            {bookingLoading && <SpinnerPadded />}

            {buildBookingStatusSection(booking)}

            {buildBookingDetailsSection(booking)}

            <Button className={'dashboard-primary-btn mb-3'} onClick={() => {
              CommonUtils.openInNewTab('https://discord.com/invite/t9tBBBYpmY');
            }}>Open
              Discord</Button>
            <Button className={'dashboard-primary-btn mb-3'} onClick={() => onChatWithCoach()}>Chat With
              Coach</Button>

            <Button
              disabled={booking.bookingStatus !== BookingStatus.Pending}
              className="dashboard-primary-btn mb-3"
              onClick={cancelBooking}
            >
              Cancel Booking
            </Button>
            <Button className={'dashboard-primary-outline-btn mb-3'}
              onClick={() => navigate(-1)}>Back</Button>
          </Stack>}
        </Col>
      </Row>
    </Container>
  </BusyOverlay>);
}
