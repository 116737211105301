import { Button } from "react-bootstrap";
import { BusyOverlay } from "../../components/BusyOverlay";
import useSound from "use-sound";
import { functions } from "../../config/firebase-config";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { UserSchema } from "../../schemas/user-schema";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

export function AdminLabTab() {
  const [id, setId] = useState('');
  const [play] = useSound(require('../../assets/sounds/notification.wav'),
    { volume: 0.5 }
  );
  // const [executeCallable] = useHttpsCallable<{ id: UserSchema['uid'] }>(functions, 'updateChatToken');
  const [executeCallable] = useHttpsCallable<{ id: UserSchema['uid'] }, { success: boolean; data: null; message: string }>(functions, 'updateChatToken');

  const updateChatToken = useCallback(async () => {
    if (!id) {
      toast.error('Must have a User ID'); // Display an error message
      return;
    }

    try {

      const response = await executeCallable({ id });
      const data = response?.data;

      if (data && data.success) {
        toast.success('Token updated successfully');
      } else {
        toast.error('Failed to update token');
      }

    } catch (error) {
      toast.error('Failed to update token'); // Display an error message
    }
  }, [executeCallable, id]);

  return <>
    <BusyOverlay isBusy={false}>
      <h3 className={"primary-color"}>Laboratory</h3>
      <p>This section is for devs to do experiments on new cool ideas!</p>
      <Form className="form-inline py-3">
        <h6>Reset Chat Token</h6>
        <section className="form-group">
          <FloatingLabel
            controlId="floatingInput"
            label="User ID:"
            className="mb-2"
          >
            <Form.Control type="text" placeholder="User ID:" value={id} onChange={e => setId(e.currentTarget.value)} />
          </FloatingLabel>
        </section>
        <Button className="dashboard-secondary-btn" variant="primary" onClick={updateChatToken}>
          Refresh Chat Secrets
        </Button>
      </Form>
      <hr className="bg-danger border-2 border-top border-danger"></hr>
      <Button className={"dashboard-secondary-btn"} variant={"primary"}
        onClick={() => play()}>Play Notification Sound</Button>

    </BusyOverlay>
  </>;
}

