import { FC, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LoginPayload } from "../../schemas/user-schema";
import './LoginPage.scss';
import { useForm } from "react-hook-form";
import { useStoreActions } from "../../models";
import { FieldError } from "../../components/form/FieldError";
import { BusyOverlay } from "../../components/BusyOverlay";
import { useBusy } from "../../hooks/use-busy";
import { routes } from "../../App";
import { SocialLoginBar } from "../../components/SocialLoginBar";
import { Helmet } from "react-helmet";
import { EnvUtils } from "../../utils/env-utils";
import { useRedirectLogin } from "../../hooks/use-redirect-login";

const LoginPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm<LoginPayload>();
  const { signUserEmailPassword } = useStoreActions((actions) => actions.users);
  const { isBusy, setIsBusy } = useBusy();
  const uiStoreActions = useStoreActions(actions => actions.ui);

  useRedirectLogin(isLoggedIn);

  const onLoginSubmit = async (loginPayload: LoginPayload) => {
    setIsBusy(true);
    let response = await signUserEmailPassword(loginPayload);
    console.log('Login result is:', response)
    setIsBusy(false);
    if (!response.success) {
      await uiStoreActions.addMessageAlert({
        title: 'Login Failed',
        subtitle: response.message,
      })
      return;
    }
    setIsLoggedIn(true);
  };

  function onCreateAccount() {
    navigate({
      pathname: routes.register,
      search: location.search,
    });
  }

  function getPageTitle() {
    return 'Login';
    //return searchParams.get('redirect') ? 'Login as a coach' : 'Login'
  }

  return (
    <div className={'page login-page'}>
      <Helmet>
        <title>FPS Lounge | Login</title>
        <script type="text/javascript">
          {`!function(m,a,i,t,r,e){if(m.RH)return;r=m.RH={},r.uuid=t,r.loaded=0,r.base_url=i,r.queue=[],m.rht=function(){r.queue.push(arguments)};e=a.getElementsByTagName('script')[0],c=a.createElement('script');c.async=!0,c.src=i+'/widget/'+t+'.js',e.parentNode.insertBefore(c,e),console.log('ReferralHero script loaded successfully');}(window,document,'https://app.referralhero.com', '${EnvUtils.readString("REACT_APP_REFERRALHERO_ID")}');`}
        </script>
      </Helmet>
      <BusyOverlay isBusy={isBusy}>
        <div className="py-md-5 login">
          <Container className="py-md-5 pt-5 pt-xs-0">
            <div className="login-outline">
              <div className="login-img">
                <img src="/assets/Login/login-img.png" alt="" />
              </div>
              <div className="login-content">
                <div className="login-heading primary-color pt-4">{getPageTitle()}</div>
                <div className={"create-account"}>Don’t you have a account?
                  <a className={'clickable ms-2'} onClick={() => onCreateAccount()}>Create one</a>
                </div>

                <SocialLoginBar setIsBusy={setIsBusy} />

                <div className="section-breaker-main">
                  <div className="section-breaker"></div>
                  <div>or</div>
                  <div className="section-breaker"></div>
                </div>

                <div className="login-fields-main pt-3">
                  <Form onSubmit={handleSubmit(onLoginSubmit)}>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Control type="email"
                        placeholder="Email"
                        {...register("email", {
                          required: 'Field is required'
                        })} />
                      <FieldError message={errors.email?.message} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control type="password"
                        placeholder="Password"
                        {...register("password", {
                          required: 'Field is required'
                        })} />
                      <FieldError message={errors.password?.message} />
                    </Form.Group>
                    <div className="login-btn-main-section pt-4 pb-5">
                      <div className="forgot-password"><a
                        className={'clickable'}
                        onClick={() => navigate(routes.resetPassword)}
                      >
                        Forgot your password?</a></div>
                      <div className={"login-btn-main"}>
                        <Button variant={"dark"} type={"submit"}
                          className="login-btn">Login</Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>

            </div>
          </Container>
        </div>
      </BusyOverlay>
    </div>
  );
};

export default LoginPage;
