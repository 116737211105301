import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, orderBy, Query, query, where} from "firebase/firestore";
import {earningConverter, EarningSchema} from "../schemas/earning-schema";

export function useEarningsOfCoach(coachId?: string | null): {
    allEarnings: EarningSchema[] | undefined;
    earningsError: FirestoreError | undefined;
    earningsLoading: boolean;
    earningsQuery?: Query<EarningSchema>;
} {
    const earningsCollectionRef = collection(db, 'earnings').withConverter(earningConverter);
    const earningsQuery = coachId ? query<EarningSchema>(earningsCollectionRef, where("coachPointer.id", "==", coachId), orderBy('created', "desc")) : undefined;
    const [allEarnings, earningsLoading, earningsError] = useCollectionData<EarningSchema>(earningsQuery);

    return {
        allEarnings,
        earningsLoading,
        earningsError,
        earningsQuery,
    };
}
