import React, {useEffect, useState} from "react";
import {useCurrentUser} from "../../hooks/use-current-user";
import {BookingCalendar} from "../../components/calendar/BookingCalendar";
import {CalendarEvent} from "../../definitions/calendar-event";
import {useBookingsOfCoach} from "../../hooks/use-bookings";
import {Button, Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {routes} from "../../App";
import {BookingStatus} from "../../definitions/booking-status";
import {UserSchemaExt} from "../../schemas/user-schema";
import {CoachBookingMiniListItem} from "../../components/CoachBookingMiniListItem";

export function CoachCalendarTab() {
    const {user, authUser, userLoading} = useCurrentUser();
    const navigate = useNavigate();
    const [myEvents, setEvents] = useState<CalendarEvent[]>([])
    const {allBookings} = useBookingsOfCoach(user?.uid, [BookingStatus.Accepted, BookingStatus.Complete]);
    useEffect(() => {
        const allEvents = allBookings?.filter(bookingData => !!bookingData.booking).map(bookingData => bookingData.booking!) ?? [];
        setEvents(allEvents)
    }, [allBookings])

    return <div>
        <h3 className={"primary-color"}>My Calendar</h3>
        <p>Below is all your past and upcoming sessions.</p>
        <Row>
            <Col lg={4}>
                {userLoading || allBookings?.map(booking =>
                    <CoachBookingMiniListItem key={booking.id} hasActions={true} bookingData={booking}/>)}
            </Col>
            <Col lg={8}>
                <div className="my-4">
                    <Button className={"dashboard-secondary-btn"}
                            onClick={() => navigate(routes.editCoachAvailability)}>Edit
                        Weekly Availability</Button>
                </div>
                {user?.timezone &&
                    <BookingCalendar timezone={UserSchemaExt.getTimezone(user)}
                                     events={myEvents} availabilityRanges={user?.availabilityRanges}/>}
            </Col>
        </Row>
    </div>;
}
