import { useEffect, useMemo, useState } from "react";
import { Col, Container, Nav, Row, Stack, Tab, Tabs } from "react-bootstrap";
import {
  Configure,
  Hits,
  HitsPerPage,
  InstantSearch,
  Pagination,
  SearchBox,
  useRefinementList,
  useToggleRefinement
} from "react-instantsearch-hooks-web";
import './AlgoliaCoachesPage.scss'
import { CoachHit } from "../../components/CoachHit";
import _ from "lodash";
import { Facet } from "../../definitions/facet";
import { FacetRefinementList } from "../../components/algolia/FacetRefinementList";
import { useParams } from "react-router-dom";
import { useGames } from "../../hooks/use-games";
import { AlgoliaUserSchema } from "../../schemas/user-schema";
import { GameUtils } from "../../utils/game-utils";
import { AlgoliaConfig, searchClient } from "../../config/algolia-config";
import { Helmet } from "react-helmet";
import { EnvUtils } from "../../utils/env-utils";

export const AlgoliaCoachesPage = () => {
  const [facets, setFacets] = useState<Facet[]>([]);
  const usersIndex = useMemo(() => searchClient.initIndex(AlgoliaConfig.usersIndexName), []);
  const { gameFilter } = useParams();
  useEffect(() => {
    initializeIndex().then();
  }, []);

  async function initializeIndex() {
    const settings = await usersIndex.getSettings();
    const facets = _(settings.attributesForFaceting)
      .filter(item => item.includes('searchable(facets.'))
      .map(item => _.trimEnd(_.trimStart(item, 'searchable('), ')'))
      .uniq()
      .map(item => {
        return {
          id: item,
          label: _.split(item, '.')[1],
        }
      })
      .filter(item => item.label !== 'Game')
      .value();
    setFacets(facets);
  }


  return (
    <Container className={'page coaches-page mb-5'}>
      <Helmet>
        <title>FPS Lounge | {gameFilter ? `${gameFilter} Coaches` : 'Coaches'}</title>
        <script type="text/javascript">
          {`!function(m,a,i,t,r,e){if(m.RH)return;r=m.RH={},r.uuid=t,r.loaded=0,r.base_url=i,r.queue=[],m.rht=function(){r.queue.push(arguments)};e=a.getElementsByTagName('script')[0],c=a.createElement('script');c.async=!0,c.src=i+'/widget/'+t+'.js',e.parentNode.insertBefore(c,e),console.log('ReferralHero script loaded successfully');}(window,document,'https://app.referralhero.com', '${EnvUtils.readString("REACT_APP_REFERRALHERO_ID")}');`}
        </script>
      </Helmet>
      <h1 className={"primary-color text-center mt-5"}>Our Coaches</h1>
      <p className={"text-center pb-3"}>From the best-of-the-best, select your sherpa.</p>
      <InstantSearch searchClient={searchClient} indexName={AlgoliaConfig.usersIndexName}>
        <AlgoliaCoachesInner facets={facets} />
      </InstantSearch>
    </Container>
  );
}

function AlgoliaCoachesInner(props: { facets: Facet[] }) {
  const { allGames, gamesLoading } = useGames();
  const [gameFilter, setGameFilter] = useState<string | undefined>(useParams().gameFilter || allGames?.[0].name);
  const { items } = useRefinementList({ attribute: "facets.Game" });
  const selectedGame = useMemo(() => GameUtils.getGameDataByName(allGames, gameFilter) ?? allGames?.[0] ?? null, [gameFilter, allGames]);
  const gameRefinementToggle = useToggleRefinement({
    attribute: "facets.Game",
    on: gameFilter,
  });

  useEffect(() => {
    if (gamesLoading === false && allGames) {
      setGameFilter(allGames[0].name)
    }
  }, [gamesLoading]);

  useEffect(() => {
    if (_.isEmpty(props.facets)) return;
    if (items.filter(item => item.isRefined).map(item => item.value).includes('gameFilter')) return;
    if (gameFilter) {
      gameRefinementToggle.refine();
    }
  }, [props.facets, gameFilter, gameRefinementToggle])

  function onGameTabSelect(gameId: string | null) {
    if (!gameId) {
      setGameFilter(allGames?.[0].name);
      return;
    }
    const foundName = GameUtils.getGameDataById(allGames, gameId)?.name;
    setGameFilter(foundName);
  }

  return <div>
    <Configure hitsPerPage={40} filters="role:coach AND isHidden:false" />
    <Row className={"mt-5 pb-5"}>
      <Col className={"pt-4 pt-lg-0"}>
        {allGames && <Nav variant="tabs" className={'mb-3'}>
          <Tabs activeKey={selectedGame?.id} onSelect={onGameTabSelect}>
            {allGames.map(game =>
              <Tab key={game.id} eventKey={game.id} title={game.name}>
                <Stack direction={"horizontal"} className={'my-3'}>
                  <SearchBox />
                  {props.facets.filter(facet => facet.id.startsWith(`facets.${selectedGame?.name}`)).map(facet =>
                    <FacetRefinementList key={facet.id} facet={facet} />)}
                </Stack>
                <hr />
                <div className={"result-pagination-bar pb-2"}>
                  <Pagination /> <HitsPerPage items={[
                    { default: false, value: 10, label: "10" },
                    { default: false, value: 30, label: "30" },
                    { default: false, value: 50, label: "50" },
                    { default: true, value: 100, label: "100" },
                  ]}></HitsPerPage>
                </div>
                <Hits hitComponent={(hit) => {
                  const user = (hit.hit as any) as AlgoliaUserSchema;
                  const gameData = GameUtils.getGameDataById(allGames, user.coachingGame);
                  return <CoachHit hit={hit.hit} sendEvent={hit.sendEvent} gameData={gameData} />;
                }} />
              </Tab>
            )}
          </Tabs>
        </Nav>}
      </Col>
    </Row>
  </div>;
}
