import {EnvUtils} from "../utils/env-utils";

// settings
export class AppConfig {
    public static readonly isEmulatorMode = EnvUtils.readBool('REACT_APP_IS_EMULATOR_MODE');
    public static readonly isProductionEnv = !AppConfig.isEmulatorMode && EnvUtils.readString('REACT_APP_FIREBASE_ENV') === 'PROD';
    public static readonly showFaucetButton: boolean = EnvUtils.readBool('REACT_APP_SHOW_FAUCET_BUTTON');
    public static readonly onlineModeExpirySeconds = 60 * 60 * 4;
    public static readonly currency: string = 'USD';
}
