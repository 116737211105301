import { useState, useEffect, useCallback } from "react";
import { httpsCallable } from "firebase/functions";
import { CartItemPayload } from "../models/cart-model";
import { TypedCallableResponse } from "../definitions/typed-callable-response";
import { debounce } from "lodash";
import { functions } from "../config/firebase-config";

export const usePaymentIntent = ({ cart, queryParams, }: { cart: CartItemPayload[]; queryParams: URLSearchParams; }) => {

  const [payIntent, setPayIntent] = useState<TypedCallableResponse<string | null> | null>(null);
  const [loading, setLoading] = useState(true);

  const createPaymentIntent = useCallback(httpsCallable<Omit<CartItemPayload, "type"> & { productType: CartItemPayload["type"] }, TypedCallableResponse<string | null>>(functions, "createPaymentIntent"), []);

  const getPayIntent = useCallback(
    debounce(async () => {
      if (cart.length > 0 && !payIntent && !queryParams.get("payment_intent")) {
        const { data: payIntent } = await createPaymentIntent({
          productType: cart[0].type,
          productId: cart[0].productId,
          amount: cart[0].amount,
        });
        setPayIntent(payIntent);
        if (payIntent !== null) {
          setLoading(false);
        }
      }
    }, 500), // Debounce time in milliseconds
    [cart, payIntent, queryParams, createPaymentIntent]
  );

  useEffect(() => {
    getPayIntent();
  }, [getPayIntent]);

  return { payIntent, loading };
};
