import { StudentCoursesVideoTab } from '../student/StudentCoursesVideoTab';
import { ReactHlsPlayer } from '../../components/HlsPlayer';
import { useCourse } from '../../hooks/use-course';
import { HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useCurrentUser } from '../../hooks/use-current-user';
// import { PlayFill } from "react-bootstrap-icons";
import { MdAccountCircle } from "react-icons/md";
import { useCoachByPermalink } from '../../hooks/use-user';
import { CoachProfileCard } from '../../components/CoachProfileCard';
import { StudentCoursesTab } from '../student/StudentCoursesTab';
import _ from 'lodash';
import "./CoursePage.scss"

export interface CoursesVideoTabProps {
  courseId: string;
  setCurrentCourseId: (id: string | null) => void;
}

type BaseProps = HTMLAttributes<HTMLDivElement> & {
  children?: ReactNode | undefined;
  index: number;
};

export function CourseBaseTab({ children, leadingIcon, index, ...props }: BaseProps & { leadingIcon?: ReactNode | undefined }) {
  return (
    <div {...props} className="tw-w-full tw-h-full tw-min-w-[150px] tw-min-h-8 tw-gap-2 tw-py-4 tw-px-4 tw-inline-flex tw-items-center tw-border tw-border-transparent tw-text-base tw-text-white tw-shadow-sm tw-rounded-lg hover:tw-bg-gray-700 focus:tw-outline-none focus:tw-ring-2 tw-ring-red-600/75 focus:tw-ring-offset-2" >
      <span style={{ border: '1px solid red' }} className="tw-font-bold tw-rounded-full tw-h-7 tw-w-28 md:tw-w-11 tw-mb-auto tw-text-center">{index + 1}</span>&nbsp;
      {leadingIcon && leadingIcon}
      <span className="tw-line-clamp-2 tw-text-ellipsis tw-text-left">
        {/* <span className="tw-font-bold">{index + 1}</span>:&nbsp; */}
        {children}
      </span>
    </div>
  );
}

export function CourseVideoTab({ children, ...props }: BaseProps & { index: number }) {
  // const icon = <span className="tw-mr-2 tw-ml-[-0.25rem]"><PlayFill size={25} /></span>;
  return (
    <li className="tw-list-none">
      <CourseBaseTab {...props}>
        {children}
      </CourseBaseTab>
    </li>
  );
}

export function CoursePage({ courseId, setCurrentCourseId }: CoursesVideoTabProps) {
  const { user, userLoading } = useCurrentUser();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { course, courseLoading } = useCourse(courseId);
  const { coachData } = useCoachByPermalink(course?.coachPointer.permaLink);
  const [currentTab, setCurrentTab] = useState('CoursePage');
  const [showModal, setShowModal] = useState(false);
  const isLoading = userLoading || courseLoading;
  const [currentLessonId, setCurrentLessonId] = useState<number | null>(null);
  const [currentVideo, setCurrentVideo] = useState<null | { thumbnail: string; name: string; src: string; description: string; }>(null);
  const [{ section, video }, setCurrentSelection] = useState({ section: 0, video: 0, });
  const progress = user?.courseProgress?.[courseId] ?? {};
  const totalProgress = Object.values(progress).reduce((sum, value) => sum + value, 0)
  let totalVideos = 0;
  if (course && course.sections) { totalVideos = course.sections.reduce((sum, section) => sum + section.videos.length, 0); }

  let percentage = 0;
  if (totalVideos > 0) {
    percentage = (totalProgress / (totalVideos * 100)) * 100;
  }
  let roundedPercentage = Math.round(percentage);

  const setCurrent = useCallback(
    (key: "section" | "video", value: number) => {
      setCurrentSelection((v) => {
        if (key === "section") return { video: -1, section: value };
        setCurrentVideo(course?.sections[v.section].videos[value] ?? null);
        return { ...v, [key]: value };
      });
    },
    [course?.sections]
  );
  useEffect(() => {
    if (course && course.sections.length > 0 && course.sections[0].videos.length > 0) {
      setCurrent("video", 0);
    }
  }, [course, setCurrent]);


  if (currentTab === 'Home') {
    return <StudentCoursesTab />
  }
  if (currentTab === 'StudentCoursesVideoTab') {
    return <StudentCoursesVideoTab courseId={courseId} setCurrentCourseId={setCurrentCourseId} currentLessonId={currentLessonId} />
  }

  const hasCourse = courseId
    ? user?.courses?.includes(courseId) ?? false
    : false;
  if (!isLoading && (!hasCourse || !course)) {
    return (
      <div>
        <h2>Course not found or access denied</h2>
        <p>Please check the course ID or your access permissions.</p>
      </div>
    );
  }

  if (!course) return null;

  return (
    <>
      {showModal && (
        <div className="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="tw-flex tw-items-end tw-justify-center tw-h-3/4 md:tw-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0">
            <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" aria-hidden="true"></div>

            <span className="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen" aria-hidden="true">&#8203;</span>

            <div className="tw-inline-block tw-align-bottom tw-bg-black tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-w-4/5">
              <div className="tw-bg-black tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4">
                <ReactHlsPlayer
                  autoPlay
                  muted
                  controls
                  poster={`https://customer-fa6dyqdnfg287ik3.cloudflarestream.com/b36c59d0ac9e1e3e987a47bd7cf8e84b/thumbnails/thumbnail.jpg?time=4s`}
                  src={`https://customer-fa6dyqdnfg287ik3.cloudflarestream.com/b36c59d0ac9e1e3e987a47bd7cf8e84b/manifest/video.m3u8`}
                  className="tw-w-full tw-h-full"
                />
              </div>
              <div className="tw-bg-black tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse">
                <button type="button" className="tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-red-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500 sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm" onClick={() => setShowModal(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* First */}
      <div className="tw-flex tw-flex-col">
        <div className="tw-relative md:tw-mx-[-9rem]">
          {/* <div className="tw-absolute tw-w-screen tw-h-80 md:tw-h-[45rem] tw-opacity-50 tw-bg-center tw-object-cover tw-bg-cover tw-top-[-0.875rem] tw-left-[-1rem] md:tw-left-0 tw-blur-sm" style={{ backgroundColor: "white", }} /> */}
          <ReactHlsPlayer autoPlay loop muted
            className="tw-absolute tw-w-screen tw-h-80 md:tw-h-[56rem] tw-opacity-50 tw-bg-center tw-object-cover tw-bg-cover tw-top-[-0.875rem] tw-left-[-1rem] md:tw-left-0 tw-blur-sm"
            showQualitySelector={false}
            src={course?.bgVideo || "https://customer-fa6dyqdnfg287ik3.cloudflarestream.com/f8590dd6ac043327f6ec169a48024bc8/manifest/video.m3u8"}
            playerRef={videoRef}
            {...(course?.bgThumbnail ? { poster: course?.bgThumbnail } : undefined)}
          />
          <div className="gradient-bg tw-absolute tw-w-screen tw-h-80 md:tw-h-[56rem] tw-top-[-0.875rem] tw-left-[-1rem]"></div>
        </div>
        <div className="tw-relative tw-flex tw-flex-row tw-gap-y-3">
          <div className="md:tw-w-4/6">
            <div className="tw-text-sm tw-opacity-50 tw-font-bold tw-mt-3">
              <span className="tw-cursor-pointer" onClick={() => setCurrentTab('Home')}>HOME &nbsp;</span>
              &gt;
              <span>&nbsp;COURSES &nbsp;</span>
              &gt;
              <span className="tw-cursor-pointer" onClick={() => setCurrentTab('StudentCoursesVideoTab')}>&nbsp;{`${_.truncate(course?.name ?? 'Unknown', { length: 18 }).toUpperCase()}`}</span>
            </div>
            <h3 className="lg:tw-text-2xl tw-text-xl tw-font-bold tw-my-8"> {course?.name} </h3>
            <p className="tw-hidden md:tw-block tw-mb-8">{course?.description}</p>

            <div className="tw-flex tw-gap-x-4 tw-my-8">
              {course.coachPointer.img ? (
                <img src={course.coachPointer.img} alt="Profile" className="tw-h-12 tw-w-12 tw-bg-cover tw-bg-center tw-rounded-full" />
              ) : (
                <MdAccountCircle size={40} />
              )}
              <h3 className="tw-font-bold tw-self-center"> {course.coachPointer.name} </h3>
            </div>

            <h5 className="tw-font-semibold tw-mb-4">Progress {`${roundedPercentage || 0}%`}</h5>
            <div className="tw-w-full tw-bg-gray-200 tw-rounded-full tw-h-2.5 dark:tw-bg-gray-700 tw-mb-20">
              <div className="tw-bg-red-600 tw-h-2.5 tw-rounded-full" style={{ width: `${roundedPercentage || 0}%` }}></div>
            </div>
            <button className="tw-no-underline tw-w-[22rem] tw-inline-flex tw-justify-center tw-self-center md:tw-self-auto tw-items-center tw-rounded-md tw-border tw-border-transparent tw-bg-red-100 tw-px-6 tw-py-3.5 tw-font-bold tw-uppercase tw-leading-4 tw-text-white tw-shadow-sm hover:tw-bg-red-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-red-100 focus:tw-ring-offset-2" onClick={() => setCurrentTab('StudentCoursesVideoTab')}>Continue Learning</button>
            <button className="tw-no-underline tw-w-[22rem] tw-inline-flex tw-justify-center tw-self-center md:tw-self-auto tw-items-center tw-rounded-md tw-border tw-border-transparent tw-bg-transparent tw-px-6 tw-py-3.5 tw-font-bold tw-uppercase tw-leading-4 tw-text-gray-100" onClick={() => setShowModal(true)}>Watch Trailer</button>
          </div>
          <div className=" tw-hidden md:tw-block md:tw-w-2/6 md:tw-ml-64 md:tw-mt-40">
            <div className="tw-bg-gray-700 tw-h-96 tw-w-64 tw-flex tw-flex-col tw-rounded-lg">
              <div className="tw-w-full tw-h-2/5 tw-bg-cover tw-bg-center tw-rounded-t-lg" style={{ backgroundImage: `url(${course.image2})` }}>
                <div className="tw-w-36 tw-h-24 tw-mt-5 tw-ml-12 tw-bg-cover tw-bg-center" style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/fps-lounge-staging.appspot.com/o/images%2Fapex-legends.png?alt=media&token=5206cd47-745e-4765-a254-1d05ebd6e07a')` }}>
                </div>
              </div>
              <div className="tw-mt-2 tw-ml-5">
                <div className="tw-text-sm tw-mt-4">LEVEL</div>
                <div className="tw-text-lg tw-font-bold tw-mb-4">{course?.difficulty}</div>
                <div className="tw-text-sm">COURSE LENGTH</div>
                <div className="tw-text-lg tw-font-bold tw-mb-4">{course?.Length}</div>
                <div className="tw-text-sm">LANGUAGE</div>
                <div className="tw-text-lg tw-font-bold">{course?.Lang}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-hidden md:tw-flex tw-flex-col tw-items-center tw-font-bold tw-mt-48 tw-cursor-pointer tw-z-10" onClick={() => { const secondSectionElement = document.getElementById("secondSection"); if (secondSectionElement) { secondSectionElement.scrollIntoView({ behavior: "smooth" }); } }} >
          <div className="">More Info</div>
          <div>\/</div>
        </div>
      </div>

      <div className="tw-flex tw-flex-wrap tw-mt-4 md:hidden">
        <div className="tw-w-1/3 tw-flex tw-flex-col">
          <div className="tw-text-sm tw-font-bold tw-text-gray-500">LEVEL</div>
        </div>
        <div className="tw-w-1/3 tw-flex tw-flex-col">
          <div className="tw-text-sm tw-font-bold tw-text-gray-500">COURSE LENGTH</div>
        </div>
        <div className="tw-w-1/3 tw-flex tw-flex-col">
          <div className="tw-text-sm tw-font-bold tw-text-gray-500">LANGUAGE</div>
        </div>
        <div className="tw-w-1/3 tw-flex tw-flex-col">
          <div className="tw-text-lg tw-font-bold">{course?.difficulty}</div>
        </div>
        <div className="tw-w-1/3 tw-flex tw-flex-col">
          <div className="tw-text-lg tw-font-bold">{course?.Length}</div>
        </div>
        <div className="tw-w-1/3 tw-flex tw-flex-col">
          <div className="tw-text-lg tw-font-bold">{course?.Lang}</div>
        </div>
      </div>
      {/* Second */}
      <div id="secondSection" className="tw-flex tw-flex-col tw-mt-16 md:tw-mt-64">
        <div className="md:tw-grid md:tw-grid-cols-5 md:tw-grid-flow-row md:tw-gap-4">
          <div className="tw-relative tw-flex tw-flex-col lg:tw-col-span-4 md:tw-col-span-3 tw-col-span-6 ">
            <h3 className="lg:tw-text-2xl tw-text-xl tw-font-bold tw-filter-none tw-normal-case tw-mb-8 tw-pb-0 "> Course Description</h3>
            <p className="">{course?.description}</p>
            <h3 className="lg:tw-text-2xl tw-text-xl tw-font-bold tw-filter-none tw-normal-case tw-mb-8 tw-pb-0"> Lessons</h3>
            <div className="tw-relative tw-flex tw-flex-col tw-gap-4 tw-mb-4">
              {course.sections[section].videos.map((v, i) => (
                <CourseVideoTab key={`video-${v.name}`} index={i} onClick={() => { if (video === i) return; setCurrent("video", i); setCurrentLessonId(i); setCurrentTab('StudentCoursesVideoTab'); }} >
                  <div className="tw-flex hover:tw-cursor-pointer">
                    <div className="tw-h-48 md:tw-h-48 md:tw-w-80 tw-flex-none tw-bg-contain tw-bg-no-repeat tw-rounded tw-bg-center md:tw-bg-left tw-text-center tw-overflow-hidden" style={{ backgroundImage: `url(${course.image})` }} />
                    <div className="tw-flex tw-flex-col">
                      <h5 className="tw-font-bold">{v.name}</h5>
                      <div className="tw-text-gray-200/60">{v.description}</div>
                    </div>
                  </div>
                </CourseVideoTab>
              ))}
            </div>
          </div>
          <div className="tw-flex tw-flex-col md:tw-gap-y-5">
            <h3 className="lg:tw-text-2xl tw-text-xl tw-font-bold tw-filter-none tw-normal-case tw-mb-28 tw-pb-0"> Your Instructor</h3>
            <CoachProfileCard coachData={coachData} alternateStyle />
          </div>
        </div>
      </div>
    </>
  )
}
