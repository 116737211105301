import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, query} from "firebase/firestore";
import {gameConverter, GameSchema} from "../schemas/game-schema";

export function useGames(): { allGames: GameSchema[] | undefined; gamesError: FirestoreError | undefined; gamesLoading: boolean } {
    const gameCollectionRef = collection(db, 'games').withConverter(gameConverter);
    // TODO eventually this will grow tobecome large, we should set an active bool on the game
    const gamesQuery = query<GameSchema>(gameCollectionRef);
    const [allGames, gamesLoading, gamesError] = useCollectionData<GameSchema>(gamesQuery);

    return {
        allGames,
        gamesLoading,
        gamesError,
    };
}
