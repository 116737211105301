import { useEffect } from 'react';
import { routes } from '../App';
import { useCurrentUser } from './use-current-user';
import { useNavigate } from 'react-router-dom';

export const useRedirectLogin = (isLoggedIn: boolean) => {
  const navigate = useNavigate();
  const { isAdmin, isStudent, isCoach } = useCurrentUser();

  useEffect(() => {
    if (!isLoggedIn) return;

    if (isAdmin) {
      navigate(routes.admin);
    } else if (isStudent) {
      navigate(routes.studentDashboard);
    } else if (isCoach) {
      navigate(routes.coachDashboard);
    }
  }, [isLoggedIn, isAdmin, isStudent, isCoach, navigate]);
};
