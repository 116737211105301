import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PaypalConfig } from "../../config/paypal-config";
import { PaymentElement, AddressElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { MutableRefObject, useEffect, useMemo, useState } from "react";
import { EnvUtils } from "../../utils/env-utils";
import { CheckoutProcessStep } from "../../pages/billing/CheckoutProcessPage";
import { BusyOverlay } from "../BusyOverlay";
import { toast } from "react-toastify";
import { useStoreActions, useStoreState } from "../../models";
import { useCoursesByUser } from "../../hooks/use-courses";
import currency from "currency.js";
import { EconomyConfig } from "../../config/economy-config";
import { useCurrentUser } from "../../hooks/use-current-user";
import _ from "lodash";
import { AppConfig } from "../../config/app-config";

const PayPalButtonWrapper = ({ handlePageStep }: { handlePageStep: (step: CheckoutProcessStep) => void; }) => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [busy, setBusy] = useState(false);
  const { createPaypalFundingOrder } = useStoreActions(actions => actions.economy);
  const { user } = useCurrentUser();
  const isLoading = isPending && busy;
  const cartStoreOptions = useStoreState(state => state.cart);
  const productIds = useMemo(() => {
    return cartStoreOptions.data.map(cartItem => cartItem.productId);
  }, [cartStoreOptions.data]);


  const { allStudentCourses: courses } = useCoursesByUser({ courses: productIds });

  const price = useMemo(() => {
    const totalPrice = courses?.reduce((total, course) => total.add(course.price), currency(0)) ?? currency(0);
    const houseFee = totalPrice.multiply(EconomyConfig.houseTxFeePercentNormalized);
    return totalPrice.add(houseFee).value;
  }, [courses]);

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: AppConfig.currency,
      },
    });
  }, [price, AppConfig.currency, dispatch]);

  if (isPending) return null;

  return (
    <BusyOverlay isBusy={isLoading}>
      <PayPalButtons
        style={{ "layout": "vertical" }}
        disabled={price <= 0}
        createOrder={async (data, actions) => {
          if (Number.isNaN(price) || !price || price <= 0) return '';
          setBusy(true);
          const response = await createPaypalFundingOrder({
            actions,
            data,
            uid: user!.uid,
            price: `course_${productIds[0]}`,
            quantity: _.round(price, 2),
          });
          return response.data ?? '';
        }}
        onCancel={() => {
          setBusy(false);
        }}
        onApprove={async (data, actions) => {
          const orderResponse = await actions.order?.capture();
          if (!orderResponse || orderResponse.status !== 'COMPLETED') {
            toast('Couldn\'t capture order details.\nPlease contact us on discord: https://discord.com/invite/t9tBBBYpmY.');
            return;
          }
          // TODO: fix the single courseId in this section
          // await executeCallable({ courseId: productIds[0], order: orderResponse });
          setBusy(false);
          handlePageStep(CheckoutProcessStep.ORDER_CONFIRMATION);
        }}
        fundingSource="paypal"
      />
    </BusyOverlay>
  )
}

export type StripeBillingInfo = {
  city: string,
  country: string,
  line1: string,
  line2: string | null,
  postal_code: string,
  state: string,
  name: string
}

type PaymentBillingInfoFormProps = {
  emailRef: MutableRefObject<HTMLInputElement | null>;
  handlePageStep(step: CheckoutProcessStep): void;
  updateBillingInfo(info: any): void;
}

export const PaymentBillingInfoForm = (props: PaymentBillingInfoFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const { user } = useCurrentUser();

  useEffect(() => {
    setIsLoading(!stripe);
  }, [stripe]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${EnvUtils.readString("REACT_APP_URL")}/checkout`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      props.handlePageStep(CheckoutProcessStep.PAYMENT_METHOD);
      toast(error.message);
    } else {
      toast('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <BusyOverlay isBusy={isLoading}>
      <form id="payment-billing-info" onSubmit={handleSubmit}>
        <h5 className="tw-text-white tw-font-bold tw-text-lg tw-mb-6">Contact Information</h5>
        <div>
          <label htmlFor="email" className="tw-block tw-text-sm tw-text-gray-100">
            Email Address
          </label>
          <div className="tw-mt-1">
            <input
              ref={props.emailRef}
              type="email"
              name="email"
              id="email"
              className="tw-block tw-w-full tw-rounded-md tw-border-gray-500 tw-bg-dp-01 focus:tw-border-gray-600 sm:tw-text-sm tw-p-2 tw-text-white"
              placeholder="you@example.com"
              defaultValue={user?.email || ''}
            />
          </div>
        </div>

        <h5 className="tw-text-white tw-font-bold tw-text-lg tw-my-6">Payment Method</h5>


        <PayPalScriptProvider
          options={{
            "client-id": PaypalConfig.clientId,
            components: "buttons",
            currency: "USD",
          }}>
          <PayPalButtonWrapper handlePageStep={props.handlePageStep} />
        </PayPalScriptProvider>


        <div className="tw-relative tw-mb-6">
          <div className="tw-absolute tw-inset-0 tw-flex tw-items-center" aria-hidden="true">
            <div className="tw-w-full tw-border-t tw-bg-gray-100 tw-border-gray-100"></div>
          </div>
          <div className="tw-relative tw-flex tw-justify-center">
            <span className="tw-bg-gray-300 tw-px-2 tw-text-sm tw-text-gray-100">Or pay with card</span>
          </div>
        </div>

        <PaymentElement />

        <h5 className="tw-text-white tw-font-bold tw-text-lg tw-my-6">Billing Address</h5>

        <AddressElement
          onChange={(event) => {
            const { address, name } = event.value;
            props.updateBillingInfo({ ...address, name });
          }}
          options={{ mode: "billing" }}
        />
      </form></BusyOverlay>
  )
}
