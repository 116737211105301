import { useMemo } from "react";
import { UserSchema, UserSchemaExt } from "../schemas/user-schema";
import { UserLanguage } from "../interfaces/user-language";

export const Flag = ({ language }: { language: UserLanguage }) => {
    return (
        <li className="tw-w-6 tw-h-6 tw-overflow-hidden tw-rounded-full tw-align-top tw-mx-1">
            <img
                className="tw-object-cover tw-w-full tw-h-full tw-align-baseline"
                src={`/assets/flags/${language.id}.svg`}
                alt=""
            />
            <span className="tw-sr-only">{language.label}</span>
        </li>
    );
};

export const ListFlags = ({ user, }: { user?: Pick<UserSchema, "languages"> | null }) => {
    const languages = useMemo(() => UserSchemaExt.languageInfo(user) ?? [], [user]);
    if (!languages.length) return null;

    return (
        <section id="languages">
            <span className="tw-sr-only">I speak</span>
            <ul className="tw-mb-0 tw-list-none tw-p-0 tw-flex">
                {languages.map((language) => (
                    <Flag key={language.id} language={language} />
                ))}
            </ul>
        </section>
    );
};
