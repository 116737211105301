import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, FirestoreError, orderBy, Query, query, where} from "firebase/firestore";
import {vodConverter, VodSchema} from "../schemas/vod-schema";

export function useVodsOfCoach(coachId?: string | null): {
    allVods: VodSchema[] | undefined;
    vodsError: FirestoreError | undefined;
    vodsLoading: boolean;
    vodsQuery?: Query<VodSchema>;
} {
    const vodsCollectionRef = collection(db, 'vods').withConverter(vodConverter);
    const vodsQuery = coachId ? query<VodSchema>(vodsCollectionRef, where("coachPointer.id", "==", coachId), orderBy('created', "desc")) : undefined;
    const [allVods, vodsLoading, vodsError] = useCollectionData<VodSchema>(vodsQuery);

    return {
        allVods,
        vodsLoading,
        vodsError,
        vodsQuery,
    };
}

export function useVodsOfStudent(studentId?: string | null): {
    allVods: VodSchema[] | undefined;
    vodsError: FirestoreError | undefined;
    vodsLoading: boolean;
    vodsQuery?: Query<VodSchema>;
} {
    const vodsCollectionRef = collection(db, 'vods').withConverter(vodConverter);
    const vodsQuery = studentId ? query<VodSchema>(vodsCollectionRef, where("studentPointer.id", "==", studentId), orderBy('created', "desc")) : undefined;
    const [allVods, vodsLoading, vodsError] = useCollectionData<VodSchema>(vodsQuery);

    return {
        allVods,
        vodsLoading,
        vodsError,
        vodsQuery,
    };
}
