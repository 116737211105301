import { useStoreActions } from "../models";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useUpdateEmail, useVerifyBeforeUpdateEmail } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase-config";

export function useChangeEmail(): {
  changeEmail: () => void,
  isBusyUpdateEmail: boolean,
} {
  const uiStoreActions = useStoreActions((actions) => actions.ui);
  const [updateEmail, updating, error] = useUpdateEmail(auth);
  const [verifyBeforeUpdateEmail] = useVerifyBeforeUpdateEmail(auth);

  useEffect(() => {
    if (error) {
      uiStoreActions.addMessageAlert({
        title: 'Update change failed!',
        subtitle: `Please retry later or contract an administrator. Error: ${error?.message}`,
      });
    }
  }, [error, uiStoreActions])

  async function changeEmail(): Promise<void> {
    const newEmail = await uiStoreActions.addSingleInputAlert({
      title: "Change Email",
      message: "Enter email for your account.",
      inputType: 'email',
      required: true,
    });
    if (!newEmail) return;
    const confirmEmail = await uiStoreActions.addSingleInputAlert({
      title: "Confirm Email",
      message: "Please re-enter your email for confirmation.",
      inputType: 'email',
      required: true,
    });
    if (!confirmEmail) return;
    if (newEmail !== confirmEmail) {
      await uiStoreActions.addMessageAlert({
        title: 'Email do not match',
        subtitle: 'Please recheck the email you entered and retry.',
      });
      return;
    }
    const verify = await verifyBeforeUpdateEmail(newEmail, null);
    if (verify) { toast('Please check your email to verify your updated email address'); }
    await updateEmail(newEmail);
    toast('Email updated successfully!');
  }

  return {
    changeEmail,
    isBusyUpdateEmail: updating,
  }
}
