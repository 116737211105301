import {db} from "../config/firebase-config";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {doc, FirestoreError} from "firebase/firestore";
import {systemConfigConverter, SystemConfigSchema} from "../schemas/system-config-schema";

export function useSystemConfig(): {
    systemConfig: SystemConfigSchema | null;
    systemConfigLoading: boolean;
    systemConfigError: FirestoreError | undefined;
} {
    const [systemConfig, systemConfigLoading, systemConfigError] = useDocumentData<SystemConfigSchema>(doc(db, `system/systemConfig`)
        .withConverter(systemConfigConverter));
    return {
        systemConfig: systemConfig ?? null,
        systemConfigLoading: systemConfigLoading,
        systemConfigError: systemConfigError,
    };
}
