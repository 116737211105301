import {db} from "../config/firebase-config";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, query, where} from "firebase/firestore";
import chunk from "lodash/chunk";
import {courseConverter, CourseSchema} from "../schemas/course-schema";
import { UserSchema } from "../schemas/user-schema";

export function useCoursesByCoach(coachId: string) {
    const courseCollectionRef = collection(db, 'courses').withConverter(courseConverter);
    const coursesQuery = query<CourseSchema>(courseCollectionRef, where("coachPointer.id", "==", coachId));
    const [allCourses, coursesLoading, coursesError] = useCollectionData<CourseSchema>(coursesQuery);
    return {
        allCourses,
        coursesLoading,
        coursesError,
    } as const;
}

export function useCoursesByUser(user: Pick<UserSchema, 'courses'> | null) {
    const courseCollectionRef = collection(db, 'courses').withConverter(courseConverter);
    // 10 comes from the limitations for in-array from firestore: https://firebase.google.com/docs/firestore/query-data/queries#in_and_array-contains-any
    if (user?.courses && (user.courses.length > 10)) {
        console.warn('Overly large queries may cause performance issues.');
    }
    const queries = chunk(user?.courses ?? [], 10).map(courseIds => where("id", "in", courseIds));
    const coursesQuery = query(courseCollectionRef, ...queries);
    const [allStudentCourses, coursesLoading, coursesError] = useCollectionData<CourseSchema>((user?.courses?.length ?? 0) > 0 ? coursesQuery : null);

    return {
        allStudentCourses,
        coursesLoading,
        coursesError,
    } as const;
}