import { Button, Nav, Tab, Table, Tabs } from "react-bootstrap";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import "./AdminCoachesTab.scss"
import { useCoaches } from "../../hooks/use-coaches";
import { UserSchema } from "../../schemas/user-schema";
import { routes } from "../../App";
import { useGames } from "../../hooks/use-games";
import { GameUtils } from "../../utils/game-utils";
import { CheckboxButton } from "../../components/CheckboxButton";
import { updateDoc } from "firebase/firestore";
import { useStoreActions } from "../../models";
import { useBusy } from "../../hooks/use-busy";
import { BusyOverlay } from "../../components/BusyOverlay";
import { SpinnerPadded } from "../../components/SpinnerPadded";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { CallableResponse } from "../../definitions/callable-response";
import { functions } from "../../config/firebase-config";
import { RemoveCoachParams } from "../../interfaces/remove-coach-params";

export function AdminCoachesTab() {
    const { allCoaches, coachesLoading } = useCoaches();
    const { allGames, gamesLoading } = useGames();
    const uiStoreActions = useStoreActions(actions => actions.ui);
    const { isBusy, setIsBusy } = useBusy();
    const [executeCallable, executing, executionError] = useHttpsCallable<RemoveCoachParams, CallableResponse>(functions, 'removeCoach');

    async function onFeaturedChange(coachesData: UserSchema, checked: boolean): Promise<void> {
        const should = await uiStoreActions.addConfirmationAlert(
            { message: `Are you sure you want mark coach as ${checked ? 'featured' : 'not featured'}?` }
        )
        if (!should) return;
        setIsBusy(true);
        await updateDoc(coachesData.snapshot.ref, 'isFeatured', checked);
        setIsBusy(false);
    }

    async function onIsHiddenChange(coachesData: UserSchema, checked: boolean): Promise<void> {
        const should = await uiStoreActions.addConfirmationAlert(
            { message: `Are you sure you want mark coach as ${checked ? 'hidden' : 'not hidden'}?` }
        )
        if (!should) return;
        setIsBusy(true);
        await updateDoc(coachesData.snapshot.ref, 'isHidden', checked);
        setIsBusy(false);
    }

    async function onRemoveCoach(coachData: UserSchema) {
        if (!coachData) return;
        const should = await uiStoreActions.addConfirmationAlert({
            message: `Are you sure you want to remove the coach ${coachData.displayName}?`,
        });
        if (!should) return;
        setIsBusy(true);
        const response = await executeCallable({
            coachId: coachData.uid,
        });
        await uiStoreActions.addMessageAlert({
            title: response?.data.success ? 'Coach removed!' : 'Failed to remove coach!',
            subtitle: response?.data.success ? 'Coach was removed successfully. The account downgraded to a student account.' : `Removal failed with error: ${response?.data.message}`,
        });
        setIsBusy(false);
    }

    function buildCoachesTableRow(coachesData: UserSchema) {
        return <tr className={"table-row-bg"} key={coachesData.uid}>
            <td className={"name-td"}>{coachesData.displayName}</td>
            <td><Link target={"_blank"}
                to={routes.coachProfile.replace(':username', coachesData.permalink!)}>{coachesData.permalink}</Link>
            </td>
            <td className={"name-td"}>{GameUtils.getGameDataById(allGames, coachesData.coachingGame)?.name} USD</td>
            <td className={"name-td"}>{coachesData.balance} USD</td>
            <td className={"name-td"}><CheckboxButton isChecked={!!coachesData.isFeatured}
                onChange={(checked) => onFeaturedChange(coachesData, checked)} />
            </td>
            <td className={"name-td"}><CheckboxButton isChecked={!!coachesData.isHidden}
                onChange={(checked) => onIsHiddenChange(coachesData, checked)} />
            </td>
            <td className={"name-td"}><Button onClick={() => onRemoveCoach(coachesData)}>Remove</Button>
            </td>
        </tr>;
    }

    const coachesSplitByGame = useMemo(() => {
        if (!allCoaches?.length) return [];
        if (!allGames?.length) return [[null, allCoaches]] as const;
        const filterObject = allGames?.reduce((memo, game) => {
            memo[game.name] = [];
            return memo;
        }, {} as Record<string, UserSchema[]>);
        const formatedCoachesObject = allCoaches.reduce((memo, coach) => {
            const game = GameUtils.getGameDataById(allGames, coach.coachingGame);
            if (game) memo[game.name].push(coach);
            return memo;
        }, filterObject);
        return Object.entries(formatedCoachesObject);
    }, [allCoaches, allGames]);

    return <>
        <BusyOverlay isBusy={isBusy}>
            <div className="admin-coach-tab">
                <h3 className={"primary-color"}>All Coaches</h3>
                <p>Manage all the coaches on the platform in a single place.</p>
                <div className={'mb-3'}>
                    <div className="dashboard-box-outline">
                        <div className="p-4">
                            {coachesLoading ? <SpinnerPadded /> : (
                                <Nav variant="tabs">
                                    <Tabs
                                        defaultActiveKey="profile"
                                        id="fill-tab-example"
                                        className="mb-3"
                                        fill
                                    >
                                        {coachesSplitByGame.map(([gameName, coachesList]) => (
                                            <Tab key={gameName} eventKey={gameName ?? 'Unknown Game'} title={gameName}>
                                                <Table className={"add-new-coaches-table"} striped={false}>
                                                    <thead>
                                                        <tr>
                                                            <td>Coach Name</td>
                                                            <td>Permalink</td>
                                                            <td>Game</td>
                                                            <td>Fund Balance</td>
                                                            <td>Featured</td>
                                                            <td>Hidden</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>{coachesList.map(buildCoachesTableRow)}</tbody>
                                                </Table>
                                            </Tab>
                                        ))}
                                    </Tabs>
                                </Nav>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </BusyOverlay>
    </>;
}
