import { useStoreActions } from "../models";
import { Helmet } from 'react-helmet';
import { EnvUtils } from "../utils/env-utils";
import { useState } from "react";
import { useRedirectLogin } from "../hooks/use-redirect-login";

type Props = {
  setIsBusy: (isBusy: boolean) => void;
};

export function SocialLoginBar(props: Props) {
  const { signUserWithGoogle, signUserWithTwitch } = useStoreActions((actions) => actions.users);
  const uiStoreActions = useStoreActions(state => state.ui);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useRedirectLogin(isLoggedIn);

  const onSignInWithGoogle = async () => {
    props.setIsBusy(true);
    try {
      let result = await signUserWithGoogle();
      console.log('Login with google result is:', result)
      if (result === "SUCCESS") {
        // navigate(searchParams.get('redirect') ?? routes.root);
        setIsLoggedIn(true);
      }
    } catch (ex: any) {
      console.error(ex)
      await uiStoreActions.addMessageAlert({
        title: 'Login Failed in Gmail',
        subtitle: ex.message,
      })
    }
    props.setIsBusy(false);
  };

  const onSignInWithTwitch = async () => {
    props.setIsBusy(true);
    try {
      let result = await signUserWithTwitch();
      console.log('Login with twitch result is:', result)
      if (result === "SUCCESS") {
        // navigate(searchParams.get('redirect') ?? routes.root);
        setIsLoggedIn(true);
      }
    } catch (ex: any) {
      console.error(ex)
      await uiStoreActions.addMessageAlert({
        title: 'Login Failed in Twitch',
        subtitle: ex.message,
      })
    }
    props.setIsBusy(false);
  };

  return (<div className="login-social-icons-main py-5">
    <Helmet>
      <script type="text/javascript">
        {`!function(m,a,i,t,r,e){if(m.RH)return;r=m.RH={},r.uuid=t,r.loaded=0,r.base_url=i,r.queue=[],m.rht=function(){r.queue.push(arguments)};e=a.getElementsByTagName('script')[0],c=a.createElement('script');c.async=!0,c.src=i+'/widget/'+t+'.js',e.parentNode.insertBefore(c,e),console.log('ReferralHero script loaded successfully');}(window,document,'https://app.referralhero.com', '${EnvUtils.readString("REACT_APP_REFERRALHERO_ID")}');`}
      </script>
    </Helmet>
    <div className="social-icon" onClick={onSignInWithGoogle}>
      <div className="social-img"><img src="/assets/Login/google.png" width={23}
        alt="" />
      </div>
    </div>
    <div className="social-icon" onClick={onSignInWithTwitch}>
      <div className="social-img"><img src="/assets/Login/twitch.png" width={23}
        alt="" />
      </div>
    </div>
    <div className="social-icon disabled">
      <div className="social-img"><img src="/assets/Login/discord.png" width={30}
        alt="" />
      </div>
    </div>
    <div className="social-icon disabled">
      <div className="social-img"><img src="/assets/Login/apple.png" width={21}
        alt="" />
      </div>
    </div>
  </div>
  );
};
