import axios from "axios";
import {ChatEngineConfig} from "../config/chat-engine-config";

export interface ChatEngineChat {
    id: number;
    title: string;
}

interface ChatEngineCreateChatParams extends ChatEngineConfigParams {
    usernames: string[],
    title: string,
    is_direct_chat: boolean,
}

interface ChatEngineConfigParams {
    senderUsername: string;
    senderSecret: string;
}

interface ChatEngineCreateMessageParams extends ChatEngineConfigParams {
    chatId: number;
    text: string;
    custom_json?: string
}

export class ChatEngineUtils {
    public static async getOrCreateChat(params: ChatEngineCreateChatParams): Promise<ChatEngineChat> {
        const response = await axios.put(
            'https://api.chatengine.io/chats/',
            params,
            {
                headers: {
                    'Project-ID': ChatEngineConfig.projectId,
                    'User-Name': params.senderUsername,
                    'User-Secret': params.senderSecret
                }
            }
        );
        return response.data;
    }

    public static async createMessage({ chatId, ...params} : ChatEngineCreateMessageParams) {
        const response = await axios.post(
            `https://api.chatengine.io/chats/${chatId}/messages/`,
            params,
            {
                headers: {
                    'Project-ID': ChatEngineConfig.projectId,
                    'User-Name': params.senderUsername,
                    'User-Secret': params.senderSecret
                }
            }
        )
    }
}
