import {db} from "../config/firebase-config";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {collection, doc} from "firebase/firestore";
import { settingsConverter, SettingsSchema } from "../schemas/settings-schema";

export function useSettings(uid: string | undefined) {
    const coachDocRef = uid ? doc(collection(db, 'settings'), uid).withConverter(settingsConverter) : undefined;
    const [settingsData, settingsLoading, settingsError] = useDocumentData<SettingsSchema>(coachDocRef);
    if (!settingsData && !settingsLoading && uid) {
        console.error(`${uid} doesn't have a setting associated with it.`);
    }
    return {
        settingsData,
        settingsLoading,
        settingsError,
    } as const;
}