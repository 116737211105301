import {Button, Stack} from "react-bootstrap";
import React from "react";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {functions} from "../../config/firebase-config";
import {TypedCallableResponse} from "../../definitions/typed-callable-response";
import {toast} from "react-toastify";
import {HttpsCallableResult} from "firebase/functions";

export type DataExportHandlerPayload = {
    exporterId: string;
    options?: any;
};
const ALL_COACHES_EXPORTER = "all-coaches-exporter-callable";
const ALL_STUDENTS_EXPORTER = "all-students-exporter-callable";
const ALL_GAINS_EXPORTER = "all-gains-exporter-callable";
const ALL_WITHDRAWALS_EXPORTER = "all-withdrawals-exporter-callable";
const ALL_BOOKINGS_EXPORTER = "all-bookings-exporter-callable";
const ALL_VODS_EXPORTER = "all-vods-exporter-callable";
const ALL_TRANSACTION_EXPORTER = 'all-transaction-exporter-callable';
const ALL_TIPS_EXPORTER = "all-tips-exporter-callable";

export function AdminExportTab() {
    const [allCoachesExporterCallable, allCoachesExporterExecuting] = useHttpsCallable<DataExportHandlerPayload,
        TypedCallableResponse<string>>(functions, 'requestDataExport');

    const [allStudentsExporterCallable, allStudentsExporterExecuting] = useHttpsCallable<DataExportHandlerPayload,
        TypedCallableResponse<string>>(functions, 'requestDataExport');

    const [allGainsExporterCallable, allGainsExporterExecuting] = useHttpsCallable<DataExportHandlerPayload,
        TypedCallableResponse<string>>(functions, 'requestDataExport');

    const [allWithdrawalsExporterCallable, allWithdrawalsExporterExecuting] = useHttpsCallable<DataExportHandlerPayload,
        TypedCallableResponse<string>>(functions, 'requestDataExport');

    const [allBookingExporterCallable, allBookingExporterExecuting] = useHttpsCallable<DataExportHandlerPayload,
        TypedCallableResponse<string>>(functions, 'requestDataExport');

    const [allVodsExporterCallable, allVodsExporterExecuting] = useHttpsCallable<DataExportHandlerPayload,
        TypedCallableResponse<string>>(functions, 'requestDataExport');

    const [allTxExporterCallable, allTxExporterExecuting] = useHttpsCallable<DataExportHandlerPayload,
        TypedCallableResponse<string>>(functions, 'requestDataExport');

    const [allTipsExporterCallable, allTipsExporterExecuting] = useHttpsCallable<DataExportHandlerPayload, TypedCallableResponse<string>>(functions, 'requestDataExport');

    function handleReceiveDownloadLink(response?: HttpsCallableResult<TypedCallableResponse<string>>) {
        toast(response?.data.message ?? 'Unknown error occurred');
        if (response?.data.data) {
            window.open(response?.data.data, '_blank')
        }
    }

    async function onExportAllCoaches() {
        const response = await allCoachesExporterCallable({
            exporterId: ALL_COACHES_EXPORTER,
        });
        handleReceiveDownloadLink(response);
    }

    async function onExportAllStudents() {
        const response = await allStudentsExporterCallable({
            exporterId: ALL_STUDENTS_EXPORTER,
        });
        handleReceiveDownloadLink(response);
    }

    async function onExportAllGains() {
        const response = await allGainsExporterCallable({
            exporterId: ALL_GAINS_EXPORTER
        });
        handleReceiveDownloadLink(response);
    }

    async function onExportAllWithdrawals() {
        const response = await allWithdrawalsExporterCallable({
            exporterId: ALL_WITHDRAWALS_EXPORTER
        });
        handleReceiveDownloadLink(response);
    }

    async function onExportAllBookings() {
        const response = await allBookingExporterCallable({
            exporterId: ALL_BOOKINGS_EXPORTER
        });
        handleReceiveDownloadLink(response);
    }

    async function onExportAllVods() {
        const response = await allVodsExporterCallable({
            exporterId: ALL_VODS_EXPORTER
        });
        handleReceiveDownloadLink(response);
    }

    async function onExportAllTx() {
        const response = await allTxExporterCallable({
            exporterId: ALL_TRANSACTION_EXPORTER
        });
        handleReceiveDownloadLink(response);
    }


    async function onExportAllTips() {
        const response = await allTipsExporterCallable({
            exporterId: ALL_TIPS_EXPORTER,
        });
        handleReceiveDownloadLink(response);
    }

    return <>
        <BusyOverlay isBusy={
            allCoachesExporterExecuting ||
            allStudentsExporterExecuting ||
            allTipsExporterExecuting ||
            allTxExporterExecuting ||
            allVodsExporterExecuting ||
            allBookingExporterExecuting ||
            allWithdrawalsExporterExecuting ||
            allGainsExporterExecuting}>
            <h3 className={"primary-color"}>Data Export</h3>
            <p>This section is for devs to do experiments on new cool ideas!</p>
            <Stack className="py-3">
                <Button className={"dashboard-secondary-btn my-1"} variant={"primary"}
                        onClick={() => onExportAllCoaches()}>
                    Export All Coaches
                </Button>
                <Button className={"dashboard-secondary-btn my-1"} variant={"primary"}
                        onClick={() => onExportAllStudents()}>
                    Export All Students
                </Button>
                <Button className={"dashboard-secondary-btn my-1"} variant={"primary"}
                        onClick={() => onExportAllGains()}>
                    Export All Deposits
                </Button>
                <Button className={"dashboard-secondary-btn my-1"} variant={"primary"}
                        onClick={() => onExportAllWithdrawals()}>
                    Export All Withdrawals
                </Button>
                <Button className={"dashboard-secondary-btn my-1"} variant={"primary"}
                        onClick={() => onExportAllBookings()}>
                    Export All Orders(Hourly)
                </Button>
                <Button className={"dashboard-secondary-btn my-1"} variant={"primary"}
                        onClick={() => onExportAllVods()}>
                    Export All Orders(Vods)
                </Button>
                <Button className={"dashboard-secondary-btn"} variant={"primary"} onClick={() => onExportAllTips()}>Export
                    All
                    Tips
                </Button>
                <Button className={"dashboard-secondary-btn my-1"} variant={"primary"}
                        onClick={() => onExportAllTx()}>
                    Export All Transactions
                </Button>

            </Stack>
        </BusyOverlay>
    </>;
}

