import { useState, useEffect } from 'react';
import { Container, Nav, Navbar } from "react-bootstrap";
import "./MainNavbar.scss";
import { routes } from "../App";
import { useCurrentUser } from "../hooks/use-current-user";
import { useLocation } from "react-router-dom";
import { AppConfig } from "../config/app-config";

export function MainNavbar() {
  const { authUser, isAdmin, isStudent, isCoach } = useCurrentUser();

  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(location)
  }, [location])

  function getDashboardLink() {
    if (isAdmin) return routes.admin;
    if (isStudent) return routes.studentDashboard;
    if (isCoach) return routes.coachDashboard;
    return routes.login;
  }

  return (
    <div className={`app-navbar`}>
      <div className={`navbar-desktop ${isScrolled ? "scrolled" : ""}`}>
        <Container>
          <div className="navbar-desktop-main">
            <div>
              <Navbar.Brand href={routes.root}><img
                src={AppConfig.isProductionEnv ? "/assets/Logo/LogoDark BG.png" : "/assets/Logo/LogoDark BG Staging.png"}
                alt=""
                width={175} /></Navbar.Brand>
            </div>
            <div className={"navbar-desktop-items"}>

              {/*<Nav.Link href={routes.root}>Home</Nav.Link>*/}
              <Nav.Link className="tw-text-white tw-font-extrabold" href={routes.coaches}>GAMES</Nav.Link>
              <Nav.Link className="tw-text-white tw-font-extrabold" href={routes.courses}>COURSES</Nav.Link>
              {(isCoach || isAdmin) || <Nav.Link className="tw-text-white tw-font-extrabold" href={routes.coachRegister}>BECOME A COACH</Nav.Link>}
              <Nav.Link className="tw-text-white tw-font-extrabold" href={routes.aboutUs}>ABOUT US</Nav.Link>

            </div>
            <div className={"navbar-primary-btn navbar-desktop-login"}>
              {
                authUser && (isAdmin || isStudent || isCoach) && (
                  <div className="">
                    <Nav.Link className="tw-text-white tw-font-extrabold" href={isAdmin ? routes.admin : isStudent ? routes.studentDashboard : routes.coachDashboard}>
                      DASHBOARD
                    </Nav.Link>
                  </div>
                )
              }
              {!authUser && location.pathname !== routes.login &&
                <Nav.Link className="tw-text-white tw-font-extrabold" href={routes.login}>Sign In</Nav.Link>}
            </div>
          </div>
        </Container>
      </div>

      <div className={`navbar-mobile ${isScrolled ? "scrolled" : ""}`}>
        <Navbar collapseOnSelect variant={"dark"} expand="xxl" fixed={"top"} className={`navbar-color ${isScrolled ? "scrolled" : "tw-bg-gray-800 tw-opacity-75"}`} style={{ display: 'flex', flexDirection: 'column', paddingTop: 0 }}>
          <Container>
            <Navbar.Brand href={routes.root}><img
              src="/assets/Logo/LogoDark%20BG.png" alt=""
              width={100} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto navbar-mobile-items">
                <Nav.Link className={"mt-3 tw-font-extrabold"} href={routes.coaches}>GAMES</Nav.Link>
                <Nav.Link className={"mt-3 tw-font-extrabold"} href={routes.courses}>COURSES</Nav.Link>
                {isStudent &&
                  <Nav.Link className={"mt-3 tw-font-extrabold"} href={routes.coachRegister}>BECOME A COACH</Nav.Link>}
                <Nav.Link className={"mt-3 tw-font-extrabold"} href={routes.aboutUs}>ABOUT US</Nav.Link>
              </Nav>
              {
                authUser && (
                  <div className="navbar-primary-btn navbar-desktop-login tw-font-extrabold">
                    <Nav.Link href={getDashboardLink()}>
                      DASHBOARD
                    </Nav.Link>
                  </div>
                )
              }
              {!authUser && location.pathname !== routes.login &&
                <Nav.Link className={"sign-in-btn mt-3"} href={routes.login}>Sign In</Nav.Link>}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>

  );
}
