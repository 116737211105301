import {useEffect, useState} from "react";
import {Button, Col, Container, Form, Row, Stack} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {useStoreActions} from "../../models";
import "./AdminAddGamePage.scss";
import {FieldError} from "../../components/form/FieldError";
import {useNavigate, useParams} from "react-router-dom";
import {GamePayload} from "../../schemas/game-schema";
import {PricingMode, PricingModeExt} from "../../definitions/pricing-mode";
import {FieldArray} from "../../components/form/GameFilterForm";
import {useGameOnce} from "../../hooks/use-game";
import _ from "lodash";
import {RankRateList} from "../../components/form/RankRateList";
import {useBusy} from "../../hooks/use-busy";
import {BusyOverlay} from "../../components/BusyOverlay";

export default function AdminAddGamePage() {
    const {gameId} = useParams();
    const {game, gameLoading, gameError} = useGameOnce(gameId);
    const navigate = useNavigate();
    const gamesStoreAction = useStoreActions((actions) => actions.games);
    const [isFormInit, setIsFormInit] = useState(false);
    const {isBusy, setIsBusy} = useBusy();
    const uiStoreActions = useStoreActions(state => state.ui);

    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        formState,
        setValue
    } = useForm<GamePayload>();

    const {filters, pricingPack} = watch();

    useEffect(() => {
        if (!game || isFormInit) return;
        setValue('type', game.type);
        setValue('name', game.name);
        setValue('chargingMode', game.chargingMode ?? false);
        setValue('description', game.description);
        setValue('filters', game.filters);
        setValue('pricingPack', game.pricingPack);
        setValue('imageUrl', game.imageUrl ?? '');
        setValue('bannerUrl', game.bannerUrl ?? '');
        setIsFormInit(true);
    }, [game])
    const chargingMode = watch('chargingMode');

    const onSubmit: SubmitHandler<GamePayload> = async (payload) => {
        if (!payload.chargingMode || payload.chargingMode.length === 0) {
            await uiStoreActions.addMessageAlert({
                title: 'Cannot create new game',
                subtitle: 'You must select at least one charging mode to continue'
            });
            return;
        }
        setIsBusy(true);
        const success = await gamesStoreAction.addGame({
            ...payload,
            updateId: gameId ?? null
        });
        setIsBusy(false);
        if (!success) return;
        navigate(-1);
    };

    async function onDiscard(): Promise<void> {
        navigate(-1);
    }

    return (
        <div className={'bg-main-color'}>
            <Container className={"page app-home-page"}>
                <BusyOverlay isBusy={isBusy}>
                    <Row>
                        <Form onSubmit={handleSubmit(onSubmit)} className={"d-flex"}>
                            <Col className={"me-4"}>
                                <div className={'p-4'}>
                                    <h1 className="text-center primary-color pb-5">Create New Game</h1>
                                    <Row className={"dashboard-box-outline"}>
                                        <Col className={"p-5"}>
                                            <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Game Name
                                                </Form.Label>
                                                <Form.Control
                                                    placeholder="Enter name of the game."
                                                    {...register("name", {
                                                        required: "Field is required",
                                                        maxLength: 40,
                                                    })}
                                                />
                                                <FieldError message={formState.errors.name?.message}/>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Game Type
                                                </Form.Label>
                                                <Form.Control
                                                    placeholder="Enter game type"
                                                    {...register("type", {
                                                        required: "Field is required",
                                                        maxLength: 40,
                                                    })}
                                                />
                                                <FieldError message={formState.errors.type?.message}/>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Game Logo
                                                </Form.Label>
                                                {game?.logo && <img style={{ maxWidth: 20 }} src={game.logo} alt="current-logo" />}
                                                <Form.Control
                                                    disabled={!!game?.logo}
                                                    type={"file"}
                                                    accept="image/*"
                                                    {...register("logo", { maxLength: 40 })}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Game Description
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder="Enter a description for the game."
                                                    {...register("description", {
                                                        required: "Field is required",
                                                        maxLength: 500,
                                                    })}
                                                />
                                                <FieldError message={formState.errors.description?.message}/>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Game Image URL
                                                </Form.Label>
                                                <Form.Control
                                                    placeholder="Paste game image URL..."
                                                    {...register("imageUrl", {
                                                        required: "Field is required",
                                                        maxLength: 500,
                                                    })}
                                                />
                                                <FieldError message={formState.errors.imageUrl?.message}/>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Game Banner URL
                                                </Form.Label>
                                                <Form.Control
                                                    placeholder="Paste game banner URL..."
                                                    {...register("bannerUrl", {
                                                        required: "Field is required",
                                                        maxLength: 500,
                                                    })}
                                                />
                                                <FieldError message={formState.errors.bannerUrl?.message}/>
                                            </Form.Group>
                                            <hr/>
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>Game
                                                Filters</Form.Label>
                                            <FieldArray
                                                {...{control, register, formState}}
                                            />
                                        </Col>
                                        <Col className={"p-5"}>
                                            <Form.Label style={{textAlign: "left", width: "100%"}}>Game Charging
                                                Modes</Form.Label>
                                            <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Default VOD review hourly rate
                                                </Form.Label>
                                                <Form.Control
                                                    type={'number'}
                                                    placeholder="Enter default hourly rate"
                                                    {...register("pricingPack.vodRate", {
                                                        required: "Field is required",
                                                        valueAsNumber: true,
                                                        min: 0,
                                                        shouldUnregister: true
                                                    })}
                                                />
                                                <FieldError
                                                    message={formState.errors.pricingPack?.vodRate?.message}/>
                                            </Form.Group>
                                            <Form.Check
                                                label={PricingModeExt.toDisplayLabel(PricingMode.Hourly)}
                                                value={PricingMode.Hourly}
                                                type={'checkbox'}
                                                {...register("chargingMode", {})}
                                            />
                                            {chargingMode && chargingMode.includes('0') && <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Default hourly rate
                                                </Form.Label>
                                                <Form.Control
                                                    type={'number'}
                                                    placeholder="Enter default hourly rate"
                                                    {...register("pricingPack.hourlyRate", {
                                                        required: "Field is required",
                                                        valueAsNumber: true,
                                                        min: 0,
                                                        shouldUnregister: true
                                                    })}
                                                />
                                                <FieldError
                                                    message={formState.errors.pricingPack?.hourlyRate?.message}/>
                                            </Form.Group>}
                                            <Form.Check
                                                label={PricingModeExt.toDisplayLabel(PricingMode.PerGame)}
                                                type={'checkbox'}
                                                disabled={true}
                                            />
                                            {chargingMode && chargingMode.includes('2') && <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Default per game rate
                                                </Form.Label>
                                                <Form.Control
                                                    type={'number'}
                                                    placeholder="Enter default per game rate"
                                                    {...register("pricingPack.perGameRate", {
                                                        required: "Field is required",
                                                        valueAsNumber: true,
                                                        min: 0,
                                                        shouldUnregister: true
                                                    })}
                                                />
                                                <FieldError
                                                    message={formState.errors.pricingPack?.perGameRate?.message}/>
                                            </Form.Group>}
                                            <Form.Check
                                                label={PricingModeExt.toDisplayLabel(PricingMode.Ranked)}
                                                type={'checkbox'}
                                                disabled={true}
                                            />
                                            {chargingMode && chargingMode.includes('1') && <>
                                                <Form.Group className="mb-3">
                                                    <Form.Select {...register('pricingPack.rankFilterId', {
                                                        required: "Field is required",
                                                    })}>
                                                        <option value={''}>Select the Rank Filter</option>
                                                        {_(filters).compact()?.map(game => <option key={game.id}
                                                                                                   value={game.id}>{game.filterName}</option>).value()}
                                                    </Form.Select>
                                                    <FieldError
                                                        message={formState.errors.pricingPack?.rankFilterId?.message}/>
                                                </Form.Group>
                                                {pricingPack && <RankRateList formState={formState} control={control}
                                                                              register={register}
                                                                              pricingPack={pricingPack}
                                                                              filterPack={filters.find(filter => filter.id === pricingPack?.rankFilterId)}
                                                />}
                                            </>}
                                        </Col>

                                        <div className={"pb-5"}>
                                            <Stack direction={"horizontal"} gap={2}
                                                   className={'mt-5 dashboard-main-btn-row'}>
                                                <Button variant="primary" type="submit"
                                                        className={"dashboard-primary-btn w-100 mx-4"}>
                                                    Save
                                                </Button>
                                                <Button variant="outline-primary"
                                                        className={"w-100 dashboard-primary-outline-btn mx-4"}
                                                        onClick={() => onDiscard()}>
                                                    Cancel
                                                </Button>
                                            </Stack>
                                        </div>

                                    </Row>

                                </div>
                            </Col>
                        </Form>
                    </Row>
                </BusyOverlay>
            </Container>
        </div>
    );
}
