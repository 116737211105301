import { useLocation, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../hooks/use-current-user";
import { routes } from "../App";

export const LoginButtons = () => {
  const { authUser } = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="tw-flex tw-gap-x-4 tw-justify-center">
      {!authUser && location.pathname !== routes.login &&
        <div className="tw-flex tw-justify-center md:tw-py-8">
          <a className="tw-no-underline tw-cursor-pointer" onClick={() => navigate(routes.login)}>
            <div className="tw-flex tw-w-24 md:tw-w-32 tw-h-12 tw-items-center tw-justify-center tw-text-white tw-bg-transparent tw-border-2 tw-border-solid tw-border-red-600 tw-rounded-lg tw-font-bold tw-bg-[#00000025]">Log In</div>
          </a>
        </div>}
      {!authUser && location.pathname !== routes.login &&
        <div className="tw-flex tw-justify-center md:tw-py-8">
          <a className="tw-no-underline tw-cursor-pointer" onClick={() => navigate(routes.register)}>
            <div className="tw-flex tw-w-36 md:tw-w-52 tw-h-12 tw-items-center tw-justify-center tw-text-white tw-bg-red-600 tw-rounded-lg tw-font-bold ">Sign Up Now!</div>
          </a></div>}
    </div>
  );
}
