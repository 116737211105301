import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Stack } from "react-bootstrap";
import { useCurrentUser } from "../../hooks/use-current-user";
import { useGameOnce } from "../../hooks/use-game";
import { PricingPack } from "../../interfaces/pricing-pack";
import { EditPackagesPayload, GameSchemaExt } from "../../schemas/game-schema";
import { useNavigate } from "react-router-dom";
import { PricingMode, PricingModeExt } from "../../definitions/pricing-mode";
import { FieldError } from "../../components/form/FieldError";
import _ from "lodash";
import { SubmitHandler, useForm } from "react-hook-form";
import { routes } from "../../App";
import { useStoreActions } from "../../models";
import { EditRankRateList } from "../../components/form/EditRankRateList";
import { GameFilterPack } from "../../interfaces/game-filter-pack";
import { useBusy } from "../../hooks/use-busy";
import { BusyOverlay } from "../../components/BusyOverlay";
import { Helmet } from "react-helmet";
import { EnvUtils } from "../../utils/env-utils";

export function EditCoachPackagesPage() {
  const usersStoreActions = useStoreActions(state => state.users);
  const { user } = useCurrentUser();
  const { game } = useGameOnce(user?.coachingGame);
  const navigate = useNavigate();

  const initialChargingMode: false | string[] = user?.chargingMode ?? game?.chargingMode ?? false;
  const initialPricingPack: PricingPack = user?.pricingPack ?? game?.pricingPack ?? {};
  const filterPack: GameFilterPack | undefined = game?.filters.find(filter => initialPricingPack.rankFilterId === filter.id);
  const uiStoreActions = useStoreActions((actions) => actions.ui);
  const exemptUserIds = EnvUtils.readString("REACT_APP_EXEMPTUSERIDS");

  const [isFormInit, setIsFormInit] = useState(false);
  const { isBusy, setIsBusy } = useBusy();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState,
    setValue
  } = useForm<EditPackagesPayload>();

  const { pricingPack } = watch();

  useEffect(() => {
    if (!game || isFormInit) return;
    setValue('chargingMode', _.cloneDeep(initialChargingMode));
    setValue('pricingPack', _.cloneDeep(initialPricingPack));
    setIsFormInit(true);
  }, [game])
  const chargingMode = watch('chargingMode');

  const onSubmit: SubmitHandler<EditPackagesPayload> = async (payload) => {
    if (!payload.chargingMode || payload.chargingMode.length === 0) {
      await uiStoreActions.addMessageAlert({
        title: 'Cannot create new game',
        subtitle: 'You must select at least one charging mode to continue'
      });
      return;
    }
    if (GameSchemaExt.hasHourlyRate(payload.chargingMode, payload.pricingPack)) {
      if (user && !exemptUserIds.includes(user.uid) && (payload.pricingPack?.hourlyRate ?? 0) < 16) {
        await uiStoreActions.addMessageAlert({
          title: 'Cannot create new game',
          subtitle: 'Your hourly rate must be at least 16 USD',
        });
        return;
      }
    }
    console.log(payload)
    if (!user?.uid) return;
    setIsBusy(true);
    const success = await usersStoreActions.editPackages({
      pricingPack: payload.pricingPack,
      chargingMode: payload.chargingMode,
      updateId: user.uid
    });
    setIsBusy(false);
    if (!success) return;
    navigate(routes.coachDashboard);
  };

  function onDiscard() {
    navigate(-1);
  }

  return (<BusyOverlay isBusy={isBusy}>
    <Container className={'page admin-page'}>
      <Helmet>
        <title>FPS Lounge | Edit My Packages</title>
      </Helmet>
      <div className={"dashboard-box-outline"}>
        <div className="p-5">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Col>
              <h1 className={"primary-color text-center"}>Edit My Packages</h1>
              <p className={"text-center pb-5"}>This section will let you modify your charging packages
                for coaching.</p>
              <Col>
                <Form.Label style={{ textAlign: "left", width: "100%" }}>Game Charging
                  Modes</Form.Label>
                <Form.Check
                  label={PricingModeExt.toDisplayLabel(PricingMode.Hourly)}
                  value={PricingMode.Hourly}
                  type={'checkbox'}
                  {...register("chargingMode", {})}
                />
                {chargingMode && chargingMode.includes('0') && <Form.Group className="mb-3">
                  <Form.Label style={{ textAlign: "left", width: "100%" }}>
                    Default hourly rate
                  </Form.Label>
                  <Form.Control
                    type={'number'}
                    placeholder="Enter default hourly rate"
                    {...register("pricingPack.hourlyRate", {
                      required: "Field is required",
                      valueAsNumber: true,
                      min: 0,
                      shouldUnregister: true
                    })}
                  />
                  <FieldError message={formState.errors.pricingPack?.hourlyRate?.message} />
                </Form.Group>}
                <Form.Check
                  label={PricingModeExt.toDisplayLabel(PricingMode.PerGame)}
                  type={'checkbox'}
                  disabled={true}
                />
                {chargingMode && chargingMode.includes('2') && <Form.Group className="mb-3">
                  <Form.Label style={{ textAlign: "left", width: "100%" }}>
                    Default per game rate
                  </Form.Label>
                  <Form.Control
                    type={'number'}
                    placeholder="Enter default per game rate"
                    {...register("pricingPack.perGameRate", {
                      required: "Field is required",
                      valueAsNumber: true,
                      min: 0,
                      shouldUnregister: true
                    })}
                  />
                  <FieldError message={formState.errors.pricingPack?.perGameRate?.message} />
                </Form.Group>}
                <Form.Check
                  label={PricingModeExt.toDisplayLabel(PricingMode.Ranked)}
                  type={'checkbox'}
                  disabled={true}
                />
                {chargingMode && chargingMode.includes('1') && <>
                  <Form.Group className="mb-3">
                    <Form.Select {...register('pricingPack.rankFilterId', {
                      required: "Field is required",
                    })}>
                      <option value={''}>Select the Rank Filter</option>
                      {_(game?.filters).compact()?.map(filter => <option key={filter.id}
                        value={filter.id}>{filter.filterName}</option>).value()}
                    </Form.Select>
                    <FieldError
                      message={formState.errors.pricingPack?.rankFilterId?.message} />
                  </Form.Group>
                  {pricingPack && <EditRankRateList formState={formState} control={control}
                    register={register}
                    pricingPack={pricingPack}
                    filterPack={filterPack}
                  />}
                </>}
                <Form.Group className="my-3">
                  <Form.Label style={{ textAlign: "left", width: "100%" }}>
                    VOD review hourly rate
                  </Form.Label>
                  <Form.Control
                    type={'number'}
                    placeholder="Enter default hourly rate"
                    {...register("pricingPack.vodRate", {
                      required: "Field is required",
                      valueAsNumber: true,
                      min: 0,
                      shouldUnregister: true
                    })}
                  />
                  <FieldError
                    message={formState.errors.pricingPack?.vodRate?.message} />
                </Form.Group>
              </Col>
              <Stack direction={"horizontal"} gap={2} className={'dashboard-main-btn-row mt-5'}>
                <Button variant="primary" type="submit" className={"dashboard-primary-btn w-100"}>
                  Save
                </Button>
                <Button variant="outline-primary" className={"dashboard-primary-outline-btn w-100"}
                  onClick={() => onDiscard()}>
                  Cancel
                </Button>
              </Stack>
            </Col>
          </Form>
        </div>
      </div>
    </Container>
  </BusyOverlay>);
}
