import {getStripePayments} from "@stripe/firestore-stripe-payments";
import {loadStripe} from "@stripe/stripe-js";
import {firebaseApp} from "./firebase-config";
import {EnvUtils} from "../utils/env-utils";

export class StripeConfig {
    public static collections = {
        productsCollection: 'products',
        customersCollection: 'users',
    };
}

export const stripePromise = loadStripe(EnvUtils.readString("REACT_APP_STRIPE_PUBLISH_KEY")); 

export const stripePayments = getStripePayments(firebaseApp, {
    productsCollection: StripeConfig.collections.productsCollection,
    customersCollection: StripeConfig.collections.customersCollection,
});
