import { useState, useEffect, useMemo, useRef, PropsWithChildren, Suspense } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { useSearchParams } from "react-router-dom";
import "../../index.scss";
import { stripePromise } from "../../config/stripe-config";
import { usePaymentIntent } from "../../hooks/use-payment-intent";
import { useStoreState } from "../../models";
import { PaymentBillingInfoForm, StripeBillingInfo } from "../../components/billing/PaymentBillingInfoForm";
import { PaymentOrderSummaryInfo } from "../../components/billing/PaymentOrderSummaryInfo";
import { PaymentReviewStripe } from "../../components/billing/PaymentReviewStripe";
import { PaymentReviewPayPal } from "../../components/billing/PaymentReviewPayPal";
import { PaymentOrderConfirmation } from "../../components/billing/PaymentOrderConfirmation";
import { BillingInfoWrapper } from "./BillingInfoContext";
import clsx from "clsx";

export enum CheckoutProcessStep {
  PAYMENT_METHOD,
  REVIEW_STRIPE,
  REVIEW_PAYPAL,
  ORDER_CONFIRMATION
}
interface AsyncContentProps {
  setIsBusy: (isBusy: boolean) => void;
}


const CheckoutProcessTab = ({ children, hidden }: PropsWithChildren<{ hidden: boolean }>) => {
  return (
    <div className={clsx("xl:tw-w-1/2 lg:tw-w-2/3 tw-w-full tw-mx-auto", hidden ? "tw-hidden" : "tw-flex tw-flex-col")}>
      {children}
    </div>
  )
}


const AsyncContent: React.FC<AsyncContentProps> = ({ setIsBusy }) => {

  const [getCheckoutProcessStep, setCheckoutProcessStep] = useState<CheckoutProcessStep>(CheckoutProcessStep.PAYMENT_METHOD);
  const [stripeBillingInfo, setStripeBillingInfo] = useState<StripeBillingInfo | null>(null);
  const [queryParams] = useSearchParams();

  const cartStoreOptions = useStoreState(state => state.cart);
  const { payIntent, loading } = usePaymentIntent({ cart: cartStoreOptions.data, queryParams });
  console.log("Loading Status", { loading, payIntent })

  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const paymentIntent = queryParams.get("payment_intent");
  const clientSecret = queryParams.get("payment_intent_client_secret");
  const redirectStatus = queryParams.get("redirect_status");
  useEffect(() => {
    setIsBusy(loading && getCheckoutProcessStep !== CheckoutProcessStep.ORDER_CONFIRMATION);
  }, [loading, setIsBusy, getCheckoutProcessStep]);

  useEffect(() => {
    if (paymentIntent && redirectStatus === "succeeded") {
      setCheckoutProcessStep(CheckoutProcessStep.ORDER_CONFIRMATION);
    }
  }, [paymentIntent, redirectStatus]);


  const stripeOptions = useMemo<StripeElementsOptions | undefined>(() => payIntent?.data ? { clientSecret: payIntent.data, appearance: { theme: 'night', variables: { colorBackground: 'rgb(31, 38, 44)', colorPrimary: '#EE2337' }, rules: { '.Input': { border: '1px solid #6b7280' }, } } } : undefined, [payIntent?.data]);

  if (!stripeOptions && !queryParams.get("payment_intent")) {
    return null;
  }

  const handlePageStep = (step: CheckoutProcessStep) => {
    setCheckoutProcessStep(step);
  }

  const updateBillingInfo = (billingInfo: any) => {
    setStripeBillingInfo(billingInfo);
  }
  return (
    <BillingInfoWrapper>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <div className="tw-grid tw-grid-cols-2 tw-w-full tw-grow tw-gap-x-4">
          {
            getCheckoutProcessStep !== CheckoutProcessStep.ORDER_CONFIRMATION ?
              <>
                <div className="md:tw-col-span-1 tw-col-span-2 tw-flex tw-h-full tw-bg-gray-300 tw-py-6 md:tw-px-0 tw-px-5 md:tw-order-1 tw-order-2">
                  <CheckoutProcessTab hidden={getCheckoutProcessStep !== CheckoutProcessStep.PAYMENT_METHOD}>
                    <PaymentBillingInfoForm
                      emailRef={emailInputRef}
                      handlePageStep={handlePageStep}
                      updateBillingInfo={updateBillingInfo}
                    />
                    <button
                      className="tw-w-full tw-bg-red-100 tw-text-white tw-font-bold tw-border-none tw-outline-none tw-px-4 tw-py-2.5 tw-rounded-md tw-uppercase tw-my-6"
                      onClick={() => stripeBillingInfo !== null && handlePageStep(CheckoutProcessStep.REVIEW_STRIPE)}
                    >
                      Review Purchase
                    </button>
                  </CheckoutProcessTab>
                  <CheckoutProcessTab hidden={getCheckoutProcessStep !== CheckoutProcessStep.REVIEW_STRIPE}>
                    <PaymentReviewStripe
                      name={stripeBillingInfo?.name ?? ''}
                      email={emailInputRef.current?.value ?? ''}
                      address={`${stripeBillingInfo?.line1 ?? ''}, ${stripeBillingInfo?.line2 ?? ''}`}
                      state={stripeBillingInfo?.state ?? ''}
                      postal={stripeBillingInfo?.postal_code ?? ''}
                      country={stripeBillingInfo?.country ?? ''}
                    />
                    <button
                      className="tw-w-full tw-bg-transparent tw-text-gray-100 tw-font-bold tw-border-none tw-outline-none tw-px-4 tw-py-2.5 tw-rounded-md tw-uppercase"
                      onClick={() => handlePageStep(CheckoutProcessStep.PAYMENT_METHOD)}
                    >
                      Edit Information
                    </button>

                    <button
                      className="tw-w-full tw-bg-red-100 tw-text-white tw-font-bold tw-border-none tw-outline-none tw-px-4 tw-py-2.5 tw-rounded-md tw-uppercase"
                      form="payment-billing-info"
                      type="submit"
                    >
                      Pay Now
                    </button>
                  </CheckoutProcessTab>
                  <CheckoutProcessTab hidden={getCheckoutProcessStep !== CheckoutProcessStep.REVIEW_PAYPAL}>
                    <PaymentReviewPayPal
                      name="John Doe"
                      email="johnd@123email.com"
                    />
                  </CheckoutProcessTab>
                </div>

                <div className="md:tw-col-span-1 tw-col-span-2 tw-flex-1 tw-h-full tw-bg-dp-02 tw-py-6 md:tw-px-0 tw-px-5 md:tw-order-2 tw-order-1">
                  <div className="xl:tw-w-1/2 lg:tw-w-2/3 tw-w-full tw-flex tw-flex-col mx-auto">
                    <PaymentOrderSummaryInfo />
                  </div>
                </div>
              </>
              : <PaymentOrderConfirmation productId={cartStoreOptions?.data?.[0]?.productId} paymentIntent={clientSecret} productType="courses" />
          }
        </div>
      </Elements>
    </BillingInfoWrapper>
  )
};

export default AsyncContent;
