import { UserLanguage } from "../interfaces/user-language";
import { DataPointer } from "../interfaces/data-pointer";

export class DataConfig {
    public static readonly supportedLanguages: UserLanguage[] = [
        { id: "en", label: "English (US)" },
        { id: "fi", label: "Finnish" },
        { id: "es", label: "Spanish" },
        { id: "ja", label: "Japanese" },
        { id: "zh", label: "Chinese" },
        { id: "ru", label: "Russian" },
        { id: "de", label: "German" },
        { id: "ar", label: "Arabic" },
        { id: "fr", label: "French" },
        { id: "yue", label: "Cantonese" },
        { id: "ko", label: "Korean" },
        { id: "tr", label: "Turkish" },
        { id: "aa", label: "Afar" },
        { id: "ab", label: "Abkhazian" },
        { id: "af", label: "Afrikaans" },
        { id: "ak", label: "Akan" },
        { id: "am", label: "Amharic" },
        { id: "an", label: "Aragonese" },
        { id: "ar", label: "Arabic" },
        { id: "av", label: "Avar" },
        { id: "ay", label: "Aymara" },
        { id: "az", label: "Azerbaijani" },
        { id: "ba", label: "Bashkir" },
        { id: "be", label: "Belarusian" },
        { id: "bg", label: "Bulgarian" },
        { id: "bi", label: "Bislama" },
        { id: "bm", label: "Bambara" },
        { id: "bn", label: "Bengali" },
        { id: "bo", label: "Tibetan" },
        { id: "br", label: "Breton" },
        { id: "bs", label: "Bosnian" },
        { id: "ca", label: "Catalan" },
        { id: "ce", label: "Chechen" },
        { id: "ch", label: "Chamorro" },
        { id: "co", label: "Corsican" },
        { id: "cs", label: "Czech" },
        { id: "cv", label: "Chuvash" },
        { id: "cy", label: "Welsh" },
        { id: "da", label: "Danish" },
        { id: "dv", label: "Dhivehi" },
        { id: "dz", label: "Dzongkha" },
        { id: "ee", label: "Ewe" },
        { id: "el", label: "Greek" },
        { id: "eo", label: "Esperanto" },
        { id: "et", label: "Estonian" },
        { id: "eu", label: "Basque" },
        { id: "fa", label: "Persian" },
        { id: "fj", label: "Fijian" },
        { id: "fo", label: "Faroese" },
        { id: "fy", label: "West Frisian" },
        { id: "ga", label: "Irish" },
        { id: "gd", label: "Scottish Gaelic" },
        { id: "gl", label: "Galician" },
        { id: "gn", label: "Guarani" },
        { id: "gu", label: "Gujarati" },
        { id: "gv", label: "Manx" },
        { id: "ha", label: "Hausa" },
        { id: "he", label: "Hebrew" },
        { id: "hi", label: "Hindi" },
        { id: "ho", label: "Hiri Motu" },
        { id: "hr", label: "Croatian" },
        { id: "ht", label: "Haitian" },
        { id: "hu", label: "Hungarian" },
        { id: "hy", label: "Armenian" },
        { id: "hz", label: "Herero" },
        { id: "ia", label: "Interlingua" },
        { id: "id", label: "Indonesian" },
        { id: "ie", label: "Interlingue" },
        { id: "ig", label: "Igbo" },
        { id: "is", label: "Icelandic" },
        { id: "it", label: "Italian" },
        { id: "iu", label: "Inuktitut" },
        { id: "ja", label: "Japanese" },
        { id: "ka", label: "Georgian" },
        { id: "ki", label: "Kikuyu" },
        { id: "kk", label: "Kazakh" },
        { id: "kl", label: "Greenlandic" },
        { id: "km", label: "Cambodian" },
        { id: "kn", label: "Kannada" },
        { id: "ko", label: "Korean" },
        { id: "kr", label: "Kanuri" },
        { id: "ks", label: "Kashmiri" },
        { id: "ku", label: "Kurdish" },
        { id: "kv", label: "Komi" },
        { id: "kw", label: "Cornish" },
        { id: "ky", label: "Kirghiz" },
        { id: "lb", label: "Luxembourgish" },
        { id: "lg", label: "Ganda" },
        { id: "li", label: "Limburgian" },
        { id: "ln", label: "Lingala" },
        { id: "lo", label: "Laotian" },
        { id: "lt", label: "Lithuanian" },
        { id: "lv", label: "Latvian" },
        { id: "mg", label: "Malagasy" },
        { id: "mh", label: "Marshallese" },
        { id: "mi", label: "Maori" },
        { id: "mk", label: "Macedonian" },
        { id: "ml", label: "Malayalam" },
        { id: "mn", label: "Mongolian" },
        { id: "mo", label: "Moldovan" },
        { id: "mr", label: "Marathi" },
        { id: "ms", label: "Malay" },
        { id: "mt", label: "Maltese" },
        { id: "my", label: "Burmese" },
        { id: "na", label: "Nauruan" },
        { id: "nd", label: "North Ndebele" },
        { id: "ne", label: "Nepali" },
        { id: "ng", label: "Ndonga" },
        { id: "nl", label: "Dutch" },
        { id: "nn", label: "Norwegian Nynorsk" },
        { id: "no", label: "Norwegian" },
        { id: "nr", label: "South Ndebele" },
        { id: "nv", label: "Navajo" },
        { id: "ny", label: "Chichewa" },
        { id: "oc", label: "Occitan" },
        { id: "oj", label: "Ojibwa" },
        { id: "om", label: "Oromo" },
        { id: "or", label: "Oriya" },
        { id: "os", label: "Ossetian" },
        { id: "pa", label: "Punjabi" },
        { id: "pl", label: "Polish" },
        { id: "ps", label: "Pashto" },
        { id: "pt", label: "Portuguese" },
        { id: "qu", label: "Quechua" },
        { id: "rm", label: "Raeto Romance" },
        { id: "rn", label: "Kirundi" },
        { id: "ro", label: "Romanian" },
        { id: "ru", label: "Russian" },
        { id: "rw", label: "Rwandi" },
        { id: "sc", label: "Sardinian" },
        { id: "sd", label: "Sindhi" },
        { id: "sg", label: "Sango" },
        { id: "sh", label: "Serbo-Croatian" },
        { id: "si", label: "Sinhalese" },
        { id: "sk", label: "Slovak" },
        { id: "sl", label: "Slovenian" },
        { id: "sm", label: "Samoan" },
        { id: "sn", label: "Shona" },
        { id: "so", label: "Somalia" },
        { id: "sq", label: "Albanian" },
        { id: "sr", label: "Serbian" },
        { id: "ss", label: "Swati" },
        { id: "st", label: "Southern Sotho" },
        { id: "su", label: "Sundanese" },
        { id: "sv", label: "Swedish" },
        { id: "sw", label: "Swahili" },
        { id: "ta", label: "Tamil" },
        { id: "te", label: "Telugu" },
        { id: "tg", label: "Tajik" },
        { id: "th", label: "Thai" },
        { id: "ti", label: "Tigrinya" },
        { id: "tk", label: "Turkmen" },
        { id: "tl", label: "Tagalog" },
        { id: "tn", label: "Tswana" },
        { id: "to", label: "Tonga" },
        { id: "ts", label: "Tsonga" },
        { id: "tt", label: "Tatar" },
        { id: "tw", label: "Twi" },
        { id: "ty", label: "Tahitian" },
        { id: "ug", label: "Uyghur" },
        { id: "uk", label: "Ukrainian" },
        { id: "ur", label: "Urdu" },
        { id: "ve", label: "Venda" },
        { id: "vi", label: "Vietnamese" },
        { id: "wa", label: "Walloon" },
        { id: "wo", label: "Wolof" },
        { id: "xh", label: "Xhosa" },
        { id: "yi", label: "Yiddish" },
        { id: "yo", label: "Yoruba" },
        { id: "za", label: "Zhuang" },
        { id: "zh", label: "Chinese" },
        { id: "zu", label: "Zulu" },
    ];
    public static readonly supportedPlatforms: DataPointer[] = [
        { id: "pc", label: "PC" },
        { id: "console", label: "Console" },
        { id: "mobile", label: "Mobile" },
    ];
    public static readonly supportedInputMethods: DataPointer[] = [
        { id: "keyboard", label: "Keyboard" },
        { id: "controller", label: "Controller" },
        { id: "touch", label: "Touch" },
    ];
}
