import { Controller, useForm } from "react-hook-form";
import { SettingsSchema } from "../schemas/settings-schema";
import { flatten, unflatten } from "flat";
import { useSettings } from "../hooks/use-settings";
import { useCurrentUser } from "../hooks/use-current-user";
import { useCallback, useEffect, useMemo } from "react";
import clsx from "clsx";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase-config";
import { setDoc } from "firebase/firestore";
import { merge } from "lodash";
import { Link } from "react-router-dom";
import { routes } from "../App";
import { toast } from "react-toastify";
import Switch from "react-switch";
import { FlatSettings } from '../definitions/settings-type';

export const Toggle = ({ name, toggle, enabled, disabled, errorMessage }: { name: string; toggle: () => void; enabled: boolean; disabled: boolean; errorMessage: string }) => {
  return (
    <>
      <button
        type="button"
        disabled={disabled}
        onClick={toggle}
        className={clsx({ "tw-bg-red-100": enabled, "tw-bg-gray-200": !enabled }, "tw-relative tw-inline-flex tw-h-[1.65rem] tw-w-11 tw-flex-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-indigo-500 tw-focus:ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-bg-gray-300")}
        role="switch"
        aria-checked={enabled}
      >
        <span className="tw-sr-only">Use {name} setting</span>
        <span className={clsx({ "tw-translate-x-3.5": enabled, "tw-translate-x-0": !enabled, "tw-bg-white": !disabled, "tw-bg-gray-200": disabled }, "tw-pointer-events-none tw-relative tw-inline-block tw-h-5 tw-w-5 tw-transform tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw-right-1")}>
          <span className={clsx({ "tw-opacity-0 tw-ease-out tw-duration-100": enabled, "tw-opacity-100 tw-ease-in tw-duration-200": !enabled }, "tw-absolute tw-inset-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-transition-opacity")} aria-hidden={!enabled}>
            <svg className="tw-h-3 tw-w-3 tw-text-gray-400" fill="none" viewBox="0 0 12 12">
              <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
          <span className={clsx({ "tw-opacity-0 tw-ease-out tw-duration-100": !enabled, "tw-opacity-100 tw-ease-in tw-duration-100": enabled }, "tw-absolute tw-inset-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-transition-opacity")} aria-hidden={enabled}>
            <svg className="tw-h-3 tw-w-3 tw-text-red-100" fill="currentColor" viewBox="0 0 12 12">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </button>
      {disabled && <div className="tw-text-xs tw-text-red-100 tw-ml-2">{errorMessage}</div>}
    </>
  );
};

const defaultSettings = {
  sms: {
    bookings: {
      instant: false,
      scheduled: false,
    },
    status: {
      accept: false,
    }
  },
} as const;
const flattenedDefault = flatten<SettingsSchema, FlatSettings>(defaultSettings);

export const CoachSettings = () => {
  const { user } = useCurrentUser();
  const { settingsData: settings } = useSettings(user?.uid);
  const formattedSettings = useMemo(() => flatten<SettingsSchema, FlatSettings>(settings ?? ({} as SettingsSchema)), [settings]);
  const form = useForm({ defaultValues: merge({}, flattenedDefault, formattedSettings) });

  useEffect(() => {
    if (formattedSettings) {
      form.reset(merge({}, flattenedDefault, formattedSettings));
    }
  }, [formattedSettings, form]);

  const submit = useCallback(
    async (data: FlatSettings) => {
      const formattedSettings = unflatten<FlatSettings, SettingsSchema>(data);
      const withDefaults = merge({}, defaultSettings, formattedSettings) as SettingsSchema;
      const d = doc(collection(db, "settings"), user?.uid);
      if (!settings) await setDoc(d, withDefaults);
      else updateDoc(d, { ...withDefaults });

      toast("Settings updated");
    },
    [user?.uid, settings]
  );

  return (
    <form onSubmit={form.handleSubmit(submit)}>
      {user?.phoneNumber ? null : (
        <div className="tw-mb-8 tw-text-sm">
          <Link to={routes.editCoachProfile} className="tw-text-red-100 tw-underline">
            Click here to add a phone number
          </Link>{" "}
          in order to recieve SMS notifications.
        </div>
      )}
      <Controller
        control={form.control}
        name="sms.bookings.instant"
        render={({ field }) => (
          <div className="tw-flex tw-items-center tw-justify-between ">
            <span className="tw-flex tw-flex-grow tw-flex-col tw-mr-5">
              <span className="tw-text-sm tw-font-medium tw-text-white" id="availability-label">
                Instant Booking SMS Notifications
              </span>
              <span className="tw-text-sm tw-text-gray-100" id="availability-description">
                Recieve SMS notifications for instant bookings
              </span>
            </span>
            <div className={user?.phoneNumber ? "" : "tw-pt-6"}>
              <Switch disabled={!user?.phoneNumber} onChange={() => field.onChange(!field.value)} checked={field.value} height={22} width={45} />
              {!user?.phoneNumber && <p className="tw-text-xs tw-text-red-100 tw-ml-1">Add a phone number to enable this feature</p>}
            </div>
          </div>
        )}
      />
      <Controller
        control={form.control}
        name="sms.bookings.scheduled"
        render={({ field }) => (
          <div className="tw-flex tw-items-center tw-justify-between ">
            <span className="tw-flex tw-flex-grow tw-flex-col tw-mr-5">
              <span className="tw-text-sm tw-font-medium tw-text-white" id="availability-label">
                Scheduled Booking SMS Notifications
              </span>
              <span className="tw-text-sm tw-text-gray-100" id="availability-description">
                Recieve SMS notifications for scheduled bookings
              </span>
            </span>
            <div className={user?.phoneNumber ? "tw-my-6" : "tw-pt-6"}>
              <Switch disabled={!user?.phoneNumber} onChange={() => field.onChange(!field.value)} checked={field.value} height={22} width={45} />
              {!user?.phoneNumber && <p className="tw-text-xs tw-text-red-100 tw-ml-1">Add a phone number to enable this feature</p>}
            </div>
          </div>
        )}
      />
      <Controller
        control={form.control}
        name="sms.status.accept"
        render={({ field }) => (
          <div className="tw-flex tw-items-center tw-justify-between">
            <span className="tw-flex tw-flex-grow tw-flex-col tw-mr-5">
              <span className="tw-text-sm tw-font-medium tw-text-white" id="availability-label">
                Online/Offline Updates SMS Notifications
              </span>
              <span className="tw-text-sm tw-text-gray-100" id="availability-description">
                Receive SMS notifications when you set your status as online or offline.
              </span>
            </span>
            <div className={user?.phoneNumber ? "" : "tw-pt-6"}>
              <Switch disabled={!user?.phoneNumber} onChange={() => field.onChange(!field.value)} checked={field.value} height={22} width={45} />
              {!user?.phoneNumber && <p className="tw-text-xs tw-text-red-100 tw-ml-1">Add a phone number to enable this feature</p>}
            </div>
          </div>
        )}
      />
      {form.formState.isDirty && (
        <button type="submit" className="tw-mt-8 tw-w-full tw-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-red-100 hover:tw-bg-red-200 tw-focus:tw-outline-none tw-focus:tw-ring-2 tw-focus:tw-ring-offset-2 tw-focus:tw-ring-red-500">
          Save
        </button>
      )}
    </form>
  );
};
