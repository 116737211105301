import { FC } from "react";
import { GainSchema } from "../schemas/gain-schema";
import "./AdminGainListItem.scss";
import { GainTypeExt } from "../definitions/gain-type";
import { Button } from "react-bootstrap";
import { PdfUtils } from "../utils/pdf-utils";
import { TimeUtils } from "../utils/time-utils";

export const AdminGainListItem: FC<{
  gainData: GainSchema;
  hasActions: boolean;
}> = function (props) {
  return (
    <div className={"tw-mb-8"} key={props.gainData.id}>
      <div className="dashboard-box-outline">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-p-4">
          <div className="tw-pb-4 md:tw-pr-4">
            <div className="">Gain Type</div>
            <div className="">
              <span className="secondary-color">
                {GainTypeExt.toDisplayLabel(props.gainData?.gainType)}
              </span>
            </div>
          </div>
          <div className="tw-pb-4 md:tw-pr-4">
            <div className="">Purchased User</div>
            <div className="">
              <span className="secondary-color">
                {props.gainData.targetUserPointer.name}
              </span>
            </div>
            <div className="">
              <span className="secondary-color">
                ({props.gainData.targetUserPointer.id})
              </span>
            </div>
          </div>
          <div className="tw-pb-4 md:tw-pr-4">
            <div className="">Total Spent Amount</div>
            <div className="">
              <span className="secondary-color">
                {props.gainData.totalAmount} USD
              </span>
            </div>
          </div>
          <div className="tw-pb-4 md:tw-pr-4">
            <div className="">Purchased Credit Amount</div>
            <div className="">
              <span className="secondary-color">
                {props.gainData.creditAmount} USD
              </span>
            </div>
          </div>
          {props.gainData.bonusAmount && (
            <div className="tw-pb-4 md:tw-pr-4">
              <div className="">Bonus Credit Amount</div>
              <div className="">
                <span className="secondary-color">
                  {props.gainData.bonusAmount} USD
                </span>
              </div>
            </div>
          )}
          <div className="tw-pb-4 md:tw-pr-4">
            <div className="">Transaction Fee</div>
            <div className="">
              <span className="secondary-color">
                {props.gainData.houseAmount} USD
              </span>
            </div>
          </div>
          <div className="tw-pb-4 md:tw-pr-4">
            <div className="">Purchase Date</div>
            <div className="">
              {TimeUtils.timestampToLocaleString(props.gainData.created)}
            </div>
          </div>
          <Button
            onClick={() => PdfUtils.generateInvoiceForGainData(props.gainData)}
            variant={"outline-light"}
            className={"tw-mt-3"}
          >
            Download Invoice
          </Button>
        </div>
      </div>
    </div>
  );
};
