import { BsDiscord, BsInstagram, BsTiktok, BsTwitch, BsTwitter } from "react-icons/bs"

export function Community() {

  return (
    <div className="tw-flex tw-gap-3">
      <div >
        <a target="_blank" rel="noopener noreferrer" href="http://www.twitter.com/fpslounge_">
          <BsTwitter className="tw-text-gray-100 tw-p-2.5 tw-h-12 tw-w-12 tw-rounded-lg tw-bg-gray-700" size={30} />
        </a>
      </div>
      <div >
        <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/fpslounge">
          <BsDiscord className="tw-text-gray-100 tw-p-2.5 tw-h-12 tw-w-12 tw-rounded-lg tw-bg-gray-700" size={30} />
        </a>
      </div>
      <div >
        <a target="_blank" rel="noopener noreferrer" href="http://www.instagram.com/fpslounge">
          <BsInstagram className="tw-text-gray-100 tw-p-2.5 tw-h-12 tw-w-12 tw-rounded-lg tw-bg-gray-700" size={30} />
        </a>
      </div>
      <div >
        <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@fps_lounge">
          <BsTiktok className="tw-text-gray-100 tw-p-2.5 tw-h-12 tw-w-12 tw-rounded-lg tw-bg-gray-700" size={30} />
        </a>
      </div>
      <div >
        <a target="_blank" rel="noopener noreferrer" href="https://www.twitch.tv/fps_lounge">
          <BsTwitch className="tw-text-gray-100 tw-p-2.5 tw-h-12 tw-w-12 tw-rounded-lg tw-bg-gray-700" size={30} />
        </a>
      </div>
    </div>
  )
}
