import { Controller, useForm } from "react-hook-form";
import Switch from "react-switch";
import { useCurrentUser } from "../../hooks/use-current-user";
import { SettingsSchema } from "../../schemas/settings-schema";
import flatten, { unflatten } from "flat";
import { merge } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useSettings } from "../../hooks/use-settings";
import { FlatSettings } from "../../definitions/settings-type";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase-config";
import { toast } from "react-toastify";

const defaultSettings = {
  sms: {
    bookings: {
      instant: false,
      scheduled: false,
    },
    status: {
      accept: false,
    }
  },
} as const;
const flattenedDefault = flatten<SettingsSchema, FlatSettings>(defaultSettings);


export function NotificationTab() {

  const { user, isStudent, isCoach } = useCurrentUser();
  const { settingsData: settings } = useSettings(user?.uid);
  const formattedSettings = useMemo(() => flatten<SettingsSchema, FlatSettings>(settings ?? ({} as SettingsSchema)), [settings]);
  const form = useForm({ defaultValues: merge({}, flattenedDefault, formattedSettings) });
  useEffect(() => {
    if (formattedSettings) {
      form.reset(merge({}, flattenedDefault, formattedSettings));
    }
  }, [formattedSettings, form]);

  const submit = useCallback(
    async (data: FlatSettings) => {
      const formattedSettings = unflatten<FlatSettings, SettingsSchema>(data);
      const withDefaults = merge({}, defaultSettings, formattedSettings) as SettingsSchema;
      const d = doc(collection(db, "settings"), user?.uid);
      if (!settings) await setDoc(d, withDefaults);
      else updateDoc(d, { ...withDefaults });
      toast("Settings updated");
    },
    [user?.uid, settings]
  );


  return <>
    <div className="tw-flex tw-flex-col tw-gap-6 tw-mt-10">
      <div className="tw-flex tw-flex-col tw-gap-2 tw-items-start">
        <div className="tw-text-red-600 tw-text-xl tw-font-bold" > Notification Preferences </div>
        <div className="tw-text-gray-500" >
          To set your preferences, please add a verified phone number and email.
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-gap-2">
        <div className={`tw-flex tw-flex-row tw-justify-between ${isStudent ? "md:tw-w-[915px]" : "md:tw-w-[858px]"}`}>
          <div className=""> &nbsp; </div>
          <div className={`${isStudent ? "tw-gap-4" : "tw-pr-8 tw-gap-12"}  tw-pl-8 tw-flex tw-flex-row md:tw-gap-20`}>
            <div className="">Email </div>
            <div className="tw-flex tw-flex-col sm:tw-flex-row">
              <span className="">{isStudent ? "SMS " : "SMS"}</span>
              {isStudent &&
                <div className="tw-flex tw-flex-col sm:tw-inline">
                  <span className="md:tw-mx-1">(Coming</span>
                  <span className="">Soon)</span>
                </div>
              }
            </div>
          </div>
        </div>

        <form onSubmit={form.handleSubmit(submit)}>
          <div className="tw-flex tw-flex-col tw-gap-6 tw-items-start tw-justify-start">
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between md:tw-w-[858px]">
              <div className="tw-flex tw-flex-col tw-gap-2 tw-items-start tw-justify-start">
                <div className="" > Instant Bookings </div>
                <div className="tw-text-sm tw-text-gray-500" >
                  Turn on notifications for instant booking updates.
                </div>
              </div>

              <div className={`tw-pr-8 tw-pl-8 md:tw-pl-8 tw-flex tw-flex-row ${isStudent ? "tw-gap-4 md:tw-gap-28" : "tw-gap-12 md:tw-gap-[75px]"} tw-items-center tw-justify-start`}>
                <div className="tw-w-11 tw-h-6 tw-relative">
                  <div className="tw-w-5 tw-h-5 tw-absolute tw-left-[4px] tw-top-0.5" > <Switch onChange={() => { }} checked={true} disabled={true} height={22} width={45} /> </div>
                </div>

                <div className="tw-w-11 tw-h-6 tw-relative">
                  <Controller
                    control={form.control}
                    name="sms.bookings.instant"
                    render={({ field }) => (
                      <div className="tw-absolute tw-left-[8px] tw-top-0.5">
                        {isStudent ?
                          <Switch onChange={() => { }} checked={false} disabled={true} height={22} width={45} /> :
                          <Switch
                            disabled={!user?.phoneNumber}
                            onChange={async () => {
                              field.onChange(!field.value);
                              let newFormValues = form.getValues();
                              newFormValues["sms.bookings.instant"] = !field.value;
                              await submit(newFormValues);
                            }}
                            checked={field.value}
                            height={22}
                            width={45}
                          />
                        }
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between md:tw-w-[858px] ">
              <div className="tw-flex tw-flex-col tw-gap-2 tw-items-start tw-justify-start ">
                <div className="" > Scheduled Bookings </div>
                <div className="tw-text-sm tw-text-gray-500" >
                  Turn on notifications for Scheduled Booking updates.
                </div>
              </div>
              <div className={`tw-pr-8 tw-pl-8 md:tw-pl-8 tw-flex tw-flex-row ${isStudent ? "tw-gap-4 md:tw-gap-28" : "tw-gap-12 md:tw-gap-[75px]"} tw-items-center tw-justify-start`}>
                <div className="tw-w-11 tw-h-6 tw-relative">
                  <div className="tw-absolute tw-left-[4px] tw-top-0.5" > <Switch onChange={() => { }} checked={true} disabled={true} height={22} width={45} /> </div>
                </div>
                <div className="tw-w-11 tw-h-6 tw-relative">
                  <Controller
                    control={form.control}
                    name="sms.bookings.scheduled"
                    render={({ field }) => (
                      <div className="tw-absolute tw-left-[8px] tw-top-0.5" >
                        {isStudent ?
                          <Switch onChange={() => { }} checked={false} disabled={true} height={22} width={45} /> :
                          <Switch
                            disabled={!user?.phoneNumber}
                            onChange={async () => {
                              field.onChange(!field.value);
                              let newFormValues = form.getValues();
                              newFormValues["sms.bookings.scheduled"] = !field.value;
                              await submit(newFormValues);
                            }}
                            checked={field.value}
                            height={22}
                            width={45}
                          />
                        }
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>

            {isCoach && (
              <div className="tw-flex tw-flex-row tw-items-center tw-justify-between md:tw-w-[858px] ">
                <div className="tw-flex tw-flex-col tw-gap-2 tw-items-start tw-justify-start ">
                  <div className="" > Online/Offline Update SMS Notifications </div>
                  <div className="tw-text-sm tw-text-gray-500" >
                    Receive SMS notifications when you set your status as online or offline.
                  </div>
                </div>
                <div className="tw-pr-8 tw-pl-8 md:tw-pl-8 tw-flex tw-flex-row tw-gap-12 md:tw-gap-[75px] tw-items-center tw-justify-start">
                  <div className="tw-w-11 tw-h-6 tw-relative">
                    <div className="tw-absolute tw-left-[4px] tw-top-0.5" > <Switch onChange={() => { }} checked={true} disabled={true} height={22} width={45} /> </div>
                  </div>
                  <div className="tw-w-11 tw-h-6 tw-relative">
                    <Controller
                      control={form.control}
                      name="sms.status.accept"
                      render={({ field }) => (
                        <div className="tw-absolute tw-left-[8px] tw-top-0.5" >
                          <Switch
                            disabled={!user?.phoneNumber}
                            onChange={async () => {
                              field.onChange(!field.value);
                              let newFormValues = form.getValues();
                              newFormValues["sms.status.accept"] = !field.value;
                              await submit(newFormValues);
                            }}
                            checked={field.value}
                            height={22}
                            width={45} />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  </>;
}
