import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";

export type StripeAddress = StripeAddressElementChangeEvent['value']['address'];
type ContextDispatch<T> = readonly [T, Dispatch<SetStateAction<T>>];

const BillingInfoContext = createContext<ContextDispatch<StripeAddress | null> | null>(null);

export const useBillingInfo = () => useContext(BillingInfoContext);

export const BillingInfoWrapper = ({ children }: PropsWithChildren<{}>) => {
    const handleBillingInfo = useState<StripeAddress | null>(null);

    return (
        <BillingInfoContext.Provider value={handleBillingInfo}>
            {children}
        </BillingInfoContext.Provider>
    )
}