import "react-multi-carousel/lib/styles.css";
import { HomePageHeroComponent } from "../../components/HomePageHeroComponent";
import { Helmet } from "react-helmet";
import { EnvUtils } from "../../utils/env-utils";
import { ReferralHeroData } from "../../components/ReferralHeroData";
import { HomePageCoachCard } from "./HomePageCoachCard";
import { LoginButtons } from "../../components/LoginButtons";
import { useLocation } from "react-router-dom";
import { useCurrentUser } from "../../hooks/use-current-user";
import { routes } from "../../App";

export const HomePage = () => {
  const { authUser } = useCurrentUser();
  const location = useLocation();
  return (
    <div className="">
      <Helmet>
        <title>FPS Lounge | Coaching</title>
        <meta name="description" content="Book 1:1 coaching with the top pros in the game" />
        <script type="text/javascript">
          {`!function(m,a,i,t,r,e){if(m.RH)return;r=m.RH={},r.uuid=t,r.loaded=0,r.base_url=i,r.queue=[],m.rht=function(){r.queue.push(arguments)};e=a.getElementsByTagName('script')[0],c=a.createElement('script');c.async=!0,c.src=i+'/widget/'+t+'.js',e.parentNode.insertBefore(c,e),console.log('ReferralHero script loaded successfully');}(window,document,'https://app.referralhero.com', '${EnvUtils.readString("REACT_APP_REFERRALHERO_ID")}');`}
        </script>
        <ReferralHeroData />
      </Helmet>
      <div>
        <div style={{ marginBottom: '10rem' }}>
          <HomePageHeroComponent />
        </div>
        <div className={"md:tw-flex md:tw-justify-center tw-pb-5 md:tw-pt-28 lg:tw-pt-28 xl:tw-pt-48 2xl:tw-pt-96"}>
          <HomePageCoachCard />
        </div>
        <div className="tw-bg-dp-01 tw-w-screen">
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-around tw-items-center tw-pt-12">
            <div className="tw-w-80 md:tw-w-[39rem] tw-pt-8 tw-pb-20 tw-text-center md:tw-text-left">
              <div className="tw-font-extrabold tw-text-3xl tw-uppercase" style={{ textShadow: '0px 0px 25px rgba(255, 47, 52, 0.55)' }}>Built By Gamers,
                <span className="tw-font-extrabold tw-text-3xl tw-text-red-600 tw-uppercase"> For Gamers</span>
              </div>
              <p>
                In 2022, game developers and platforms dominated gaming profits, often sidelining the influencers and streamers who significantly drive the industry's value. FPS Lounge was founded to rebalance this. Our aim? Ensure talented gamers, streamers, and Esports professionals earn fairly from their passion. FPS Lounge: a marketplace designed by gamers, for gamers, connecting you with the world's best players. Simple, straightforward, and here to stay.
              </p>
            </div>
            <img src={require('../../assets/images/Homepage/agent-group.png')} alt="agent-group" className="tw-relative tw-h-80 md:tw-h-[30rem]" />
          </div>
        </div>
        <div className="tw-mb-16">
          <div className="tw-text-center tw-my-16">
            <div className="tw-font-extrabold tw-text-3xl tw-text-red-600 tw-uppercase">Already An Expert?</div>
            <div className="tw-font-extrabold tw-text-3xl tw-uppercase">Become A Coach!</div>
          </div>
          <div className="tw-flex tw-gap-4 md:tw-gap-8 tw-justify-center tw-flex-wrap md:tw-flex-nowrap">
            <div className="tw-bg-dp-01 tw-rounded-xl tw-items-center tw-text-center md:tw-px-12 md:tw-pb-20 md:tw-pt-12 tw-w-44 md:tw-w-64 tw-h-44 md:tw-h-64">
              <img src={require('../../assets/icons/money-bag.png')} alt="Hourly Rate" width="100px" height="100px" />
              <div>Set Your Hourly Rate</div>
            </div>
            <div className="tw-bg-dp-01 tw-rounded-xl tw-items-center tw-text-center md:tw-px-12 md:tw-pb-20 md:tw-pt-12 tw-w-44 md:tw-w-64 tw-h-44 md:tw-h-64">
              <img src={require('../../assets/icons/clock.png')} alt="Availability" width="100px" height="100px" />
              <div>Set Your Own Availability</div>
            </div>
            <div className="tw-bg-dp-01 tw-rounded-xl tw-items-center tw-text-center md:tw-px-12 md:tw-pb-20 md:tw-pt-12 tw-w-44 md:tw-w-64 tw-h-44 md:tw-h-64">
              <img src={require('../../assets/icons/earth-globe.png')} alt="Worldwide" width="100px" height="100px" />
              <div className="tw-line-clamp2">Work Worldwide Anywhere, Anytime</div>
            </div>
            <div className="tw-bg-dp-01 tw-rounded-xl tw-items-center tw-text-center md:tw-px-12 md:tw-pb-20 md:tw-pt-12 tw-w-44 md:tw-w-64 tw-h-44 md:tw-h-64">
              <img src={require('../../assets/icons/marketing.png')} alt="Communities" width="100px" height="100px" />
              <div>Grow Your Own Communities</div>
            </div>
          </div>
          <div className="tw-pt-4 md:tw-pt-0">
            <LoginButtons />
          </div>
        </div>
        {!authUser && location.pathname !== routes.login &&
          <div className="tw-flex tw-justify-center tw-mb-20">
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-bg-dp-01 tw-w-[22rem] md:tw-w-[85rem] tw-rounded-xl">
              <div className="tw-flex tw-flex-col tw-text-center md:tw-text-left tw-pt-8 md:tw-pt-16 md:tw-pl-16 tw-pb-16">
                <div className="tw-font-extrabold tw-text-3xl tw-text-red-600 tw-uppercase">Register for Free
                  <span className="tw-font-extrabold tw-text-3xl tw-uppercase tw-text-white" style={{ textShadow: '0px 0px 25px rgba(255, 47, 52, 0.55)' }}> & Start Getting Better</span>
                </div>
                <div className="tw-pt-8 md:tw-pt-0">
                  <LoginButtons />
                </div>
              </div>
              <div className="tw-flex">
                <img className="tw-w-52 tw-h-56 md:tw-h-[28rem] md:tw-w-96 tw-relative tw-left-0 md:tw-left-13" src={require('../../assets/images/Homepage/PngItem_4553161 1.png')} alt="Communities" />
                <img className="tw-w-44 tw-h-56 md:tw-h-[28rem] md:tw-w-96 tw-ml-[-2rem]" src={require('../../assets/images/Homepage/Neon_KeyArt-Web 1.png')} alt="Communities" />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
