import { MdAccountCircle, } from "react-icons/md";
import { useCurrentUser } from "../hooks/use-current-user";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { routes } from "../App";
import { auth, db } from "../config/firebase-config";
import { UserSchemaExt } from "../schemas/user-schema";
import { useBusy } from "../hooks/use-busy";
import { collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useStoreActions } from "../models";
import { OnlineModePopup } from "./alerts/OnlineModePopup";
import Switch from "react-switch";

interface DashboardDropdownProps {
  setActiveTab: (tabName: string) => void;
}

export const DashboardDropdown: React.FC<DashboardDropdownProps> = ({ setActiveTab }) => {
  const navigate = useNavigate();
  const { authUser, user, userLoading, isStudent, isCoach, isAdmin } = useCurrentUser();
  const { isBusy, setIsBusy } = useBusy();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const uiStoreActions = useStoreActions((actions) => actions.ui);

  async function onClickSignOut() {
    await auth.signOut();
    setActiveTab('first');
    navigate(routes.login);
  }
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  async function setOnlineMode(enable: boolean): Promise<void> {
    if (userLoading) return;
    setIsBusy(true);
    await updateDoc(doc(collection(db, 'users'), authUser?.uid), {
      isOnline: enable ? serverTimestamp() : null,
    });
    setIsBusy(false);
    if (enable) await uiStoreActions.addCustomAlert({
      title: 'You are now online!',
      closeOnClickOutside: true,
      builder: (alertProps) => {
        return <OnlineModePopup {...alertProps} />;
      }
    })
  }
  function buildOnlineCheckbox() {
    return <div className="tw-flex tw-justify-center tw-py-2">
      <span className={'tw-me-3'}>{UserSchemaExt.isOnline(user) ? 'You Are Online' : 'You Are Offline'}</span>
      <Switch height={20} width={45} handleDiameter={18} className="" onChange={enable => setOnlineMode(enable)}
        checked={UserSchemaExt.isOnline(user)} />
    </div>;
  }
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="tw-relative tw-mr-1 md:tw-mr-5">
      {user?.profileImage ? (
        <img src={user.profileImage} alt="Profile" className="tw-h-9 tw-w-9 tw-rounded-full tw-transition-transform tw-duration-500 tw-ease-in-out tw-cursor-pointer" onClick={toggleDropdown} onMouseDown={(event) => event.stopPropagation()} />
      ) : (
        <MdAccountCircle size={30} onClick={toggleDropdown} onMouseDown={(event) => event.stopPropagation()} className={`tw-transition-transform tw-duration-500 tw-ease-in-out tw-cursor-pointer ${showDropdown ? '' : ''}`} />
      )}
      {showDropdown && (
        <div ref={dropdownRef} className={`tw-absolute tw-rounded-md tw-mt-1 tw-right-0 tw-w-64 md:tw-w-52 tw-py-2 tw-bg-[#30363C] tw-shadow-md tw-z-30 tw-origin-top tw-transform tw-duration-200 ${showDropdown ? "tw-opacity-100 tw-scale-100" : "tw-opacity-0 tw-scale-95"}`}>
          <div className="tw-py-1 tw-px-2">
            {/* ... rest of the code */}
            <div className="tw-flex tw-flex-col">
              <div className="tw-flex tw-flex-row tw-m-1">
                {user?.profileImage ? (
                  <img src={user.profileImage} alt="Profile" className="tw-h-9 tw-w-9 tw-rounded-full" />
                ) : (
                  <MdAccountCircle size={35} />
                )}
                <div className="tw-flex tw-flex-col tw-font-semibold tw-text-base tw-justify-center">
                  <div> &nbsp;&nbsp;{`${_.truncate(authUser?.displayName ?? 'Unknown', { length: 18 })}`}</div>
                  {/* <div>Away</div> */}
                </div>
              </div>
              <div>
                {isStudent || isAdmin || <div>{buildOnlineCheckbox()}</div>}
              </div>
            </div>
            <div className="tw-flex tw-justify-center tw-rounded-md tw-py-2 tw-ml-2 tw-cursor-pointer md:hover:tw-bg-red-600/25" onClick={() => { setActiveTab("settings"); toggleDropdown(); }} >Settings</div>
            {isCoach || isAdmin || <div className="tw-flex tw-justify-center tw-rounded-md tw-py-2 tw-ml-2 tw-cursor-pointer md:hover:tw-bg-red-600/25" onClick={() => { setActiveTab("registerCoach"); toggleDropdown(); }} >Become A Coach</div>}
            <div className="tw-flex tw-justify-center tw-rounded-md tw-p-1 tw-ml-2 tw-md-2 tw-cursor-pointer md:hover:tw-bg-red-600/25" onClick={onClickSignOut}>Log Out</div>
          </div>
        </div>
      )}
    </div>
  )
}
