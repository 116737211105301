import {useEffect, useState} from "react";
import {useBookingsOfCoach} from "./use-bookings";
import {BookingStatus} from "../definitions/booking-status";
import {DateTime, Interval} from "luxon";
import {BookingMode} from "../definitions/booking-mode";

export function useIsCoachBusy(coachId: string | null | undefined): { isCoachBusy: boolean; } {
    const {allBookings} = useBookingsOfCoach(coachId, [BookingStatus.Accepted, BookingStatus.Complete]);
    const [isCoachBusy, setIsCoachBusy] = useState<boolean>(false);

    useEffect(() => {
        if (allBookings) {
            const thresholdInterval = Interval.fromDateTimes(DateTime.now(), DateTime.now().plus({hour: 1}));
            const hasCloseBookings = allBookings.filter(booking => !!booking.hours).some(booking => {
                const isScheduled = booking.bookingMode === BookingMode.Scheduled;
                if (isScheduled && booking.booking) {
                    const interval = Interval.fromDateTimes(booking.booking.start!, booking.booking.end!);
                    return !!interval.intersection(thresholdInterval);
                } else {
                    const interval = Interval.fromDateTimes(booking.created.toDate(), DateTime.fromMillis(booking.created.toMillis()).plus({hour: booking.hours}));
                    return !!interval.intersection(thresholdInterval);
                }
            })
            setIsCoachBusy(hasCloseBookings);
        }
    }, [allBookings])
    return {
        isCoachBusy,
    }
}
