import React, {FC} from "react";
import {Button, Container, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {ResetPasswordPayload} from "../../schemas/user-schema";
import './ResetPasswordPage.scss';
import {useForm} from "react-hook-form";
import {useStoreActions} from "../../models";
import {FieldError} from "../../components/form/FieldError";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useBusy} from "../../hooks/use-busy";
import {routes} from "../../App";
import { Helmet } from "react-helmet";

export const ResetPasswordPage: FC = () => {
    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm<ResetPasswordPayload>();
    const {resetPassword} = useStoreActions((actions) => actions.users);
    const {isBusy, setIsBusy} = useBusy();
    const uiStoreActions = useStoreActions(state => state.ui);

    const onResetPassword = async (resetPasswordPayload: ResetPasswordPayload) => {
        setIsBusy(true);
        const success = await resetPassword(resetPasswordPayload);
        setIsBusy(false);
        await uiStoreActions.addMessageAlert({
            title: success ? 'Recovery email sent' : 'Failed to send email',
            subtitle: success ? 'Please check your email inbox for instructions' : 'Please try again later.',
        });
        if (success) navigate(routes.login);
    };

    return (
        <div className={'page reset-password-page'}>
            <Helmet>
                <title>FPS Lounge | Reset Password</title>
            </Helmet>
            <BusyOverlay isBusy={isBusy}>
                <div className="py-md-5 reset-password">
                    <Container className="py-md-5 pt-5 pt-xs-0">
                        <div className="reset-password-outline px-5">
                            <div className="reset-password-content">
                                <div className="reset-password-heading primary-color pt-4">Reset Password</div>
                                <div className={"create-account py-2"}>Don’t you have a account? <a
                                    href="/register">Create one</a></div>
                                <p className={'py-3'}>Please enter your email address, we will email you with a recovery
                                    link where you can
                                    create a new password.</p>
                                <div className="reset-password-fields-main">
                                    <Form onSubmit={handleSubmit(onResetPassword)}>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Control type="email"
                                                          placeholder="Email"
                                                          {...register("email")}/>
                                            <FieldError message={errors.email?.message}/>
                                        </Form.Group>
                                        <div className="reset-password-btn-main-section pt-4 pb-5">
                                            <div className={"reset-password-btn-main"}>
                                                <Button variant={"dark"} type={"submit"}
                                                        className={"reset-password-btn"}>Reset Password</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                        </div>
                    </Container>
                </div>
            </BusyOverlay>
        </div>
    );
};
