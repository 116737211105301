import { useState, useEffect, useRef } from "react";
import { AdminOverviewTab } from "./AdminOverviewTab";
import { AdminCoachApprovalTab } from "./AdminCoachApprovalTab";
import { AdminGamesTab } from "./AdminGamesTab";
import { AdminLabTab } from "./AdminLabTab";
import { AdminCoachesTab } from "./AdminCoachesTab";
import { AdminAccessTab } from "./AdminAccessTab";
import { AdminGainsTab } from "../coach/AdminGainsTab";
import { AdminPromotionsTab } from "../coach/AdminPromotionsTab";
import { AdminExportTab } from "./AdminExportTab";
import useCookie from "react-use-cookie";
import { Helmet } from "react-helmet";
import {
  MdOutlineFreeCancellation,
  MdScience,
  MdUploadFile,
  MdVideogameAsset,
  MdAdminPanelSettings,
  MdOutlineDiscount,
  MdOutlineReceiptLong,
  MdOutlineHowToReg,
  MdOutlineHome,
} from "react-icons/md";
import { AlgoliaCoachesPage } from "../common/AlgoliaCoachesPage";
import { useSystemConfig } from "../../hooks/use-system-config";
import { NotificationsPage } from "../common/NotificationsPage";
import { ChatPage } from "../common/ChatPage";
import "./AdminPage.scss";
import { SettingsTab } from "../common/SettingsTab";
import { TopNavbar } from "../../components/TopNavbar";

export const AdminDashboardPage = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [activeTab, setActiveTab] = useCookie("activeTab", "first");
  const { systemConfig } = useSystemConfig();
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const getTabClassName = (tabName: string) => {
    return `tw-rounded-md tw-text-white tw-text-base tw-p-2 tw-m-2.5 tw-cursor-pointer md:hover:tw-bg-red-600/25 ${activeTab === tabName ? "tw-bg-red-600/50 tw-font-semibold" : ""}`;
  };

  const handleOutsideClick = (ref: any, callback: any) => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  };

  useEffect(() => {
    handleOutsideClick(sidebarRef, setSidebarVisible);
  }, [sidebarRef]);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <div>
      <Helmet>
        <title>FPS Lounge | Admin Dashboard</title>
      </Helmet>
      <TopNavbar
        setActiveTab={setActiveTab}
        sidebarVisible={sidebarVisible}
        toggleSidebar={toggleSidebar}
      />

      <div className="tw-pt-16 admin-page">
        {/* Sidebar */}
        <div className="tw-flex">
          <div
            ref={sidebarRef}
            className={`tw-fixed tw-flex tw-flex-col tw-justify-between md:tw-w-1/5 tw-w-4/5 tw-h-fill tw-bg-[#282F35] tw-z-50 tw-transform tw-transition-transform tw-duration-500 tw-ease-in-out ${sidebarVisible ? "tw-translate-x-0" : "-tw-translate-x-full"}`}
          >
            {/* Above Sidebar */}
            <div className="tw-flex tw-flex-col ">
              <div
                className={getTabClassName("first")}
                onClick={() => {
                  setActiveTab("first");
                  toggleSidebar();
                }}
              >
                <MdOutlineHome className="" size={29} /> &nbsp;&nbsp; Dashboard
              </div>
              <div
                className={getTabClassName("second")}
                onClick={() => {
                  setActiveTab("second");
                  toggleSidebar();
                }}
              >
                <MdOutlineHowToReg className="" size={29} /> &nbsp;&nbsp; Coach
                Approvals
              </div>
              <div
                className={getTabClassName("fourth")}
                onClick={() => {
                  setActiveTab("fourth");
                  toggleSidebar();
                }}
              >
                <MdOutlineReceiptLong className="" size={29} /> &nbsp;&nbsp; All
                Transactions
              </div>
              <div
                className={getTabClassName("fifth")}
                onClick={() => {
                  setActiveTab("fifth");
                  toggleSidebar();
                }}
              >
                <MdOutlineDiscount className="" size={29} /> &nbsp;&nbsp;
                Promotions
              </div>
              <div
                className={getTabClassName("sixth")}
                onClick={() => {
                  setActiveTab("sixth");
                  toggleSidebar();
                }}
              >
                <MdAdminPanelSettings className="" size={29} /> &nbsp;&nbsp;
                Admin Access
              </div>
              <div
                className={getTabClassName("ninth")}
                onClick={() => {
                  setActiveTab("ninth");
                  toggleSidebar();
                }}
              >
                <MdUploadFile className="" size={29} /> &nbsp;&nbsp; Export
              </div>
              <div
                className={getTabClassName("tenth")}
                onClick={() => {
                  setActiveTab("tenth");
                  toggleSidebar();
                }}
              >
                <MdScience className="" size={29} /> &nbsp;&nbsp; Lab
              </div>
              <div className="tw-text-sm tw-font-bold tw-ml-2.5">EXPLORE</div>
              <div
                className={getTabClassName("third")}
                onClick={() => {
                  setActiveTab("third");
                  toggleSidebar();
                }}
              >
                <MdOutlineFreeCancellation className="" size={29} />{" "}
                &nbsp;&nbsp; Coaches
              </div>
              <div
                className={getTabClassName("seventh")}
                onClick={() => {
                  setActiveTab("seventh");
                  toggleSidebar();
                }}
              >
                <MdVideogameAsset className="" size={29} /> &nbsp;&nbsp; Games
              </div>
            </div>
            {/* Below Sidebar */}
            <div className="tw-flex tw-flex-col tw-mb-2">
              <div className="tw-bg-gray-100/75 tw-h-[0.25px] tw-mb-3.5 tw-mx-3 "></div>
              <div className="tw-text-sm tw-font-bold tw-ml-2.5">
                {" "}
                &nbsp;&nbsp; HOUSE BALANCE
              </div>
              <div className="tw-text-lg tw-font-bold tw-text-white tw-ml-2.5">
                {" "}
                &nbsp;&nbsp; ${systemConfig?.balance ?? "-"}
              </div>
            </div>
          </div>
          {/* Main Content */}
          <div
            className={`tw-flex tw-flex-col tw-w-full md:tw-mx-36 tw-transition-all tw-duration-500 tw-ease-in-out tw-m-3 ${sidebarVisible ? "md:tw-ml-96" : ""}`}
          >
            {activeTab === "first" && (
              <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full">
                <div className="tw-m-3">
                  <AdminOverviewTab />
                </div>
              </div>
            )}
            <div className="tw-flex tw-flex-row tw-w-full">
              {activeTab === "second" && <AdminCoachApprovalTab />}
              {activeTab === "third" && <AdminCoachesTab />}
              {activeTab === "fourth" && <AdminGainsTab />}
              {activeTab === "fifth" && <AdminPromotionsTab />}
              {activeTab === "sixth" && <AdminAccessTab />}
              {activeTab === "seventh" && <AdminGamesTab />}
              {activeTab === "eight" && <AlgoliaCoachesPage />}
              {activeTab === "ninth" && <AdminExportTab />}
              {activeTab === "tenth" && <AdminLabTab />}
              {activeTab === "notifications" && <NotificationsPage />}
            </div>
            {activeTab === "messages" && (
              <div className="tw-flex tw-flex-col tw-w-full">
                <ChatPage />
              </div>
            )}
            {activeTab === "settings" && (
              <div className="tw-flex tw-flex-col tw-w-full">
                <SettingsTab />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
