import React, {useEffect, useState} from "react";
import {Button, Container, Stack} from "react-bootstrap";
import {useCurrentUser} from "../../hooks/use-current-user";
import {useNavigate} from "react-router-dom";
import {useStoreActions} from "../../models";
import {CalendarEvent} from "../../definitions/calendar-event";
import {AvailabilityCalendar} from "../../components/calendar/AvailabilityCalendar";
import {SlotInfo} from "react-big-calendar";
import {v4 as uuid} from "uuid";
import {TimeUtils} from "../../utils/time-utils";
import {useBusy} from "../../hooks/use-busy";
import {BusyOverlay} from "../../components/BusyOverlay";
import {UserSchemaExt} from "../../schemas/user-schema";
import { Helmet } from "react-helmet";

export function CoachEditAvailabilityPage() {
    const {user, authUser, userLoading} = useCurrentUser();
    const navigate = useNavigate();
    const bookingStoreActions = useStoreActions((actions) => actions.booking);
    const uiStoreActions = useStoreActions((actions) => actions.ui);
    const {isBusy, setIsBusy} = useBusy();
    const [availabilityEvents, setAvailabilityEvents] = useState<CalendarEvent[]>([])

    useEffect(() => {
        setAvailabilityEvents(UserSchemaExt.availabilityDateRanges(user))
    }, [user])


    const onSubmit = async () => {
        if (!user?.uid) return;
        setIsBusy(true);
        const coachTimezone = UserSchemaExt.getTimezone(user);
        const success = await bookingStoreActions.setCoachAvailability({
            coachId: user.uid,
            timezone: coachTimezone.name,
            availabilityRanges: availabilityEvents
                .filter(event => event.start && event.end)
                .map(event => {
                    const startSlotId = TimeUtils.dateToWeeklyHalfHourId(TimeUtils.dateOfTz(event.start!.valueOf(), coachTimezone));
                    const endSlotId = TimeUtils.dateToWeeklyHalfHourId(TimeUtils.dateOfTz(event.end!.valueOf(), coachTimezone));
                    return [startSlotId, endSlotId];
                }),
        });
        setIsBusy(false);
        if (!success) return;
        navigate(-1);
    };

    function onDiscard() {
        navigate(-1);
    }

    function onClearAll() {
        setAvailabilityEvents([])
    }

    const onEventAdd = (params: SlotInfo) => {
        const newEvent: CalendarEvent = {
            id: uuid(),
            start: params.start,
            end: params.end,
        };
        setAvailabilityEvents([
            ...availabilityEvents,
            newEvent,
        ])
    }

    const onEventClick = async (event: CalendarEvent) => {
        const should = await uiStoreActions.addConfirmationAlert(
            {message: 'Are you sure you want to remove the availability marker?'}
        )
        if (!should) return;
        setAvailabilityEvents(availabilityEvents.filter(ev => ev.id !== event.id))
    }

    return <BusyOverlay isBusy={isBusy}>
        <Helmet>
            <title>FPS Lounge | Edit Availability</title>
        </Helmet>
        <Container className={'page coach-edit-availability'}>
            <div className="dashboard-box-outline">
                <div className="p-5">
                    <h1 className={"primary-color"}>Edit My Weekly Availability</h1>
                    {user?.timezone && <AvailabilityCalendar
                        timezone={UserSchemaExt.getTimezone(user)}
                        events={availabilityEvents}
                        onAddEvent={onEventAdd}
                        onClickEvent={onEventClick}
                    />
                    }
                    <div className={"mt-5"}>
                        <Stack direction={"horizontal"} gap={2} className={'mt-4'}>
                            <Button variant="primary" onClick={() => onSubmit()}
                                    className={"dashboard-primary-btn w-100"}>
                                Save
                            </Button>
                            <Button variant="outline-primary" className={"dashboard-primary-outline-btn w-100 mx-5"}
                                    onClick={() => onClearAll()}>
                                Clear All
                            </Button>
                            <Button variant="outline-primary" className={"dashboard-primary-outline-btn w-100"}
                                    onClick={() => onDiscard()}>
                                Cancel
                            </Button>
                        </Stack>
                    </div>
                </div>
            </div>
        </Container>
    </BusyOverlay>;
}
