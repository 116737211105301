import {NavLink, useNavigate} from "react-router-dom";
import {AlgoliaUserSchema, UserSchemaExt} from "../schemas/user-schema";
import {Button} from "react-bootstrap";
import {routes} from "../App";
import {OnlineIndicator} from "./OnlineIndicator";
import './CoachHit.scss';
import {StarFill} from "react-bootstrap-icons";
import {GameSchema} from "../schemas/game-schema";
import { ListFlags } from "./Flag";

type Props = { hit: any, sendEvent: any, gameData: GameSchema | null };

export function CoachHit(props: Props) {
    const navigate = useNavigate();
    const user = props.hit as AlgoliaUserSchema;

    return (
        <div className={'coach-hit'}>
            <div className={`${UserSchemaExt.isOnlineAlgolia(user) ? 'coach-card-online' : 'coach-card-offline'}`}>

                <div className="coach-card-outline">
                    <NavLink className="coach-card-profile-click"
                         to={routes.coachProfile.replace(':username', user.permalink!)} />
                    <div className="coach-card-img"><img
                        src={user.profileImage ?? props.gameData?.imageUrl ?? '/assets/placeholders/game-placeholder.jpg'}
                        alt=""/></div>
                    <div className="game-name">{props.gameData?.name?.toUpperCase() ?? '...'}</div>

                    <div className={"coach-card-content"}>
                        <div className="coach-name-main">

                            <div className="coach-name">
                                <div>{user.gamerTag || user.name}</div>
                            </div>
                            <div className="coach-language">
                                <div className={'d-flex'} style={{flexWrap: "wrap"}}>
                                    <ListFlags user={user} />
                                </div>
                            </div>
                            <div className={"online-indicator"}><OnlineIndicator
                                isOnline={UserSchemaExt.isOnlineAlgolia(user)}/></div>
                        </div>

                        <div className="coach-rating py-2"><StarFill color="#EE2337"/>&nbsp;&nbsp;<StarFill
                            color="#EE2337"/>&nbsp;&nbsp;<StarFill color="#EE2337"/>&nbsp;&nbsp;<StarFill
                            color="#EE2337"/>&nbsp;&nbsp;<StarFill color="#EE2337"/></div>


                        <div className="coach-details ">
                            <div className="coach-point">
                                {user.mainPoints?.length && <div className="coach-detail-row">
                                    <ul>
                                        {user.mainPoints?.map((point) => <li className={"pb-2"}
                                                                             key={point.id}>{point.label.slice(0, 28) + " ..."}</li>)}
                                    </ul>
                                </div>}
                                {!user.mainPoints?.length && <div className="coach-detail-row">
                                    <div>{user.bio || 'No bio provided'}</div>
                                </div>}
                            </div>

                        </div>

                        <div className="coach-card-btn-main pt-3">
                            {user && <>
                                <Button className={"dashboard-secondary-btn"}
                                        onClick={() => navigate(routes.bookSession.replace(':username', user.uid ?? ''))}>Book
                                    Now</Button>
                            </>}
                            <div className={"coach-price"}>{UserSchemaExt.hourlyRateStringAlgolia(user)}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>);
}
