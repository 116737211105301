import React, {useEffect, useState} from "react";
import {Button, Col, Container, Form, Row, Stack} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {useStoreActions} from "../../models";
import "./AdminAddDiscountPage.scss";
import {FieldError} from "../../components/form/FieldError";
import {useNavigate, useParams} from "react-router-dom";
import {DiscountPayload} from "../../schemas/discount-schema";
import {useBusy} from "../../hooks/use-busy";
import {BusyOverlay} from "../../components/BusyOverlay";
import {useDiscountOnce} from "../../hooks/use-discount";
import {DateTime} from "luxon";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";


export default function AdminAddDiscountPage() {
    const {discountId} = useParams();
    const {discount, discountLoading, discountError} = useDiscountOnce(discountId);
    const navigate = useNavigate();
    const economyStoreAction = useStoreActions((actions) => actions.economy);
    const [isFormInit, setIsFormInit] = useState(false);
    const {isBusy, setIsBusy} = useBusy();
    const uiStoreActions = useStoreActions(state => state.ui);

    const {
        register,
        control,
        handleSubmit,
        reset,
        watch,
        formState,
        setValue
    } = useForm<DiscountPayload>();

    useEffect(() => {
        if (!discount || isFormInit) return;
        setValue('name', discount.name);
        setValue('percentage', discount.percentage);
        setValue('startDate', discount.startDate.toDate());
        setValue('endDate', discount.endDate.toDate());
        setIsFormInit(true);
    }, [discount])

    const onSubmit: SubmitHandler<DiscountPayload> = async (payload) => {
        console.log(payload)
        setIsBusy(true);
        if (!payload.startDate || !payload.endDate || (DateTime.fromJSDate(payload.startDate) >= DateTime.fromJSDate(payload.endDate))) {
            setIsBusy(false);
            await uiStoreActions.addMessageAlert({
                title: 'Cannot create new discount',
                subtitle: 'Invalid dates selected'
            });
            return;
        }
        const success = await economyStoreAction.addDiscount({
            ...payload,
            updateId: discountId ?? null
        });
        setIsBusy(false);
        if (!success) return;
        navigate(-1);
    };

    async function onDiscard(): Promise<void> {
        navigate(-1);
    }

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    return (
        <div className={'bg-main-color'}>
            <Container className={"page app-home-page"}>
                <BusyOverlay isBusy={isBusy}>
                    <Row>
                        <Form onSubmit={handleSubmit(onSubmit)} className={"d-flex"}>
                            <Col className={"me-4"}>
                                <div className={'p-4'}>
                                    <h1 className="text-center primary-color pb-5">Create New Discount</h1>
                                    <Row className={"dashboard-box-outline"}>
                                        <Col className={"p-5"}>
                                            <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Discount Name
                                                </Form.Label>
                                                <Form.Control
                                                    placeholder="Enter name of the discount."
                                                    {...register("name", {
                                                        required: "Field is required",
                                                        maxLength: 40,
                                                    })}
                                                />
                                                <FieldError message={formState.errors.name?.message}/>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Discount Percentage (%)
                                                </Form.Label>
                                                <Form.Control
                                                    placeholder="Enter discount percentage"
                                                    type={'number'}
                                                    step={0.01}
                                                    {...register("percentage", {
                                                        required: "Field is required",
                                                        valueAsNumber: true,
                                                        min: 0,
                                                        max: 100,
                                                    })}
                                                />
                                                <FieldError message={formState.errors.percentage?.message}/>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label style={{textAlign: "left", width: "100%"}}>
                                                    Discount Period
                                                </Form.Label>
                                                <DateTimeRangePicker required={true} onChange={(range) => {
                                                    setValue('startDate', range?.[0] ?? null);
                                                    setValue('endDate', range?.[1] ?? null);
                                                }} value={(startDate && endDate) ? [startDate, endDate] : null}/>
                                            </Form.Group>
                                            <hr/>
                                        </Col>
                                        <div className={"pb-5"}>
                                            <Stack direction={"horizontal"} gap={2}
                                                   className={'mt-5 dashboard-main-btn-row'}>
                                                <Button variant="primary" type="submit"
                                                        className={"dashboard-primary-btn w-100 mx-4"}>
                                                    Save
                                                </Button>
                                                <Button variant="outline-primary"
                                                        className={"w-100 dashboard-primary-outline-btn mx-4"}
                                                        onClick={() => onDiscard()}>
                                                    Cancel
                                                </Button>
                                            </Stack>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                </BusyOverlay>
            </Container>
        </div>
    );
}
