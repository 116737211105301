import '../common/CoachesPage.scss';
import { UserSchemaExt } from "../../schemas/user-schema";
import { NavLink, Navigate, useParams } from "react-router-dom";
import { routes } from "../../App";
import { useCoachByPermalink } from "../../hooks/use-user";
import { SpinnerPadded } from "../../components/SpinnerPadded";
import { useGameOnce } from "../../hooks/use-game";
import { BusyOverlay } from "../../components/BusyOverlay";
import { useTipCoach } from "../../hooks/use-tip-coach";
import { useCurrentUser } from "../../hooks/use-current-user";
import { useCoachPricing } from "../../hooks/use-coach-pricing";
import { CourseList } from "../../components/CourseList";
import { Helmet } from 'react-helmet';
import { PropsWithChildren, useMemo } from 'react';
import { CoachProfileCard } from '../../components/CoachProfileCard';
import { EnvUtils } from "../../utils/env-utils";

const social = {
  youtube: 'https://www.youtube.com',
  twitch: 'https://www.twitch.tv',
  twitter: 'https://www.twitter.com',
  discord: 'https://www.discord.gg',
} as const;

export const SocialLink = ({ to, children, type }: PropsWithChildren<{ to?: string | null, type: keyof typeof social }>) => {
  const formattedUrl = useMemo(() => {
    let temp = to ?? '';
    if (temp.match(/^(youtube\.com|twitch\.tv|twitter\.com)/)) {
      temp = 'https://www.' + to;
    }
    return new URL(temp, social[type])
  }, [type, to])

  if (!to) return null;

  return (
    <a
      className="tw-cursor-pointer tw-text-gray-100 hover:tw-text-red-100 tw-duration-300"
      href={formattedUrl.toString()}
      target="_blank" rel="noopener noreferrer"
      aria-label={type}
    >{children}</a>
  )
};

export const CoachProfilePage = () => {
  let { username } = useParams();
  const { user } = useCurrentUser();
  const { coachData, coachLoading, coachError } = useCoachByPermalink(username);
  const { game } = useGameOnce(coachData?.coachingGame);
  const { isBusyTipCoach, tipCoach } = useTipCoach(coachData?.uid);
  const { pricingPack } = useCoachPricing(coachData, game);

  const rawDisableCoaches = EnvUtils.readString("REACT_APP_DISABLE_COACHES");
  const disableCoaches = rawDisableCoaches.replace(/[\[\]']/g, "").split(',').map(item => item.trim());
  const isCoachDisabled = disableCoaches.includes(coachData?.uid || "");

  const handleBookNowClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isCoachDisabled) {
      e.preventDefault();
    }
  }

  if (!coachLoading && coachError) return <p>{coachError.message}</p>;
  if (!coachLoading && (!coachData || coachData.role !== 'coach')) {
    return <Navigate to={routes.coaches} />
  }

  return (
    <BusyOverlay isBusy={isBusyTipCoach}>
      <Helmet>
        {coachData?.displayName && <title>FPS Lounge | Train with {coachData.displayName}</title>}
      </Helmet>
      <div className="tailwind tw-mt-60">
        <div className="container tw-mx-auto">
          {coachLoading && <SpinnerPadded />}
          {coachData && UserSchemaExt.isCoach(coachData) &&
            <div className="tw-grid tw-grid-cols-6 tw-grid-flow-row tw-gap-4">
              {coachData.displayName !== user?.displayName &&
                <div className="lg:tw-col-start-5 lg:tw-col-end-6 md:tw-col-start-5 md:tw-col-end-6 tw-col-start-1 tw-col-end-3">
                  <NavLink
                    className={`tw-block tw-w-full tw-text-center tw-text-white tw-font-bold tw-border-none tw-outline-none tw-px-3 tw-py-2.5 tw-rounded-md tw-uppercase tw-no-underline tw-cursor-pointer tw-whitespace-nowrap ${isCoachDisabled ? 'tw-bg-gray-500 tw-cursor-not-allowed' : 'tw-bg-red-100'}`}
                    to={routes.bookSession.replace(':username', coachData.uid)}
                    title={isCoachDisabled ? 'This coach is not available for booking' : ''}
                    onClick={handleBookNowClick}
                  >
                    Book Now
                  </NavLink>
                </div>
              }
              {coachData.displayName !== user?.displayName &&
                <div className="lg:tw-col-start-6 lg:tw-col-end-7 md:tw-col-start-6 md:tw-col-end-7 tw-col-start-5 tw-col-end-7">
                  <button
                    className="tw-block tw-w-full tw-text-center tw-bg-red-100 tw-text-white tw-font-bold tw-border-none tw-outline-none 
                      tw-px-4 tw-py-2.5 tw-rounded-md tw-uppercase tw-no-underline tw-cursor-pointer"
                    onClick={() => tipCoach()}
                  >
                    Tip
                  </button>
                </div>
              }

              <CoachProfileCard coachData={coachData} />

              <div className="tw-relative tw-flex tw-flex-col lg:tw-col-span-4 md:tw-col-span-3 tw-col-span-6 tw-rounded-md">
                <CourseList coachId={coachData?.uid ?? null} />
                <div className="tw-bg-dp-01 tw-p-6 tw-flex tw-flex-col tw-flex-grow">
                  <section id="about-me" className="tw-flex tw-flex-col tw-mb-3">
                    <h2 className="tw-text-2xl tw-text-red-100 tw-font-bold tw-mb-2">About Me</h2>
                    <p className="tw-text-lg tw-text-white">{coachData.bio || 'No bio provided'}</p>
                  </section>
                  <section className="tw-flex tw-flex-col">
                    <h2 className="tw-sr-only">Main Points</h2>
                    {coachData.mainPoints && (<ul>
                      {coachData.mainPoints?.map(mainPoint =>
                        <li key={mainPoint.id} className="tw-mb-1 tw-text-lg tw-text-white" >
                          {mainPoint.label}
                        </li>
                      )}
                    </ul>)}
                  </section>
                  <div className="tw-grid tw-grid-cols-4 tw-gap-4 tw-h-fit tw-flex-1 tw-content-end">
                    <div className="lg:tw-col-span-1 md:tw-col-span-2 tw-col-span-4 tw-bg-dp-02 tw-p-3 tw-flex tw-justify-between tw-rounded-md tw-shadow-04">
                      <div className="tw-block tw-my-auto">
                        <span className="tw-text-xs tw-text-white tw-font-bold leading-4">Win Rate</span>
                        <h5 className="tw-text-lg tw-text-red-100 tw-mb-0 tw-pb-0">{coachData.winRate ? `${coachData.winRate}%` : '-'}</h5>
                      </div>
                      <img
                        src={require('../../assets/icons/trophy.png')}
                        className="tw-h-2/3 tw-w-auto tw-my-auto"
                        alt="Win Rate Icon"
                      />
                    </div>
                    <div className="lg:tw-col-span-1 md:tw-col-span-2 tw-col-span-4 tw-bg-dp-02 tw-p-3 tw-flex tw-justify-between tw-rounded-md tw-shadow-04">
                      <div className="tw-block tw-my-auto">
                        <span className="tw-text-xs tw-text-white tw-font-bold leading-4">K/D Ratio</span>
                        <h5 className="tw-text-lg tw-text-red-100 tw-mb-0 tw-pb-0">{coachData.kdRatio || '-'}</h5>
                      </div>
                      <img
                        src={require('../../assets/icons/piechart.png')}
                        className="tw-h-2/3 tw-w-auto tw-my-auto"
                        alt="K/D Icon"
                      />
                    </div>
                    <div className="lg:tw-col-span-1 md:tw-col-span-2 tw-col-span-4 tw-bg-dp-02 tw-p-3 tw-flex tw-justify-between tw-rounded-md tw-shadow-04">
                      <div className="tw-block tw-my-auto">
                        <span className="tw-text-xs tw-text-white tw-font-bold leading-4">Total Kills</span>
                        <h5 className="tw-text-lg tw-text-red-100 tw-mb-0 tw-pb-0">{coachData.totalKills || '-'}</h5>
                      </div>
                      <img
                        src={require('../../assets/icons/target.png')}
                        className="tw-h-2/3 tw-w-auto tw-my-auto"
                        alt="Kills Icon"
                      />
                    </div>
                    <div className="lg:tw-col-span-1 md:tw-col-span-2 tw-col-span-4 tw-bg-dp-02 tw-p-3 tw-flex tw-justify-between tw-rounded-md tw-shadow-04">
                      <div className="tw-block tw-my-auto">
                        <span className="tw-text-xs tw-text-white tw-font-bold leading-4">Total Deaths</span>
                        <h5 className="tw-text-lg tw-text-red-100 tw-mb-0 tw-pb-0">{coachData.totalDeaths || '-'}</h5>
                      </div>
                      <img
                        src={require('../../assets/icons/skulls.png')}
                        className="tw-h-2/3 tw-w-auto tw-my-auto"
                        alt="Deaths Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:tw-col-span-2 md:tw-col-span-3 tw-col-span-6 tw-bg-transparent tw-rounded-md tw-p-5 md:tw-order-1 tw-order-2">
              </div>

              <div className="lg:tw-col-span-4 md:tw-col-span-3 tw-col-span-6 md:tw-order-2 tw-order-1">
                <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                  <div className="tw-flex tw-flex-col tw-col-span-2">
                    <h2 id="coaching-packages" className="tw-text-2xl tw-text-red-100 tw-font-bold tw-mb-2">Coaching Packages</h2>
                  </div>

                  <div className="tw-relative tw-flex tw-flex-col lg:tw-col-span-1 tw-col-span-2 tw-bg-dp-01 tw-rounded-md tw-p-5">
                    <img
                      className="tw-object-cover tw-w-full tw-h-auto tw-rounded-md tw-flex tw-mx-auto"
                      src={game?.imageUrl ?? '/assets/placeholders/game-placeholder.jpg'}
                      alt=""
                    />
                    <div className="tw-relative tw-mt-4 tw-flex tw-flex-col tw-flex-1 tw-h-full">
                      <span className="tw-text-xs tw-uppercase tw-text-white tw-font-bold">{game?.name}</span>
                      <h4 className="tw-text-xl tw-font-bold tw-text-white">Live Coaching</h4>
                      <p className="tw-text-gray-100 tw-text-md">
                        Play live alongside your coach and learn from the best players in the game.
                        If your coach is online, you can book them to Play Now, or book a session in the future at a time that works for you!
                      </p>

                      <div className="tw-relative tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-items-end tw-flex-1">
                        <h3 className="tw-text-2xl tw-text-red-100 tw-font-bold">${coachData && (pricingPack?.hourlyRate || "-")}/hr</h3>
                        <NavLink
                          className={`tw-block tw-w-auto tw-text-center tw-text-white tw-font-bold tw-border-none tw-outline-none tw-px-4 tw-py-2.5 tw-rounded-md tw-uppercase tw-no-underline tw-cursor-pointer md:tw-my-0 tw-mt-4 ${isCoachDisabled ? 'tw-bg-gray-500 tw-cursor-not-allowed' : 'tw-bg-red-100'}`}
                          to={routes.bookSession.replace(':username', coachData.uid)}
                          title={isCoachDisabled ? 'This coach is not available for booking' : ''}
                          onClick={handleBookNowClick}
                        >
                          Book Now
                        </NavLink>

                      </div>
                    </div>
                  </div>
                  <div className="tw-relative tw-flex tw-flex-col lg:tw-col-span-1 tw-col-span-2 tw-bg-dp-01 tw-rounded-md tw-p-5">
                    <img
                      className="tw-relative tw-object-cover tw-w-full tw-h-auto tw-rounded-md tw-flex tw-mx-auto"
                      src={game?.imageUrl ?? '/assets/placeholders/game-placeholder.jpg'}
                      alt=""
                    />
                    <div className="tw-relative tw-mt-4 tw-flex tw-flex-col tw-flex-1 tw-h-full">
                      <span className="tw-text-xs tw-uppercase tw-text-white tw-font-bold">{game?.name}</span>
                      <h4 className="tw-text-xl tw-font-bold tw-text-white">VOD Review</h4>
                      <p className="tw-text-gray-100 tw-text-md">
                        {coachData.vodBio ?? <>Submit a VOD of your play, and get a in depth analysis and recommendations back in 24-48 hours.</>}
                      </p>

                      <div className="tw-relative tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-items-end tw-flex-1">
                        <h3 className="tw-text-2xl tw-text-red-100 tw-font-bold">${coachData && (pricingPack?.vodRate || "-")}/hr</h3>
                        <NavLink
                          className={`tw-block tw-w-auto tw-text-center tw-text-white tw-font-bold tw-border-none tw-outline-none tw-px-4 tw-py-2.5 tw-rounded-md tw-uppercase tw-no-underline tw-cursor-pointer md:tw-my-0 tw-mt-4  ${isCoachDisabled ? 'tw-bg-gray-500 tw-cursor-not-allowed' : 'tw-bg-red-100'}`}
                          to={routes.submitVod.replace(':username', coachData.uid)}
                          title={isCoachDisabled ? 'This coach is not available for booking' : ''}
                          onClick={handleBookNowClick} >
                          Submit VOD
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </BusyOverlay>
  );
}
