import { collection, doc } from "firebase/firestore";
import { db } from "../config/firebase-config";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { CourseSchema, courseConverter } from "../schemas/course-schema";

export function useCourse(courseId?: string) {
    const coursesCollectionRef = collection(db, 'courses').withConverter(courseConverter);
    const [course, courseLoading, courseError] = useDocumentData<CourseSchema>(courseId ? doc(coursesCollectionRef, courseId) : null);
    return {
        course,
        courseLoading,
        courseError,
    } as const;
}