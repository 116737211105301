import './SlotAvailabilityView.scss';
import { DateRange, SlotAvailability, TimeUtils } from "../utils/time-utils";
import { Table } from "react-bootstrap";
import _ from "lodash";
import clsx from 'clsx';

type CommonProps = {
    selectedRange: DateRange | null,
    onClickSlot: (slot: SlotAvailability) => void,
}

type Props = {
    availabilityList: SlotAvailability[],
} & CommonProps;

function AvailableRow({ availability, selectedRange, onClickSlot }: { availability: SlotAvailability } & CommonProps) {
    const isSelected = selectedRange && selectedRange[0] <= availability.slot[0] && selectedRange[1] >= availability.slot[1];
    const isBeforeNow = TimeUtils.isBeforeNow(availability.slot[0]);
    const available = availability.isAvailable && !isBeforeNow;
    return (
        <tr
            onClick={() => {
                if (!available) return;
                onClickSlot(availability);
            }}
            className={
                clsx({
                    clickable: available,
                    'tw-bg-gray-250': availability.isAvailable && isBeforeNow,
                    booked: !availability.isAvailable,
                    available: available,
                    selected: isSelected
                })
            }>
            <td>
                {TimeUtils.dateToLocaleWeekdayString(availability.slot[0])} {TimeUtils.dateToLocaleTimeString(availability.slot[0])}
                &nbsp;&nbsp;-&nbsp;&nbsp;
                {TimeUtils.dateToLocaleWeekdayString(availability.slot[1])} {TimeUtils.dateToLocaleTimeString(availability.slot[1])}
            </td>
            <td>
                {available ? <>Available</> : !availability.isAvailable ? <>Booked</> : isBeforeNow ? <>Unavailable</> : <>Unknwown</>}
            </td>
        </tr>
    );
}


export function SlotAvailabilityView({ availabilityList, selectedRange, onClickSlot }: Props) {
    console.log(availabilityList);
    return (
        <div className="slot-availability-view">
            {_.isEmpty(availabilityList.filter(availability => availability.isAvailable)) &&
                <p className="py-4">No available slots on this day.</p>}
            <Table bordered border={2} className="availability-table table-border-color-light">
                <tbody>
                    {_(availabilityList).reject((object, i) => (
                        i > 0
                        && availabilityList[i - 1].isAvailable === object.isAvailable
                        && !object.isAvailable
                    )).map((availability) => {
                        return <AvailableRow key={JSON.stringify(availability.slot)} availability={availability} selectedRange={selectedRange} onClickSlot={onClickSlot} />
                    }).value()}
                </tbody>
            </Table>
        </div>
    );
};
