import * as React from 'react';
import './OnlineIndicator.scss';

type Props = {
    isOnline: boolean,
    isCoachBusy?: boolean,
};

export function OnlineIndicator(props: Props) {
    return (
        <div className={`ring-container ${props.isOnline ? 'online' : 'offline'} ${props.isCoachBusy ? 'busy' : ''}`}>
            <div className="circle">
                <div className="ringring"></div>
            </div>
        </div>);
}
