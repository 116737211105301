import { FirestoreDataConverter } from "firebase/firestore";

export interface SettingsSchema {
  sms: {
    bookings: {
      instant: boolean,
      scheduled: boolean,
    },
    status: {
      accept: boolean,
    }
  }
}

export const settingsConverter: FirestoreDataConverter<SettingsSchema> = {
  toFirestore(post) {
    return post;
  },
  fromFirestore(snapshot, options) {
    return { ...snapshot.data(options) } as SettingsSchema;
  },
};
