import {useEffect, useState} from "react";
import {DateRangeList, TimeUtils} from "../utils/time-utils";
import {UserSchema, UserSchemaExt} from "../schemas/user-schema";

export function useCoachAvailability(coachData ?: UserSchema, sort: boolean = false): { availabilityEvents: DateRangeList } {
    const [availabilityEvents, setAvailabilityEvents] = useState<DateRangeList>([]);

    useEffect(() => {
        if (!coachData?.availabilityRanges) return;

        const availabilityEvents: DateRangeList = coachData?.availabilityRanges?.map(event => {
            return [
                TimeUtils.getStartOfWeek(TimeUtils.nowTz(UserSchemaExt.getTimezone(coachData))).plus(TimeUtils.weeklyHalfHourIdToOffset(event[0])),
                TimeUtils.getStartOfWeek(TimeUtils.nowTz(UserSchemaExt.getTimezone(coachData))).plus(TimeUtils.weeklyHalfHourIdToOffset(event[1], true))];
        }) ?? [];

        if (sort) {
          availabilityEvents.sort((a, b) => a[0].weekday - b[0].weekday);
        }

        setAvailabilityEvents(availabilityEvents);
    }, [coachData?.availabilityRanges])

    return {
        availabilityEvents,
    }
}
