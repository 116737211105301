import { CoachOverviewTab } from "./CoachOverviewTab";
import { CoachBookingsTab } from "./CoachBookingsTab";
import { CoachCalendarTab } from "./CoachCalendarTab";
import { CoachPackagesTab } from "./CoachPackagesTab";
import { CoachEarningsTab } from "./CoachEarningsTab";
import { NotificationsPage } from "../common/NotificationsPage";
import useCookie from "react-use-cookie";
import { ChatPage } from "../common/ChatPage";
import { CoachVodTab } from "./CoachVodTab";
import { useCurrentUser } from "../../hooks/use-current-user";
import { CoachWithdrawalsTab } from "./CoachWithdrawalsTab";
import { useWithdrawFunds } from "../../hooks/use-withdraw-funds";
import { BusyOverlay } from "../../components/BusyOverlay";
import { Helmet } from "react-helmet";
import { MdOutlineAttachMoney, MdOutlineHome, MdPlayCircleOutline, MdOutlineCalendarToday, MdPersonSearch, MdStorefront, MdOutlineFreeCancellation, MdOutlineRequestQuote } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import { AlgoliaCoachesPage } from "../common/AlgoliaCoachesPage";
import { useBusy } from "../../hooks/use-busy";
import { SettingsTab } from "../common/SettingsTab";
import { ReferralHeroData } from "../../components/ReferralHeroData";
import { TopNavbar } from "../../components/TopNavbar";

export const CoachDashboardPage = () => {
  const { user } = useCurrentUser();
  const { isBusyWithdrawFunds, withdrawFunds } = useWithdrawFunds();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [activeTab, setActiveTab] = useCookie("activeTab", "first");
  const { isBusy } = useBusy();
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const getTabClassName = (tabName: string) => {
    return `tw-rounded-md tw-text-white tw-text-base tw-p-2 tw-m-2.5 tw-cursor-pointer md:hover:tw-bg-red-600/25 ${activeTab === tabName ? "tw-bg-red-600/50 tw-font-semibold" : ""}`;
  };

  const handleOutsideClick = (ref: any, callback: any) => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  useEffect(() => {
    handleOutsideClick(sidebarRef, setSidebarVisible);
  }, [sidebarRef]);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (<BusyOverlay isBusy={isBusyWithdrawFunds || isBusy}>
    <div>
      <Helmet>
        <title>FPS Lounge | Coach Dashboard</title>
        <ReferralHeroData />
      </Helmet>
      <TopNavbar setActiveTab={setActiveTab} sidebarVisible={sidebarVisible} toggleSidebar={toggleSidebar} />

      <div className="tw-pt-16">
        {/* Sidebar */}
        <div className="tw-flex">
          <div ref={sidebarRef} className={`tw-fixed tw-flex tw-flex-col tw-justify-between md:tw-w-1/5 tw-w-4/5 tw-h-fill tw-bg-[#282F35] tw-transform tw-transition-transform tw-duration-500 tw-ease-in-out ${sidebarVisible ? 'tw-translate-x-0' : '-tw-translate-x-full'}`} style={{ zIndex: 501 }}>
            {/* Above Sidebar */}
            <div className="tw-flex tw-flex-col ">
              <div className={getTabClassName("first")} onClick={() => { setActiveTab("first"); toggleSidebar(); }}>
                <MdOutlineHome className="" size={29} /> &nbsp;&nbsp; Dashboard
              </div>
              <div className={getTabClassName("second")} onClick={() => { setActiveTab("second"); toggleSidebar(); }}>
                <MdOutlineCalendarToday className="" size={29} /> &nbsp;&nbsp; My Calendar
              </div>
              <div className={getTabClassName("third")} onClick={() => { setActiveTab("third"); toggleSidebar(); }}>
                <MdOutlineFreeCancellation className="" size={29} /> &nbsp;&nbsp; Booking Requests
              </div>
              <div className={getTabClassName("fourth")} onClick={() => { setActiveTab("fourth"); toggleSidebar(); }}>
                <MdPlayCircleOutline className="" size={29} /> &nbsp;&nbsp; VOD Requests
              </div>
              <div className={getTabClassName("fifth")} onClick={() => { setActiveTab("fifth"); toggleSidebar(); }}>
                <MdOutlineRequestQuote className="" size={29} /> &nbsp;&nbsp; My Earnings
              </div>
              <div className={getTabClassName("sixth")} onClick={() => { setActiveTab("sixth"); toggleSidebar(); }}>
                <MdOutlineAttachMoney className="" size={29} /> &nbsp;&nbsp; My Withdrawals
              </div>
              <div className={getTabClassName("seventh")} onClick={() => { setActiveTab("seventh"); toggleSidebar(); }}>
                <MdStorefront className="" size={29} /> &nbsp;&nbsp; My Packages
              </div>
              <div className="tw-text-sm tw-font-bold tw-ml-2.5">
                EXPLORE
              </div>
              <div className={getTabClassName("eight")} onClick={() => { setActiveTab("eight"); toggleSidebar(); }}>
                <MdPersonSearch className="" size={29} /> &nbsp;&nbsp; Coaches
              </div>
            </div>
            {/* Below Sidebar */}
            <div className="tw-flex tw-flex-col">
              <div className="tw-bg-gray-100/75 tw-h-[0.25px] tw-mb-3.5 tw-mx-3 "></div>
              <div className="tw-text-sm tw-font-bold tw-ml-2.5"> &nbsp;&nbsp;BALANCE</div>
              <div className="tw-text-lg tw-font-bold tw-text-white tw-ml-2.5"> &nbsp;&nbsp;${user?.balance ?? "-"}</div>
              <button onClick={withdrawFunds} className={`tw-rounded-md tw-text-white tw-text-base tw-font-semibold tw-p-3.5 tw-m-2.5 ${user && user.balance && user.balance < 50 ? "tw-bg-gray-400" : "tw-bg-red-600/75"}`} > WITHDRAW</button>
            </div>
          </div>
          {/* Main Content */}
          <div className={`tw-flex tw-flex-row tw-w-full md:tw-mx-36 tw-m-4 tw-transition-all tw-duration-500 tw-ease-in-out ${sidebarVisible ? 'md:tw-ml-96' : ''}`}>
            {activeTab === "first" && (
              <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full">
                <div className="tw-m-3">
                  <CoachOverviewTab />
                </div>
                <div className="tw-m-5">
                  <div className="tw-pt-20" id="referralhero-sharing-widget"></div>
                </div>
              </div>
            )}
            {activeTab === "second" && (
              <div className="tw-flex tw-flex-col tw-w-full">
                <CoachCalendarTab />
              </div>
            )}
            {activeTab === "third" && (
              <div className="tw-flex tw-flex-col tw-w-full">
                <CoachBookingsTab />
              </div>)}
            {activeTab === "fourth" && <CoachVodTab />}
            {activeTab === "fifth" && <CoachEarningsTab />}
            {activeTab === "sixth" && <CoachWithdrawalsTab />}
            {activeTab === "seventh" && (
              <div className="tw-flex tw-flex-col md:tw-w-4/12">
                <CoachPackagesTab />
              </div>
            )}
            {activeTab === "eight" && <AlgoliaCoachesPage />}
            {activeTab === "notifications" && <NotificationsPage />}
            {activeTab === "messages" && <ChatPage />}
            {activeTab === "settings" && (
              <div className="tw-flex tw-flex-col tw-w-full">
                <SettingsTab />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

  </BusyOverlay>)
}
