import {userConverter, UserSchema} from "../schemas/user-schema";
import {db} from "../config/firebase-config";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import {collection, doc, FirestoreError, query, where} from "firebase/firestore";
import _ from "lodash";

export function useUser(uid: string | undefined): {
    userError: FirestoreError | undefined;
    userData: UserSchema | undefined;
    userLoading: boolean;
} {
    const coachDocRef = uid ? doc(collection(db, 'users'), uid).withConverter(userConverter) : undefined;
    const [userData, userLoading, userError] = useDocumentData<UserSchema>(coachDocRef);
    return {
        userData,
        userLoading,
        userError,
    };
}

export function useCoachByPermalink(permalink: string | null | undefined): {
    coachError: FirestoreError | undefined;
    coachData: UserSchema | undefined;
    coachLoading: boolean;
} {
    const coachCollectionRef = collection(db, 'users').withConverter(userConverter);
    const coachFilters = permalink ? [where("permalink", "==", permalink)] : [];
    const coachQuery = query<UserSchema>(coachCollectionRef, ...coachFilters);
    const [coachDataList, coachLoading, coachError] = useCollectionData<UserSchema>(coachQuery);
    const coachData = _.first(coachDataList ?? []);
    return {
        coachData,
        coachLoading,
        coachError,
    };
}
