import {UserModel, users} from "./user-model";
import {ui, UiModel} from "./ui-model";
import {games, GamesModel} from "./games-model";
import {booking, BookingModel} from "./booking-model";
import {createStore, createTypedHooks, persist} from "easy-peasy";
import {economy, EconomyModel} from "./economy-model";
import {cart, CartModel} from "./cart-model";

const typedHooks = createTypedHooks<JointStoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export interface JointStoreModel {
    users: UserModel;
    ui: UiModel;
    games: GamesModel;
    booking: BookingModel;
    economy: EconomyModel;
    cart: CartModel;
}

export const store = createStore<JointStoreModel>(
    {
        users,
        ui,
        games,
        booking,
        economy,
        cart: persist(cart),
    }
);
