import "./PricingPage.scss"
import { Button, Container } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { BusyOverlay } from "../../components/BusyOverlay";
import { useAddFunds } from "../../hooks/use-add-funds";
import { Helmet } from "react-helmet";
import { EnvUtils } from "../../utils/env-utils";

export function PricingPage() {
  const { addFunds, isBusyAddFunds } = useAddFunds();

  return (
    <div className={"page pricing-page"}>
      <Helmet>
        <title>FPS Lounge | Pricing</title>
        <meta name="description" content="Starting at $16/hour" />
        <script type="text/javascript">
          {`!function(m,a,i,t,r,e){if(m.RH)return;r=m.RH={},r.uuid=t,r.loaded=0,r.base_url=i,r.queue=[],m.rht=function(){r.queue.push(arguments)};e=a.getElementsByTagName('script')[0],c=a.createElement('script');c.async=!0,c.src=i+'/widget/'+t+'.js',e.parentNode.insertBefore(c,e),console.log('ReferralHero script loaded successfully');}(window,document,'https://app.referralhero.com', '${EnvUtils.readString("REACT_APP_REFERRALHERO_ID")}');`}
        </script>
      </Helmet>
      <BusyOverlay isBusy={isBusyAddFunds}>
        <Container className={"py-5"}>

          <div className={"standard-pricing pb-5"}>
            <h1 className={"primary-color text-center"}>Standard Pricing</h1>
            <div className="pricing-sub-heading text-center pb-5">for Apex, Valorant, Fortnite, CoD Warzone
            </div>
            <div className="box-outline my-5">
              <div className="standard-pricing-img">
                <img src="/assets/pricing/cod-apex.png" width={400} alt="" />
              </div>
              <div className="p-5 ">
                <div className="standard-pricing-content">
                  <span className="price-small-text secondary-color">Starting At</span>
                  <h1>
                    $16 <span className="price-small-text secondary-color"> / Hour for Pro Coaching</span>
                  </h1>
                  <div className="pt-5">
                    <div><Check2Circle color={"#05B78C"} />&nbsp;&nbsp; Game with the World's Best
                      Players.
                    </div>
                    <div className={"py-3"}><Check2Circle color={"#05B78C"} />&nbsp;&nbsp; Increase
                      Your
                      Win Rate and Improve Stats.
                    </div>
                    <div><Check2Circle color={"#05B78C"} />&nbsp;&nbsp; Enjoy great vibes.</div>
                  </div>

                  <div className={"pt-5"}>
                    <Button className={"FPS-primary-button"} onClick={() => addFunds()}>Add
                      Funds</Button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/*
                <div className="apex-package pb-5">
                    <h1 className={"primary-color text-center pt-5"}>GAME PACKAGES</h1>
                    <div className="pricing-sub-heading text-center pb-4">Let our coaches take you to the next level. We
                        guarantee they will help you develop the skills you need to achieve the next rank.
                    </div>
                </div>
                <div className="apex-box-main pb-5">
                    <div className="apex-logo-img">
                        <img src="/assets/pricing/apex-legends-logo.png" width={1000} alt=""/>
                    </div>
                    <div className="box-outline my-5">
                        <div className="apex-package-img">
                            <img src="/assets/pricing/Rhapsody.png" width={500} alt=""/>
                        </div>
                        <div className="p-5 ">
                            <div className="apex-package-content">

                                <div>
                                    <h1 className={"secondary-color"}>RANKING SYSTEM</h1>
                                </div>

                                <div className="pt-5 apex-rank-main">
                                    <div className="apex-rank-row pb-4">
                                        <div className="apex-rank-img">
                                            <img src="/assets/pricing/bronze.png" width={80} alt=""/>
                                        </div>
                                        <div className={"apex-rank-text"}>
                                            <div className={"apex-rank-price text-center"}>$60</div>
                                            <div className="apex-rank-tagline text-center">Bronze to Silver</div>
                                        </div>
                                        <div className="apex-rank-img">
                                            <img src="/assets/pricing/silver.png" width={80} alt=""/>
                                        </div>
                                    </div>
                                    <div className="apex-rank-row pb-4">
                                        <div className="apex-rank-img">
                                            <img src="/assets/pricing/silver.png" width={80} alt=""/>
                                        </div>
                                        <div>
                                            <div className={"apex-rank-price text-center"}>$75</div>
                                            <div className="apex-rank-tagline text-center">Silver to Gold</div>
                                        </div>
                                        <div className="apex-rank-img">
                                            <img src="/assets/pricing/gold.png" width={80} alt=""/>
                                        </div>
                                    </div>
                                    <div className="apex-rank-row pb-4">
                                        <div className="apex-rank-img">
                                            <img src="/assets/pricing/gold.png" width={80} alt=""/>
                                        </div>
                                        <div>
                                            <div className={"apex-rank-price text-center"}>$150</div>
                                            <div className="apex-rank-tagline text-center">Gold to Platinum</div>
                                        </div>
                                        <div className="apex-rank-img">
                                            <img src="/assets/pricing/Platinum.png" width={80} alt=""/>
                                        </div>
                                    </div>
                                    <div className="apex-rank-row pb-4">
                                        <div className="apex-rank-img">
                                            <img src="/assets/pricing/Platinum.png" width={80} alt=""/>
                                        </div>
                                        <div>
                                            <div className={"apex-rank-price text-center"}>$275</div>
                                            <div className="apex-rank-tagline text-center">Platinum to Diamond</div>
                                        </div>
                                        <div className="apex-rank-img">
                                            <img src="/assets/pricing/Diamond.png" width={80} alt=""/>
                                        </div>
                                    </div>
                                </div>

                                <div className={"add-funds-btn pt-5"}>
                                    <Button className={"FPS-primary-button"}>Add Funds</Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="valorant-box-main my-5 pt-5">
                    <div className="valorant-logo-img">
                        <img src="/assets/pricing/valorant.webp" width={900} alt=""/>
                    </div>
                    <div className="box-outline my-5">
                        <div className="valorant-package-img">
                            <img src="/assets/pricing/valo.png" width={600} alt=""/>
                        </div>
                        <div className="p-5 ">
                            <div className="valorant-package-content">

                                <div className="valorant-package-row pb-4">
                                    <div className={"valorant-price"}>$14</div>
                                    <div className="games-quantity">1 Game</div>
                                </div>
                                <div className="valorant-package-row pb-4">
                                    <div className={"valorant-price"}>$42</div>
                                    <div className="games-quantity">3 Game</div>
                                </div>
                                <div className="valorant-package-row pb-4">
                                    <div className={"valorant-price"}>$70</div>
                                    <div className="games-quantity">5 Game</div>
                                </div>
                                <div className="valorant-package-row">
                                    <div className={"valorant-price"}>$140</div>
                                    <div className="games-quantity">10 Game</div>
                                </div>

                                <div className={"add-funds-btn pt-5"}>
                                    <Button className={"FPS-primary-button"}>Add Funds</Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>*/}

        </Container>
      </BusyOverlay>

    </div>
  )
}
