import React, {FC} from "react";
import {GainSchema} from "../schemas/gain-schema";
import "./StudentGainListItem.scss"
import {GainTypeExt} from "../definitions/gain-type";
import {Button, Col, Row} from "react-bootstrap";
import {PdfUtils} from "../utils/pdf-utils";
import {TimeUtils} from "../utils/time-utils";

export const StudentGainListItem: FC<{ gainData: GainSchema; hasActions: boolean; }> = function (props) {
    return (
        <div className={"student-gain-list-item mb-4"} key={props.gainData.id}>
            <div className="dashboard-box-outline">
                <div className="p-4">
                    <Row>
                        <Col lg={6}>
                            <div className="student-gain-row pb-4">
                                <div className="student-gain-title">
                                    Gain Type
                                </div>
                                <div className="student-gain-info"><span
                                    className="student-gain-info">{GainTypeExt.toDisplayLabel(props.gainData?.gainType)}</span>
                                </div>
                            </div>
                            <div className="student-gain-row pb-4">
                                <div className="student-gain-title">Purchase Date</div>
                                <div className="student-gain-info">
                                    {TimeUtils.timestampToLocaleString(props.gainData.created)}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="student-gain-row pb-4">
                                <div className="student-gain-title">
                                    Total Spent Amount
                                </div>
                                <div className="student-gain-info"><span
                                    className="student-gain-info">{props.gainData.totalAmount} USD</span>
                                </div>
                            </div>
                            <div className="student-gain-row pb-4">
                                <div className="student-gain-title">
                                    Purchased Credit Amount
                                </div>
                                <div className="student-gain-info"><span
                                    className="student-gain-info">{props.gainData.creditAmount} USD</span>
                                </div>
                            </div>
                            {props.gainData.bonusAmount && <div className="student-gain-row pb-4">
                                <div className="student-gain-title">
                                    Bonus Credit Amount
                                </div>
                                <div className="student-gain-info"><span
                                    className="student-gain-info">{props.gainData.bonusAmount} USD</span>
                                </div>
                            </div>}
                            <div className="student-gain-row pb-4">
                                <div className="student-gain-title">
                                    Transaction Fee
                                </div>
                                <div className="student-gain-info"><span
                                    className="student-gain-info">{props.gainData.houseAmount} USD</span>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="invoice-btn">
                        <Button onClick={() => PdfUtils.generateInvoiceForGainData(props.gainData)}
                                className={'dashboard-secondary-btn'}>Download Invoice</Button>
                    </div>

                </div>
            </div>
        </div>);
};
