import { MdClose, MdMenu, MdNotificationsNone, MdOutlineMessage } from "react-icons/md"
import { Link } from "react-router-dom";
import { routes } from "../App";
import { AppConfig } from "../config/app-config";
import { DashboardDropdown } from "./DashboardDropdown";

interface TopNavbarProps {
  setActiveTab: (tabName: string) => void;
  sidebarVisible: boolean;
  toggleSidebar: () => void;
}

export const TopNavbar: React.FC<TopNavbarProps> = ({ setActiveTab, sidebarVisible, toggleSidebar }) => {
  const logoSource = AppConfig.isProductionEnv ? "/assets/Logo/LogoDark BG.png" : "/assets/Logo/LogoDark BG Staging.png";

  return (
    <div className="tw-flex tw-flex-row tw-w-screen tw-h-16 tw-bg-[#282F35] tw-fixed tw-top-0" style={{ zIndex: 501 }}>
      <div className="tw-flex tw-justify-between tw-items-center tw-px-3 tw-w-screen">
        <div className="tw-flex">
          <div className="tw-mt-3.5 tw-mx-3.5 tw-cursor-pointer">
            {sidebarVisible ? (
              <MdClose className="tw-rounded-2xl" size={30} onClick={toggleSidebar} />
            ) : (
              <MdMenu className="tw-rounded-2xl" size={30} onClick={toggleSidebar} />
            )}
          </div>
          <div className="tw-order-2 md:tw-order-1 tw-cursor-pointer">
            <Link to={routes.root} onClick={() => setActiveTab("first")}>
              <img src={logoSource} alt="" className="tw-w-24" />
            </Link>
          </div>
        </div>
        <div className="tw-flex">
          <div className="tw-mr-4 tw-cursor-pointer">
            <MdNotificationsNone onClick={() => setActiveTab("notifications")} className="" size={30} />
          </div>
          <div className="tw-mr-4 tw-cursor-pointer">
            <MdOutlineMessage onClick={() => setActiveTab("messages")} className="" size={30} />
          </div>
          {/* Dropdown */}
          <DashboardDropdown setActiveTab={setActiveTab} />
        </div>
      </div>
    </div>
  )
}
