import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp,
    WithFieldValue
} from "firebase/firestore";

export interface DiscountSchema {
    id: string,
    created: Timestamp,
    modified: Timestamp,
    name: string,
    startDate: Timestamp,
    endDate: Timestamp,
    percentage: number,
}

export interface DiscountPayload {
    updateId: string | null;
    created: Timestamp,
    name: string,
    startDate: Date | null,
    endDate: Date | null,
    percentage: number,
}

export const discountConverter: FirestoreDataConverter<DiscountSchema> = {
    toFirestore(discount: WithFieldValue<DiscountSchema>): DocumentData {
        return discount;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): DiscountSchema {
        return {
            id: snapshot.id,
            ...snapshot.data(options),
        } as DiscountSchema;
    },
};
